import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { serverRequestUpdate } from '../../../store/modules/server/actions';
import history from '../../../history';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import Select from '../../../components/Select';
import { ContainerButtons, Divider, TitleDivider } from './styles';

const ValidateSchema = Yup.object().shape({
  label: Yup.string().required('O campo "Label" é obrigatório'),
  ip: Yup.string(),
  operation_system: Yup.string(),
  memory: Yup.string(),
  harddisck_main: Yup.string(),
  email: Yup.string().email('Email inválido'),
  name_pc: Yup.string().required('O campo "Nome do PC" é obrigatório'),
  type: Yup.string().required('O campo "Tipo do Servidor" é obrigatório'),
  email_drive: Yup.string().required(
    'O campo "Email - Google Drive" é obrigatório'
  ),
  key_drive: Yup.string().required('O campo "Private Key" é obrigatório'),
  scope_drive: Yup.string().required('O campo "SCope" é obrigatório'),
});

function Create() {
  const dispath = useDispatch();
  const [server, setServer] = useState();
  const { id } = useParams();
  const loading = useSelector((state) => state.server.loading);

  useEffect(() => {
    const getServer = async () => {
      try {
        const response = await api.get(`servers/${id}`);
        if (response.data) {
          setServer(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getServer();
  }, []);

  async function handleSubmit({
    label,
    name_pc,
    type,
    email,
    email_drive,
    key_drive,
    scope_drive,
  }) {
    dispath(
      serverRequestUpdate(
        id,
        label,
        null,
        null,
        null,
        null,
        name_pc,
        type,
        email,
        email_drive,
        key_drive,
        scope_drive
      )
    );
    console.log();
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Servidores">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          label: server && server.label,
          ip: '',
          operation_system: '',
          memory: '',
          harddisck_main: '',
          name_pc: server && server.name_pc,
          type: server && server.type,
          email: server?.email,
          email_drive: server?.email_drive,
          key_drive: server?.key_drive,
          scope_drive: server?.scope_drive,
        }}
        // validationSchema={ValidateSchema}
      >
        <StyledForm>
          <Label text="Descrição" />
          <Field placeholder="" type="text" name="label" component={Input} />

          <Label text="Nome do Computador (certifique-se de escrever corretamente o nome)" />
          <Field placeholder="" type="text" name="name_pc" component={Input} />

          <Label text="Email para Notificação" />
          <Field placeholder="" type="text" name="email" component={Input} />
          <Label text="Tipo do Servidor" />
          <Field name="type" component={Select} type="text">
            <option value="" disabled>
              Selecione uma opção
            </option>
            <option key="G" value="G">
              Geral
            </option>
            <option key="C" value="C">
              Contigente
            </option>
          </Field>
          <Divider>
            <TitleDivider>Google Drive - Configurações</TitleDivider>
          </Divider>
          <Label text="Email" />
          <Field
            placeholder=""
            type="text"
            name="email_drive"
            component={Input}
          />
          <Label text="Private Key" />
          <Field
            placeholder=""
            type="text"
            name="key_drive"
            component={Input}
          />
          <Label text="Scope" />
          <Field
            placeholder=""
            type="text"
            name="scope_drive"
            component={Input}
          />
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/server-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
