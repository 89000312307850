import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { updateFailure, updateSuccess } from './actions'; // Importando as ações corretas
import history from '../../../history';

import api from '../../../services/api';

export function* updateCreate({ payload }) {
  // Alterando o nome da função
  try {
    const { software_id, details, version, exe_id, script_id, aditional_id } =
      payload;
    console.log('chama api update');
    yield call(api.post, 'updates', {
      // Atualizando a rota da API
      software_id,
      details,
      version,
      exe_id,
      script_id,
      aditional_id,
    });

    yield put(updateSuccess());

    history.push('/update-list'); // Atualizando a rota para a lista de updates
    toast.success('Nova atualização cadastrada'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Atualização não cadastrada');
  }
}

export function* updateUpdate({ payload }) {
  // Alterando o nome da função
  try {
    const { id, software_id, details, exe_id, script_id, aditional_id } =
      payload;

    yield call(api.put, `updates/${id}`, {
      // Atualizando a rota da API
      software_id,
      details,
      exe_id,
      script_id,
      aditional_id,
    });

    yield put(updateSuccess());

    history.push('/update-list'); // Atualizando a rota para a lista de updates
    toast.success('Cadastro alterado'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* updateDelete({ payload }) {
  // Alterando o nome da função
  try {
    const { id } = payload;

    yield call(api.delete, `updates/${id}`); // Atualizando a rota da API

    yield put(updateSuccess());

    history.push('/update-list'); // Atualizando a rota para a lista de updates
    toast.success('Registro deletado'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Registro não deletado');
  }
}

export default all([
  takeLatest('UPDATE_REQUEST_CREATE', updateCreate), // Atualizando os tipos de ação
  takeLatest('UPDATE_REQUEST_UPDATE', updateUpdate), // Atualizando os tipos de ação
  takeLatest('UPDATE_REQUEST_DELETE', updateDelete), // Atualizando os tipos de ação
]);
