export function updateReleasedRequestCreate(data) {
  return {
    type: 'UPDATE_RELEASED_REQUEST_CREATE',
    payload: { updates: data },
  };
}

export function updateReleasedRequestUpdate(
  id,
  update_id,
  date_release,
  business_id
) {
  return {
    type: 'UPDATE_RELEASED_REQUEST_UPDATE',
    payload: {
      update_id,
      date_release,
      business_id,
      id,
    },
  };
}

export function updateReleasedRequestDelete(id) {
  return {
    type: 'UPDATE_RELEASED_REQUEST_DELETE',
    payload: { id },
  };
}

export function updateReleasedFailure() {
  return {
    type: 'UPDATE_RELEASED_FAILURE',
  };
}

export function updateReleasedSuccess() {
  return {
    type: 'UPDATE_RELEASED_SUCCESS',
  };
}
