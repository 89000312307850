import { createBrowserHistory } from 'history';

export const history_old = createBrowserHistory();

// export default history;

export const history = {
  navigate: null,
  push: (page, ...rest) => history.navigate(page, ...rest),
  location: history_old.location,
};
