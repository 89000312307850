import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { issuerRequestUpdate } from '../../../store/modules/issuer/actions';
import history from '../../../history';
import api from '../../../services/api';
import { CNPJ_CPFREG, CELL_PHONEREG } from '../../../utils/constants';
import { removeNoNumber } from '../../../utils/functions';
import Input, { InputMask } from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const ValidateSchema = Yup.object().shape({
  name: Yup.string().required('O Nome é obriatória').min(3, 'Nome muito curta'),
  email: Yup.string().email('Email inválido'),
  responsable: Yup.string(),
  phone: Yup.string().matches(CELL_PHONEREG, 'Número de telefone inválido'),
  cnpj: Yup.string().matches(CNPJ_CPFREG, 'Número de CPNJ inválido'),
});

function Update() {
  const [issuer, setIssuer] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.issuer.loading);

  const { id } = useParams();

  useEffect(() => {
    const getIssuer = async () => {
      try {
        const response = await api.get(`issuers/${id}`);
        if (response.data) {
          setIssuer(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getIssuer();
  }, []);

  // alert(id);
  async function handleSubmit({ name, cpnj, email, phone, responsable }) {
    dispath(issuerRequestUpdate(id, name, cpnj, email, phone, responsable));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Alteração de Emitente">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          name: issuer.name,
          cnpj: issuer.cnpj,
          email: issuer.email,
          phone: issuer.phone,
          responsable: issuer.responsable,
        }}
        validationSchema={ValidateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Nome do Emissor" />
            <Field placeholder="" type="text" name="name" component={Input} />
            <Label text="CNPJ" />
            <Field
              mask="99.999.999/9999-99"
              type="text"
              name="cnpj"
              component={InputMask}
              onChange={(e) => {
                props.setFieldValue('cnpj', removeNoNumber(e.target.value));
              }}
            />
            <Label text="Responsável" />
            <Field
              placeholder=""
              type="text"
              name="responsable"
              component={Input}
            />
            <Label text="Email" />
            <Field placeholder="" type="text" name="email" component={Input} />
            <Label text="Telefone" />
            <Field
              mask="(99)99999-9999"
              placeholder=""
              type="text"
              name="phone"
              component={InputMask}
              onChange={(e) => {
                props.setFieldValue('phone', removeNoNumber(e.target.value));
              }}
            />
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/issuer-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Update;
