import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { businessModuleFailure, businessModuleSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* businessModuleCreate({ payload }) {
  try {
    console.log('Entrou Aqui Hoje');
    const { table } = payload;
    yield call(api.post, 'businessmodules', { a: table });
    console.log('Entrou Aqui Hoje2');

    yield put(businessModuleSuccess());

    history.push('/company-list');
    toast.success('Alterado com sucesso.');
  } catch (error) {
    yield put(businessModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Não foi possivel alterar o módulo selecionado');
  }
}
export function* businessModuleUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `businessmodule/${id}`, {
      description,
    });

    yield put(businessModuleSuccess());

    history.push('/license-list');
    toast.success('Acesso de módulos alterado.');
  } catch (error) {
    yield put(businessModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Acesso não alterado');
  }
}

export function* businessModuleDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `businessmodule/${id}`);

    yield put(businessModuleSuccess());

    history.push('/license-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(businessModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('BUSINESSMODULE_REQUEST_CREATE', businessModuleCreate),
  takeLatest('BUSINESSMODULE_REQUEST_UPDATE', businessModuleUpdate),
  takeLatest('BUSINESSMODULE_REQUEST_DELETE', businessModuleDelete),
]);
