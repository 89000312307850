import React from 'react';
import ReactDOM from 'react-dom';

import { Backdrop, WrappedModal, InsideWrapper, Title } from './styles';

const Modal = React.memo(
  ({ opened, close, children, title, dynamicWrapper }) =>
    ReactDOM.createPortal(
      <>
        <Backdrop onClick={close} $opened={opened} />
        <WrappedModal $opened={opened}>
          {dynamicWrapper ? (
            children
          ) : (
            <InsideWrapper>
              <Title>{title}</Title>
              {children}
            </InsideWrapper>
          )}
        </WrappedModal>
      </>,
      document.getElementById('root-modal')
    ),
  (prevProps, nextProps) => prevProps.opened === nextProps.opened
);

export default Modal;
