export function businessModuleRequestCreate(table) {
  console.log('Chegou Aqui');
  return {
    type: 'BUSINESSMODULE_REQUEST_CREATE',
    payload: { table },
  };
}

export function businessModuleRequestUpdate(id, description) {
  return {
    type: 'BUSINESSMODULE_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function businessModuleRequestDelete(id) {
  return {
    type: 'BUSINESSMODULE_REQUEST_DELETE',
    payload: { id },
  };
}

export function businessModuleFailure() {
  return {
    type: 'BUSINESSMODULE_FAILURE',
  };
}

export function businessModuleSuccess() {
  return {
    type: 'BUSINESSMODULE_SUCCESS',
  };
}
