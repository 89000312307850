import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { serverDBFailure, serverDBSuccess } from './actions';
import { hideModal } from '../modal/actions';
import history from '../../../history';

import api from '../../../services/api';

export function* serverDBCreate({ payload }) {
  try {
    const { database, server_id } = payload;

    yield call(api.post, 'serverdatabases', {
      database,
      server_id,
    });

    yield put(serverDBSuccess());
    yield put(hideModal());

    history.push('/server-list');
    toast.success('Novo Database cadastrado');
  } catch (error) {
    yield put(serverDBFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Módel não cadastrado');
  }
}
export function* serverDBUpdate({ payload }) {
  try {
    const { id, database, server_id } = payload;
    console.log('Passando o id para atualizar');
    // const software_id = id;
    yield call(api.put, `serverdatabases/${id}`, {
      database,
      server_id,
    });

    yield put(serverDBSuccess());
    yield put(hideModal());

    history.push('/server-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(serverDBFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* serverDBDelete({ payload }) {
  try {
    console.log('Aqui vai chegar para enviar para o backend');
    const { id } = payload;

    yield call(api.delete, `serverdatabases/${id}`);

    yield put(serverDBSuccess());

    history.push('/server-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(serverDBFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('SERVERDB_REQUEST_CREATE', serverDBCreate),
  takeLatest('SERVERDB_REQUEST_UPDATE', serverDBUpdate),
  takeLatest('SERVERDB_REQUEST_DELETE', serverDBDelete),
]);
