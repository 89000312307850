import styled from 'styled-components';

export const ContainerButtons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DivInput = styled.div`
  margin-bottom: 20px;
`;
