import React from 'react';
import { Container } from './styles';

function ContainerList({ children, size, removeShadow }) {
  return (
    <Container size={size} removeShadow={removeShadow}>
      {children}
    </Container>
  );
}

export default ContainerList;
