import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import io from 'socket.io-client';
import GlobalStyle from './styles/global';
import { lightTheme, darkTheme } from './styles/theme';
import Routes from './routes';
import history from './history';
import { store, persistor } from './store';
import ModalRoot from './pages/rootModal/rootModal';

function App() {
  const [theme] = useState('light');
  history.navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Solicitar permissão para enviar notificações quando o componente App é montado
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    // const socket = io('http://192.168.1.8:3333');
    const socket = io(process.env.REACT_APP_SERVER_URL);
    socket.on('nova-notificacao', (data) => {
      console.log('Received data:', data);
      if (
        data &&
        data['Ticket Resposta'] &&
        data['Ticket Resposta'].message &&
        data['Ticket Resposta'].message.trim() !== ''
      ) {
        const notification = data['Ticket Resposta'];
        setNotifications((prevNotifications) => {
          // Verifique se a notificação já existe no estado
          const isDuplicate = prevNotifications.some(
            (prevNotification) => prevNotification.id === notification.id
          );
          if (!isDuplicate) {
            return [notification, ...prevNotifications];
          }
          return prevNotifications; // Retorne o estado atual se for duplicado
        });
      }
    });
    return () => {
      socket.off('nova-notificacao');
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      const latestNotification = notifications[0];

      if ('Notification' in window) {
        if (Notification.permission === 'granted') {
          const notification = new Notification(latestNotification.title, {
            body: latestNotification.message,
            icon: 'https://i.imgur.com/QJYc5x4.png',
            dir: 'ltr',
          });
          // Adicione um event listener para o clique
          notification.onclick = () => {
            // Use a função de navegação para redirecionar o usuário
            history.push(`/ticket-detresponse/${latestNotification.idTicket}`);
          };
          // Remova a última notificação da lista
          setNotifications((prevNotifications) => prevNotifications.slice(1));
        }
      }
    }
  }, [notifications]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
          <ModalRoot />
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={4000} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
