import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { recurrentExportFailure, recurrentExportSuccess } from './actions'; // Ajuste o nome da ação conforme necessário
import history from '../../../history';

import api from '../../../services/api';

export function* recurrentExportCreate({ payload }) {
  try {
    const { data } = payload;

    yield call(api.post, 'recurrentexports', data); // Ajuste o endpoint da API conforme necessário

    yield put(recurrentExportSuccess());

    history.push('/recurrent-export-list'); // Ajuste o redirecionamento conforme necessário
    toast.success('Nova exportação recorrente cadastrada');
  } catch (error) {
    yield put(recurrentExportFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Exportação recorrente não cadastrada');
    }
  }
}

export function* recurrentExportOnDemandCreate({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.post, 'exportnfeondemand', data); // Ajuste o endpoint da API conforme necessário

    yield put(recurrentExportSuccess());

    history.push(`/export-historic-list/${response.data.id}`); // Ajuste o redirecionamento conforme necessário
    toast.success('Exportação sob demanda solicitada.');
  } catch (error) {
    yield put(recurrentExportFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Exportação recorrente não solicitada');
    }
  }
}

export function* recurrentExportUpdate({ payload }) {
  try {
    const { id, data } = payload;
    yield call(api.put, `recurrentexports/${id}`, data); // Ajuste o endpoint da API conforme necessário

    yield put(recurrentExportSuccess());

    history.push('/recurrent-export-list'); // Ajuste o redirecionamento conforme necessário
    toast.success('Cadastro da exportação recorrente alterado');
  } catch (error) {
    yield put(recurrentExportFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Cadastro da exportação recorrente não alterado');
    }
  }
}

export function* recurrentExportDelete({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `recurrentexports/${id}`); // Ajuste o endpoint da API conforme necessário

    yield put(recurrentExportSuccess());

    history.push('/recurrent-export-list'); // Ajuste o redirecionamento conforme necessário
    toast.success('Registro da exportação recorrente deletado');
  } catch (error) {
    yield put(recurrentExportFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Registro da exportação recorrente não deletado');
    }
  }
}

export default all([
  takeLatest('RECURRENT_EXPORT_REQUEST_CREATE', recurrentExportCreate),
  takeLatest('RECURRENT_EXPORT_REQUEST_UPDATE', recurrentExportUpdate),
  takeLatest('RECURRENT_EXPORT_REQUEST_DELETE', recurrentExportDelete),
  takeLatest(
    'RECURRENT_EXPORT_ONDEMAND_REQUEST_CREATE',
    recurrentExportOnDemandCreate
  ),
]);
