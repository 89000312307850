import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
};

// eslint-disable-next-line default-param-last
export default function BUSINESSMODULE(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'BUSINESSMODULE_REQUEST_CREATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'BUSINESSMODULE_REQUEST_UPDATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'BUSINESSMODULE_REQUEST_DELETE':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'BUSINESSMODULE_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case 'BUSINESSMODULE_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });

    default:
      return state;
  }
}
