import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
};

export default function group(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GROUP_REQUEST_CREATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'GROUP_REQUEST_UPDATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'GROUP_REQUEST_DELETE':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'GROUP_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case 'GROUP_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });

    default:
      return state;
  }
}
