import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaTrashAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { MdFileDownload, MdFileDownloadOff } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import StyledForm from '../../../components/Form/StyledForm';
import api from '../../../services/api';
import ContainerCard from '../../../components/ContainerCard';
import Button, { SearchButton } from '../../../components/Button';
import history from '../../../history';
import { recurrentExportRequestDelete } from '../../../store/modules/recurrentexport/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';
import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';
import Label from '../../../components/Label';
import { SelectReactNoForm } from '../../../components/SelectReaact';

const HistoricSchema = Yup.object().shape({});

function ListHistoricExport() {
  const [historics, setHistorics] = useState([]);
  const [business, setBusiness] = useState([]);
  const [businessQuery, setBusinessQuery] = useState([]);

  let { id } = useParams();

  const dispath = useDispatch();
  const loading = useSelector((state) => state.recurrentexport.loading);
  const [loadingTable, setLoadingTable] = useState(false);
  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getBusiness();

    if (id) {
      const getHistoricById = async () => {
        try {
          const response = await api.get(`/recurrentexporthistorics/${id}`);
          id = null;
          if (response.data) {
            setHistorics(response.data);
            history.push('export-historic-list');
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.status === 400
          ) {
            toast.error(error.response.data[0].message);
          } else {
            toast.error(error.message);
          }
        }
      };

      getHistoricById();
    }
  }, [loading]);

  async function handleSubmit() {
    if (businessQuery === '' || businessQuery.length === 0) {
      toast.warn('Selecione uma empresa para pesquisar.');
      return;
    }
    try {
      const response = await api.get(
        `/recurrentexporthistoricsbycompany/${businessQuery}`
      );
      if (response.data) {
        setHistorics(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerList>
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{}}>
        {(props) => (
          <StyledForm autocomplete="off">
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Nome da Empresa" />
                  <SelectReactNoForm
                    value={(businessQuery, business.company_name)}
                    placeholder="Selecione uma Empresa"
                    onChange={(e) => {
                      if (e) {
                        setBusinessQuery(e.value);
                      } else {
                        setBusinessQuery(null);
                        setHistorics([]);
                      }
                    }}
                    options={business.map((busines) => ({
                      label: busines.company_name,
                      value: busines.id,
                    }))}
                    name="idBusiness"
                  />
                </div>
                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Pesquisar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>

      <Table title="Histórico de Exportação" loading={loadingTable}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Tipo Exportação</TableCellHeader>
          <TableCellHeader>Tipo Doc.</TableCellHeader>
          <TableCellHeader>Sub Tipo Doc.</TableCellHeader>
          <TableCellHeader>Intervalo</TableCellHeader>
          <TableCellHeader>Arquivo</TableCellHeader>
        </TableHeader>
        <tbody>
          {historics.map((hi, index) => (
            <TableRow key={hi.id}>
              <TableCell>{hi.id}</TableCell>
              <TableCell>
                {hi.type_export === 'M' ? 'Sob demanda' : 'Recorrente'}
              </TableCell>
              <TableCell>{hi.typedoc === 55 ? 'NF-e' : 'NFC-e'}</TableCell>
              <TableCell>
                {(() => {
                  // eslint-disable-next-line default-case
                  switch (hi.subtypedoc) {
                    case 1:
                      return 'Emitidos';
                    case 2:
                      return 'Recebidos por Emissão';
                    case 3:
                      return 'Recebidos por Entrada';
                    default:
                      return '';
                  }
                })()}
              </TableCell>
              <TableCell>{hi.range}</TableCell>
              <TableCell>
                <ContainerActions>
                  {hi && hi.zipFiles ? (
                    <Link
                      to={`${hi.zipFiles.url}/S`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MdFileDownload size="15px" color="#696969" />
                    </Link>
                  ) : (
                    <MdFileDownloadOff
                      size="25px"
                      color="#696969"
                      style={{ marginLeft: '5px' }}
                    />
                  )}
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListHistoricExport;
