export function licenseRequestCreate(table) {
  return {
    type: 'LICENSE_REQUEST_CREATE',
    payload: { table },
  };
}

export function licenseRequestUpdate(id, description) {
  return {
    type: 'LICENSE_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function licenseRequestDelete(id) {
  return {
    type: 'LICENSE_REQUEST_DELETE',
    payload: { id },
  };
}

export function licenseFailure() {
  return {
    type: 'LICENSE_FAILURE',
  };
}

export function licenseSuccess() {
  return {
    type: 'LICENSE_SUCCESS',
  };
}
