import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { serverDBRequestUpdate } from '../../../../store/modules/serverdatabase/actions';

import api from '../../../../services/api';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import StyledForm from '../../../../components/Form';
import Label from '../../../../components/Label';
import { hideModal } from '../../../../store/modules/modal/actions';
import ContainerCard from '../../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const validateSchema = Yup.object().shape({
  database: Yup.string()
    .required('A descrição é obrigatória')
    .min(3, 'Descrição muito curta'),
});

function Update({ id, server_id }) {
  const [serverDb, setServerDb] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.serverdatabase.loading);

  useEffect(() => {
    const getSoftModule = async () => {
      try {
        const response = await api.get(`serverdatabases/${id}`);
        if (response.data) {
          setServerDb(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getSoftModule();
  }, []);

  // alert(id);
  async function handleSubmit({ database }) {
    dispath(serverDBRequestUpdate(id, database, server_id));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Alteração do Database" removeShadow>
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          database: serverDb.database,
        }}
        validationSchema={validateSchema}
      >
        <StyledForm>
          <Label text="Nome do Banco de dados(tenha certeza que este nome está correto)" />
          <Field placeholder="" type="text" name="database" component={Input} />

          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => dispath(hideModal())}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Update;
