export function subChartRequestCreate(description, chartaccountId) {
  return {
    type: 'SUBCHART_REQUEST_CREATE',
    payload: { description, chartaccountId },
  };
}

export function subChartRequestUpdate(id, description) {
  return {
    type: 'SUBCHART_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function subChartRequestDelete(id) {
  return {
    type: 'SUBCHART_REQUEST_DELETE',
    payload: { id },
  };
}

export function subChartFailure() {
  return {
    type: 'SUBCHART_FAILURE',
  };
}

export function subChartSuccess() {
  return {
    type: 'SUBCHART_SUCCESS',
  };
}
