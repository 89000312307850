import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import api from '../../../services/api';
import { formatCpfCnpj } from '../../../utils/functions';
import Select from '../../../components/Select';
import { companyRequestDelete } from '../../../store/modules/company/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button, { SearchButton } from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import {
  ContainerActions,
  ContainerButtons,
  TitleDivider,
  Divider,
} from './styles';
import ContainerCard from '../../../components/ContainerCard';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import { SelectReactNoForm } from '../../../components/SelectReaact';
import Input, {
  InputFile,
  InputCheckBoxNoForm,
} from '../../../components/Input';

moment.locale('pt-br');

function ListUser() {
  const [users, setUsers] = useState([]);
  const [business, setBusiness] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupQuery, setGroupQuery] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.company.loading);

  const { id } = useParams();

  function selecionarEstSubIndex(acao, index) {
    const novoArray = [...business];

    novoArray[index].sel = acao;

    if (!acao) {
      novoArray[index].scheduling = '';
    }

    setBusiness(novoArray);
  }

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoadingUser(true);
        const response = await api.get(`users/${id}`);
        if (response.data) {
          setUsers(response.data);
        }

        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const response = await api.get('/searchcompany', {
          params: {
            group_id: groupQuery,
            user_id: id,
          },
        });
        if (response.data) {
          const array = [];
          response.data.map((e) => {
            array.push({
              sel: e.businessAccess && e.businessAccess.length > 0,
              ...e,
            });
            return null;
          });

          console.log('Vejamos o que tem aqui: ', array);
          setBusiness(array);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getGroup();

    getUsers();
    getBusiness();
  }, [loading]);

  async function handleSubmit() {
    try {
      const response = await api.get('/searchcompany', {
        params: {
          group_id: groupQuery,
          user_id: id,
        },
      });
      if (response.data) {
        const array = [];
        response.data.map((e) => {
          array.push({
            sel: e.businessAccess && e.businessAccess.length > 0,
            ...e,
          });
          return null;
        });

        console.log('Vejamos o que tem aqui: ', array);
        setBusiness(array);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  async function saveAccessUser() {
    try {
      await api.delete(`userbusinesses/${id}`);

      business.map((e) => {
        if (e.sel) {
          api.post('userbusinesses', {
            business_id: e.id,
            user_id: id,
          });
        }
        return null;
      });
      history.push('/user-list');
      toast.success('Acessos redefinidos com sucesso');
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerList>
      <ModalConfirm />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          name: '',
          group_id: '',
        }}
        // validationSchema={TicketSchema}
      >
        {(props) => (
          <StyledForm>
            <ContainerCard title="Acessos do Usuário">
              <Divider>
                <TitleDivider>
                  {users?.name?.toUpperCase() || 'Default Name'}
                </TitleDivider>
              </Divider>
              <Divider />
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Selecione um Grupo" />
                  <SelectReactNoForm
                    value={(groupQuery, groups.description)}
                    placeholder="Selecione um Grupo"
                    onChange={(e) => {
                      if (e) {
                        setGroupQuery(e.value);
                      } else {
                        setGroupQuery(null);
                      }
                    }}
                    options={groups.map((group) => ({
                      label: group.description,
                      value: group.id,
                    }))}
                    name="idGroup"
                  />
                </div>

                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Salvar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>

      <Table title="Empresas" loading={loadingUser}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Sel.</TableCellHeader>
          <TableCellHeader>Fantasia</TableCellHeader>
          <TableCellHeader>Razão Social</TableCellHeader>
          <TableCellHeader>Filial</TableCellHeader>
          <TableCellHeader>Cnpj</TableCellHeader>
          <TableCellHeader>Grupo</TableCellHeader>
        </TableHeader>
        <tbody>
          {business.map((businesses, index) => {
            business[index].id = businesses.id;
            return (
              <TableRow key={businesses.id}>
                <TableCell>{businesses.id}</TableCell>
                <TableCell>
                  <InputCheckBoxNoForm
                    noError
                    placeholder=""
                    type="checkbox"
                    name="selected"
                    checked={businesses.sel}
                    onChange={(e) =>
                      selecionarEstSubIndex(e.target.checked, index)
                    }
                  />
                </TableCell>

                <TableCell>{businesses.fantasy_name}</TableCell>
                <TableCell>{businesses.company_name}</TableCell>
                <TableCell>{businesses.code}</TableCell>
                <TableCell>
                  {businesses && formatCpfCnpj(businesses.cnpj)}
                </TableCell>

                <TableCell>{businesses.group.description}</TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <ContainerButtons>
        <Button
          size="contained"
          variant="outline"
          text="Cancelar"
          type="button"
          onClick={() => history.push('/user-list')}
        />
        <Button
          size="contained"
          type="button"
          text={loading ? 'Aguarde...' : 'Salvar'}
          onClick={saveAccessUser}
        />
      </ContainerButtons>
    </ContainerList>
  );
}

export default ListUser;
