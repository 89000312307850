import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { groupRequestUpdate } from '../../../store/modules/group/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const groupSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obrigatória')
    .min(3, 'Descrição muito curta'),
});

function Update() {
  const [group, setGroup] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);

  const { id } = useParams();

  useEffect(() => {
    const getGroup = async () => {
      try {
        const response = await api.get(`groups/${id}`);
        if (response.data) {
          setGroup(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getGroup();
  }, []);

  // alert(id);
  async function handleSubmit({ description }) {
    dispath(groupRequestUpdate(id, description));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Alteração de Grupo">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: group.description,
        }}
        validationSchema={groupSchema}
      >
        <StyledForm>
          <Label text="Descrição do Grupo de Empresas" />
          <Field
            placeholder=""
            type="text"
            name="description"
            component={Input}
          />

          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/group-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Update;
