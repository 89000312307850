export function serverDBRequestCreate(database, server_id) {
  return {
    type: 'SERVERDB_REQUEST_CREATE',
    payload: { database, server_id },
  };
}

export function serverDBRequestUpdate(id, database, server_id) {
  return {
    type: 'SERVERDB_REQUEST_UPDATE',
    payload: { database, server_id, id },
  };
}

export function serverDBRequestDelete(id) {
  return {
    type: 'SERVERDB_REQUEST_DELETE',
    payload: { id },
  };
}

export function serverDBFailure() {
  return {
    type: 'SERVERDB_FAILURE',
  };
}

export function serverDBSuccess() {
  return {
    type: 'SERVERDB_SUCCESS',
  };
}
