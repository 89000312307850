// sagas.js
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { ticketFailure, ticketSuccess, ticketIdReceived } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* ticketCreate({ payload }) {
  const displayedMessages = new Set();

  try {
    const { subject, situation, ticket_categories_id, message, business_id } =
      payload;

    const response = yield call(api.post, 'tickets', {
      subject,
      situation,
      ticket_categories_id,
      message,
      business_id,
    });

    history.push('/ticket-list');
    toast.success('Ticket Cadastrado com Sucesso');
  } catch (error) {
    yield put(ticketFailure());

    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors && data.errors.length > 0) {
        // Exibir mensagens de erro personalizadas
        data.errors.forEach((err) => {
          // Verificar se a mensagem já foi exibida
          if (!displayedMessages.has(err.message)) {
            toast.error(err.message);

            // Adicionar a mensagem ao conjunto para evitar exibições duplicadas
            displayedMessages.add(err.message);
          }
        });
      } else {
        // Se não houver mensagens personalizadas, exibir uma mensagem padrão
        const defaultMessage =
          'Erro de validação: Ticket não cadastrado corretamente.';

        // Verificar se a mensagem padrão já foi exibida
        if (!displayedMessages.has(defaultMessage)) {
          toast.error(defaultMessage);

          // Adicionar a mensagem ao conjunto
          displayedMessages.add(defaultMessage);
        }
      }
    } else {
      // Se o erro não for 422, exibir uma mensagem genérica
      const genericMessage =
        'Erro ao cadastrar o ticket. Tente novamente mais tarde.';

      // Verificar se a mensagem genérica já foi exibida
      if (!displayedMessages.has(genericMessage)) {
        toast.error(genericMessage);

        // Adicionar a mensagem ao conjunto
        displayedMessages.add(genericMessage);
      }
    }
  }
}

export function* ticketUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `tickets/${id}`, {
      description,
    });

    yield put(ticketSuccess());

    history.push('/ticket-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(ticketFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* ticketDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `tickets/${id}`);

    yield put(ticketSuccess());

    history.push('/ticket-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(ticketFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('TICKET_REQUEST_CREATE', ticketCreate),
  takeLatest('TICKET_REQUEST_UPDATE', ticketUpdate),
  takeLatest('TICKET_REQUEST_DELETE', ticketDelete),
]);
