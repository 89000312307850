import React, { useState } from 'react';
import { Li, StyledNavLink } from './styles';

export default function NavItem({ link, children, clicked, ...props }) {
  return (
    <Li>
      <StyledNavLink to={link} {...props} onClick={clicked}>
        {children}
      </StyledNavLink>
    </Li>
  );
}
