export function updateRequestCreate(
  software_id,
  details,
  version,
  exe_id,
  script_id,
  aditional_id
) {
  return {
    type: 'UPDATE_REQUEST_CREATE',
    payload: { software_id, details, version, exe_id, script_id, aditional_id },
  };
}

export function updateRequestUpdate(
  id,
  software_id,
  details,
  version,
  exe_id,
  script_id,
  aditional_id
) {
  return {
    type: 'UPDATE_REQUEST_UPDATE',
    payload: {
      software_id,
      details,
      version,
      exe_id,
      script_id,
      aditional_id,
      id,
    },
  };
}

export function updateRequestDelete(id) {
  return {
    type: 'UPDATE_REQUEST_DELETE',
    payload: { id },
  };
}

export function updateFailure() {
  return {
    type: 'UPDATE_FAILURE',
  };
}

export function updateSuccess() {
  return {
    type: 'UPDATE_SUCCESS',
  };
}
