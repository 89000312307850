import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import history from '../../history';
import { signUpRequest } from '../../store/modules/auth/actions';

import Input from '../../components/Input';
import Button from '../../components/Button';
import StyledForm from '../../components/Form';
import Label from '../../components/Label';
import ContainerCard from '../../components/ContainerCard';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .required('O e-mail é obrigatório')
    .email('E-mail inválido'),

  password: Yup.string()
    .required('A senha é obrigatória')
    .min(6, 'Muito curto'),
  confirmPassword: Yup.string()
    .required('É preciso confirmar a senha')
    .min(6, 'Muito curto')
    .test('passwords-equals', 'As senha devem ser iguais', function (value) {
      return this.parent.password === value;
    }),
});

function SignUp() {
  const dispatch = useDispatch();
  async function handleSubmit({ name: username, email, password }) {
    dispatch(signUpRequest(email, username, password));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de usuário" size="500">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        initialValues={{
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={SignUpSchema}
      >
        <StyledForm>
          <Label text="Nome" />
          <Field type="text" name="name" component={Input} />
          <Label text="Seu email" />
          <Field type="text" name="email" component={Input} />
          <Label text="Sua senha" />
          <Field type="password" name="password" component={Input} />
          <Label text="Confirme a senha" />
          <Field type="password" name="confirmPassword" component={Input} />
          <Button type="submit" text="Cadastrar" />
          <Button
            variant="text"
            text="Já tenho conta"
            onClick={() => history.push('/')}
          />
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default SignUp;
