import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { issuerRequestCreate } from '../../../store/modules/issuer/actions';
import history from '../../../history';

import Input, { InputMask } from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';
import { CNPJ_CPFREG, CELL_PHONEREG } from '../../../utils/constants';
import { removeNoNumber } from '../../../utils/functions';

const ValidateSchema = Yup.object().shape({
  name: Yup.string().required('O Nome é obriatória').min(3, 'Nome muito curta'),
  email: Yup.string().email('Email inválido'),
  responsable: Yup.string(),
  phone: Yup.string().matches(CELL_PHONEREG, 'Número de telefone inválido'),
  cnpj: Yup.string().matches(CNPJ_CPFREG, 'Número de CPNJ inválido'),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);

  async function handleSubmit({ name, cnpj, email, phone, responsable }) {
    dispath(issuerRequestCreate(name, cnpj, email, phone, responsable));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Emissor/Sacado">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          name: '',
          cnpj: '',
          email: '',
          phone: '',
          responsable: '',
        }}
        validationSchema={ValidateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Nome do Emissor" />
            <Field placeholder="" type="text" name="name" component={Input} />
            <Label text="CNPJ" />
            <Field
              mask="99.999.999/9999-99"
              type="text"
              name="cnpj"
              component={InputMask}
              onChange={(e) => {
                props.setFieldValue('cnpj', removeNoNumber(e.target.value));
              }}
            />
            <Label text="Responsável" />
            <Field
              placeholder=""
              type="text"
              name="responsable"
              component={Input}
            />
            <Label text="Email" />
            <Field placeholder="" type="text" name="email" component={Input} />
            <Label text="Telefone" />
            <Field
              mask="(99)99999-9999"
              placeholder=""
              type="text"
              name="phone"
              component={InputMask}
              onChange={(e) => {
                props.setFieldValue('phone', removeNoNumber(e.target.value));
              }}
            />
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/issuer-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Create;
