export const lightTheme = {
  colors: {
    accent: '#2a3042',
    // accent: '#556ee6',
    warning: '#F2AC57',
    sucess: '#14A38B',
    error: '#FF7171',
    primary: '#2C2738',
    secondary: '#495057',
    muted: '#7C9CBF',
    bright: '#FFF',
    shade: '#ced4da',
    shadeSVG: '%23ced4da',
    tint: '#EBF4F8',
    side: '#2a3042',

    /**
    accent: '#0880AE',
    warning: '#F2AC57',
    sucess: '#14A38B',
    error: '#FF7171',
    primary: '#2C2738',
    secondary: '#756F86',
    muted: '#7C9CBF',
    bright: '#FFF',
    shade: '#DBE2EA',
    tint: '#EBF4F8', */
  },
  name: 'light',
};
export const darkTheme = {
  colors: {
    accent: '#8A85FF',
    warning: '#F2AC57',
    sucess: '#14A38B',
    error: '#FF7171',
    primary: '#D6E1DF',
    secondary: '#A1B0BB',
    muted: '#7C9CBF',
    bright: '#282C34',
    // shade: '#ACAFB8',
    shade: '#5A5D63',
    tint: '#1C2025',
    side: '#2a3042',
  },
  name: 'dark',
};
