export function cityRequestCreate(name, uf) {
  return {
    type: 'CITY_REQUEST_CREATE',
    payload: { name, uf },
  };
}

export function cityRequestUpdate(id, name, uf) {
  return {
    type: 'CITY_REQUEST_UPDATE',
    payload: { name, uf, id },
  };
}

export function cityRequestDelete(id) {
  return {
    type: 'CITY_REQUEST_DELETE',
    payload: { id },
  };
}

export function cityFailure() {
  return {
    type: 'CITY_FAILURE',
  };
}

export function citySuccess() {
  return {
    type: 'CITY_SUCCESS',
  };
}
