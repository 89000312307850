import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled.div`
  //min-height: calc(100%);
  height: auto;
  background-color: ${(props) => props.theme.colors.side};
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  //border-right: solid 1px ${(props) => props.theme.colors.shade};
  //flex: 1;
  display: flex;
  //min-width: 250px;
  //max-width: 350px;
  width: 200px;
  padding: 5px 8px;
  transition: all linear 0.2s;
`;

export const Scroll = styled(PerfectScrollBar)`
  height: calc(100vh - 86px);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  width: 100%;

  padding-top: 0px;
`;
