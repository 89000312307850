import { produce } from 'immer';

const INITIAL_STATE = {
  user: null,
};

// eslint-disable-next-line default-param-last
export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AUTH_SIGN_IN_SUCCESS':
      return produce(state, (draft) => {
        draft.user = action.payload.user;
        draft.user.roles = action.payload.user.userRoles.map(
          (role) => role.description
        );
      });
    case 'USER_REQUEST_CREATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'USER_REQUEST_UPDATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'USER_REQUEST_DELETE':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'USER_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case 'USER_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
}
