import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { updateReleasedFailure, updateReleasedSuccess } from './actions'; // Importando as ações corretas
import history from '../../../history';

import api from '../../../services/api';

export function* updateReleasedCreate({ payload }) {
  // Alterando o nome da função
  try {
    const { update_id, date_release, business_id } = payload;

    const rest = yield call(api.post, 'updatesreleaseds', payload);

    console.log(rest.data);

    let dt;
    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-plusplus
    for (let i = 0; i < rest.data.length; i++) {
      dt = rest.data[i];
      console.log(dt);
      console.log(rest.data);
      yield call(api.get, `auxUpdate-nofity-server/${dt.id}`);
    }

    yield put(updateReleasedSuccess());

    history.push('/update-released-list'); // Atualizando a rota para a lista de updates
    toast.success('Nova atualização Liberada'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateReleasedFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Atualização não cadastrada');
  }
}

export function* updateReleasedUpdate({ payload }) {
  // Alterando o nome da função
  try {
    const { update_id, date_release, business_id, id } = payload;

    yield call(api.put, `updatesreleaseds/${id}`, {
      // Atualizando a rota da API
      update_id,
      date_release,
      business_id,
    });

    yield put(updateReleasedSuccess());

    history.push('/update-released-list'); // Atualizando a rota para a lista de updates
    toast.success('Cadastro alterado'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateReleasedFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* updateReleasedDelete({ payload }) {
  // Alterando o nome da função
  try {
    const { id } = payload;

    yield call(api.delete, `updatesreleaseds/${id}`); // Atualizando a rota da API

    yield put(updateReleasedSuccess());

    history.push('/update-released-list'); // Atualizando a rota para a lista de updates
    toast.success('Registro deletado'); // Mensagem de sucesso
  } catch (error) {
    yield put(updateReleasedFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Registro não deletado');
  }
}

export default all([
  takeLatest('UPDATE_RELEASED_REQUEST_CREATE', updateReleasedCreate), // Atualizando os tipos de ação
  takeLatest('UPDATE_RELEASED_REQUEST_UPDATE', updateReleasedUpdate), // Atualizando os tipos de ação
  takeLatest('UPDATE_RELEASED_REQUEST_DELETE', updateReleasedDelete), // Atualizando os tipos de ação
]);
