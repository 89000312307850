import React, { useCallback } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Dropzone, { useDropzone } from 'react-dropzone';
import ptBR from 'date-fns/locale/pt-BR';

import { FaUpload } from 'react-icons/fa';

import {
  Container,
  StyledInput,
  StyledInputMask,
  Error,
  StyledInputCurrency,
  StyledInputDatePicker,
  StyledInputDateTimePicker,
  StyledInputCheckBox,
  StyledInputFile,
  ContainerInputFile,
  StyledTextArea,
} from './styles';

export default function Input({ field, form: { touched, errors }, ...props }) {
  return (
    <Container>
      <StyledInput {...field} {...props} />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

export function InputNoForm({ noError, ...props }) {
  return (
    // <Container noError={noError}>
    <StyledInput {...props} />
    // </Container>
  );
}

export function TextAreaNoForm({ noError, ...props }) {
  return (
    // <Container noError={noError}>
    <StyledTextArea {...props} />
    // </Container>
  );
}

export function InputCheckBoxNoForm({ noError, ...props }) {
  return (
    <Container noError={noError}>
      <StyledInputCheckBox {...props} />
    </Container>
  );
}

export function InputFile({
  field,
  onDrop,

  ...props
}) {
  return (
    <Dropzone accept={props.acceptDrop && props.acceptDrop} onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <ContainerInputFile {...getRootProps()}>
          <FaUpload size={25} />
          <h2>Solte um arquivo aqui ou clique para selecionar</h2>
          <StyledInputFile {...getInputProps()} {...field} {...props} />
        </ContainerInputFile>
      )}
    </Dropzone>
  );
}

export function InputMask({ field, form: { touched, errors }, ...props }) {
  return (
    <Container>
      <StyledInputMask {...field} {...props} />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

export function InputCurrency({ field, form: { touched, errors }, ...props }) {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <Container>
      <StyledInputCurrency
        {...field}
        currency="BRL"
        config={currencyConfig}
        {...props}
        onValueChange={(value, name) => console.log(value, name)}
      />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

export function InputDatePicker({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <Container>
      <StyledInputDatePicker {...field} {...props} />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

export function InputDatePickerNoForm({ noError, range, ...props }) {
  return (
    <Container noError={noError}>
      <StyledInputDatePicker range={range} {...props} />
    </Container>
  );
}

export function InputDatePickerRangeNoForm({ noError, ...props }) {
  return (
    <Container noError={noError}>
      <StyledInputDatePicker {...props} />
    </Container>
  );
}

export function InputDateTimePickerNoForm({ noError, ...props }) {
  return (
    <Container noError={noError}>
      <StyledInputDateTimePicker {...props} />
    </Container>
  );
}
