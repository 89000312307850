import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
};

export default function updateReleased(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'UPDATE_RELEASED_REQUEST_CREATE':
    case 'UPDATE_RELEASED_REQUEST_UPDATE':
    case 'UPDATE_RELEASED_REQUEST_DELETE':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'UPDATE_RELEASED_SUCCESS':
    case 'UPDATE_RELEASED_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });

    default:
      return state;
  }
}
