import React, { useState, useEffect } from 'react';

import { useDispatch, connect } from 'react-redux';
import { FaAngleDown } from 'react-icons/fa';
import {
  StyledNavCategory,
  Title,
  ContainerItens,
  Category,
  IconAndTatle,
} from './styles';
import history from '../../history';
import {
  changeCategoryMenu,
  changePage,
} from '../../store/modules/sideMenu/actions';

import { namePageByUrl } from '../../utils/constants';

function NavItem({
  linkGroup,
  children,
  clicked,
  title,
  Icon,
  categoryCurrent,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const linkGroups = linkGroup.split('|');

  function handleClick() {
    dispatch(changeCategoryMenu(linkGroup));

    setOpen(!open);
  }
  useEffect(() => {
    const defineOpen = () => {
      try {
        const currentUrl = history.location.pathname;

        if (categoryCurrent === linkGroup) {
          setOpen(true);
        } else if (currentUrl.includes(linkGroup) && !categoryCurrent) {
          dispatch(changeCategoryMenu(linkGroup));
        } else if (
          (currentUrl.includes(linkGroups[0]) ||
            currentUrl.includes(linkGroups[1])) &&
          !categoryCurrent
        ) {
          dispatch(changeCategoryMenu(linkGroup));
        } else setOpen(false);
      } catch (error) {
        setOpen(false);
      }
    };
    defineOpen();
  }, [categoryCurrent]);

  useEffect(() => {
    const defineOpen = () => {
      try {
        const currentUrl = history.location.pathname;
        dispatch(changePage(namePageByUrl(currentUrl)));
      } catch (error) {
        setOpen(false);
      }
    };
    defineOpen();
  }, [history.location.pathname]);

  return (
    <StyledNavCategory open={open} {...props}>
      <Category onClick={handleClick} open={open}>
        <IconAndTatle>
          {Icon ? <Icon size={18} /> : null}

          <Title>{title}</Title>
        </IconAndTatle>
        <span>
          {' '}
          <FaAngleDown size={15} />{' '}
        </span>
      </Category>
      <ContainerItens open={open}>{children}</ContainerItens>
    </StyledNavCategory>
  );
}

export default connect((state) => state.sideMenu)(NavItem);
