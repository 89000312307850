import React from 'react';
import Rating from '@mui/material/Rating';
import { Box } from '@mui/material';

function StarRating({
  rating,
  onRatingChange,
  editable = true,
  size = 'medium',
  allowHalfStars = true, // Nova prop para controlar meias estrelas
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Rating
        name="rating"
        value={rating}
        precision={allowHalfStars ? 0.5 : 1} // Define a precisão com base na prop
        onChange={(_, newValue) => editable && onRatingChange(newValue)}
        readOnly={!editable}
        size={size}
      />
    </Box>
  );
}

export default StarRating;
