import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { groupFailure, groupSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* groupCreate({ payload }) {
  try {
    const { description } = payload;

    yield call(api.post, 'groups', {
      description,
    });

    yield put(groupSuccess());

    history.push('/group-list');
    toast.success('Novo grupo cadastrado');
  } catch (error) {
    yield put(groupFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Grupo não cadastrado');
  }
}
export function* groupUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `groups/${id}`, {
      description,
    });

    yield put(groupSuccess());

    history.push('/group-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(groupFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* groupDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `groups/${id}`);

    yield put(groupSuccess());

    history.push('/group-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(groupFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('GROUP_REQUEST_CREATE', groupCreate),
  takeLatest('GROUP_REQUEST_UPDATE', groupUpdate),
  takeLatest('GROUP_REQUEST_DELETE', groupDelete),
]);
