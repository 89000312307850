import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { serverDBRequestCreate } from '../../../../store/modules/serverdatabase/actions';
import { hideModal } from '../../../../store/modules/modal/actions';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import StyledForm from '../../../../components/Form';
import Label from '../../../../components/Label';
import ContainerCard from '../../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const ValidateSchema = Yup.object().shape({
  database: Yup.string()
    .required('O nome do banco de dados é obrigatório')
    .min(3, 'Descrição muito curta'),
});

function Create({ server_id }) {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.serverdatabase.loading);

  async function handleSubmit({ database }) {
    dispath(serverDBRequestCreate(database, server_id));
  }
  function handleReset(values, { setStatus }) {
    dispath(hideModal());
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Database" removeShadow>
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          database: '',
        }}
        validationSchema={ValidateSchema}
      >
        <StyledForm>
          <Label text="Nome do Banco de dados(tenha certeza que este nome está correto)" />
          <Field placeholder="" type="text" name="database" component={Input} />

          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="reset"
              onClick={() => {
                dispath(hideModal());
              }}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
