import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import city from './city/sagas';
import group from './group/sagas';
import computer from './computer/sagas';
import ticket from './ticket/sagas';
import ticketCategory from './ticketcategory/sagas';
import ticketDiscussion from './ticketdiscussion/sagas';
import company from './company/sagas';
import license from './license/sagas';
import businessmodule from './businessmodule/sagas';
import software from './software/sagas';
import update from './update/sagas';
import updatereleased from './updatereleased/sagas';
import softmodule from './softmodule/sagas';
import server from './server/sagas';
import serverdatabase from './serverdatabase/sagas';
import recurrentexport from './recurrentexport/sagas';
import modal from './modal/sagas';
// import chartaccount from './chartaccount/sagas';
// import subchart from './subchart/sagas';
import issuer from './issuer/sagas';
// import document from './document/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    city,
    company,
    group,
    ticket,
    ticketCategory,
    ticketDiscussion,
    license,
    update,
    server,
    software,
    computer,
    softmodule,
    businessmodule,
    updatereleased,
    modal,
    serverdatabase,
    recurrentexport,
    // chartaccount,
    // subchart,
    issuer,
    // document,
  ]);
}
