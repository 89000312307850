import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Button, { ButtonImage, ButtonModal } from '../../../components/Button';
import {
  Wrapper,
  Header,
  ContentChildren,
  Content,
  Scroll,
  ContainerUserInfo,
  ContainerLogo,
  PainelInfoCurrentPage,
} from './styles';
import SideMenu from '../../../components/SideMenu';

import userDefault from '../../../assets/images/user-default.png';
import logo from '../../../assets/images/logo.png';

// Componente Modal
function Modal({ onEditProfile, onLogout, position, modalRef }) {
  const modalStyle = {
    position: 'absolute',
    top: `${position.y}px`, // Ajuste a posição vertical para abaixo do botão de imagem
    left: `${position.x}px`,
    width: '150px', // Aumente a largura para acomodar o balão de conversa
    height: '120px',
    backgroundColor: 'white',
    boxShadow: `2px 2px 15px #5A5D63 `,
    borderRadius: '4px',
    zIndex: 10,
  };

  const triangleStyle = {
    position: 'absolute',
    top: `${position.y - 64}px`, // Ajuste a posição vertical para abaixo do botão de imagem
    left: `15px`,
    width: '0',
    height: '0',

    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid white', // Cor do triângulo
    zIndex: 11, // Deve estar acima do modal
  };

  const backdropStyle = {
    position: 'absolute',
    width: '150px',
    height: '220px',
    top: `${position.y}px`,
    left: `${position.x}px`,
    backgroundColor: 'white', // Exemplo de cor de fundo com transparência
    zIndex: 5, // Menor que o zIndex do modal
    // Outros estilos conforme necessário
  };

  return (
    <div className="modal" ref={modalRef} style={modalStyle}>
      <div style={triangleStyle} />
      <ButtonModal onClick={onEditProfile} text="Editar Perfil" />
      <ButtonModal onClick={onLogout} text="Sair" />
    </div>
  );
}

function DefaultLayout({ children }) {
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const pageName = useSelector((state) => state.sideMenu.pageName);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditProfile = () => {
    // Lógica para editar perfil
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    setIsModalOpen(false);
    localStorage.removeItem('persist:sisfoco');
    window.location.reload();
  };

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const handleButtonClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        x: rect.left,
        y: rect.bottom, // Isso fará com que o modal apareça logo abaixo do botão
      });
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <Wrapper>
      <Header>
        <ContainerLogo>
          <img src={logo} alt="system logo" />
        </ContainerLogo>
        <ContainerUserInfo>
          <ButtonImage ref={buttonRef} onClick={handleButtonClick}>
            <img
              src={userDefault}
              alt="user profile"
              style={{ display: 'block' }}
            />
          </ButtonImage>

          <span style={{ marginLeft: '5px' }}>
            <h2>{user.user_name}</h2>
            <h3>{user.email}</h3>
          </span>
        </ContainerUserInfo>
      </Header>
      <Content>
        <SideMenu />
        <ContentChildren>
          <Scroll>
            {/* <PainelInfoCurrentPage> */}
            {/* <h2>{pageName}</h2> */}
            {/* </PainelInfoCurrentPage> */}
            {children}
          </Scroll>
        </ContentChildren>
      </Content>
      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          onEditProfile={handleEditProfile}
          onLogout={handleLogout}
          position={modalPosition}
          modalRef={modalRef}
        />
      )}
    </Wrapper>
  );
}
DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
export default DefaultLayout;
