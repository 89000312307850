import React, { useState } from 'react';
import { FaInfoCircle, FaQuestionCircle, FaStar } from 'react-icons/fa';
import StarRating from '../StartRating';
import {
  Container,
  ContainerNote,
  ContainerArchive,
  ContainerArchiveExample,
  Title,
  TitleNote,
  TitleTextNote,
  TitleDiscussion,
  Subtitle,
  ContainerChildren,
  Border,
  ContainerAction,
  TitleAction,
  TitleTextAction,
  ButtonContainer,
  Button,
} from './styles';

export default function ContainerCard({
  title,
  subtitle,
  children,
  size,
  removeShadow,
  alignRight,
  showBorder,
}) {
  return (
    <Container size={size} removeShadow={removeShadow}>
      <Title
        subtitle={subtitle}
        alignRight={alignRight}
        showBorder={showBorder}
      >
        {title}
      </Title>
      {subtitle ? <Subtitle>{subtitle || ''}</Subtitle> : null}
      {showBorder ? <Border /> : null}
      <ContainerChildren>{children}</ContainerChildren>
    </Container>
  );
}

export function ContainerCardDiscussion({
  title,
  titleDiscussion,
  subtitle,
  children,
  size,
  removeShadow,
  alignRight,
  showBorder,
}) {
  return (
    <Container size={size} removeShadow={removeShadow}>
      <TitleDiscussion alignRight={alignRight}>
        {alignRight ? (
          <>
            <div>{subtitle}</div>
            <div>{title}</div>
          </>
        ) : (
          <>
            <div>{title}</div>
            <div>{subtitle}</div>
          </>
        )}
      </TitleDiscussion>
      <Border />
      <ContainerChildren>{children}</ContainerChildren>
    </Container>
  );
}

export function ContainerCardArchives({ children, size, removeShadow }) {
  return (
    <ContainerArchive size={size} removeShadow={removeShadow}>
      <ContainerChildren>{children}</ContainerChildren>
    </ContainerArchive>
  );
}

export function ContainerCardArchivesExample({ children, size, removeShadow }) {
  return (
    <ContainerArchiveExample size={size} removeShadow={removeShadow}>
      <ContainerChildren>{children}</ContainerChildren>
    </ContainerArchiveExample>
  );
}

export function ContainerCardNote({ title, subtitle, corIcon = '#404c3f' }) {
  return (
    <ContainerNote>
      <TitleNote>
        <FaInfoCircle color={corIcon} size={35} />
        <TitleTextNote>{title}</TitleTextNote>
      </TitleNote>
      {subtitle && (
        <div
          style={{
            marginBottom: '10px',
            fontSize: '15px',
          }}
        >
          {subtitle}
        </div>
      )}
    </ContainerNote>
  );
}

export function ContainerCardAction({ title, message, onBack, onFinish }) {
  return (
    <ContainerAction>
      <TitleAction>
        <FaQuestionCircle color="#404c3f" size={35} />
        <TitleTextAction>{title}</TitleTextAction>
      </TitleAction>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>{message}</div>
      <ButtonContainer>
        <Button onClick={onBack}>Voltar ao Atendimento</Button>
        <Button primary onClick={onFinish}>
          Finalizar Atendimento
        </Button>
      </ButtonContainer>
    </ContainerAction>
  );
}

export function ContainerCardActionAdm({ title, message, onBack }) {
  return (
    <ContainerAction>
      <TitleAction>
        <FaQuestionCircle color="#404c3f" size={35} />
        <TitleTextAction>{title}</TitleTextAction>
      </TitleAction>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>{message}</div>
      <ButtonContainer>
        <Button onClick={onBack}>Cancelar Pedido de Finalização</Button>
      </ButtonContainer>
    </ContainerAction>
  );
}

export function ContainerCardReview({ onSubmit }) {
  const [serviceRating, setServiceRating] = useState(1); // Inicializa com 1 estrela
  const [resolutionRating, setResolutionRating] = useState(1); // Inicializa com 1 estrela
  const [comment, setComment] = useState('');

  const handleStarClick = (setter, rating) => {
    if (rating > 0) {
      // Garante que o rating mínimo seja 1
      setter(rating);
    }
  };

  return (
    <ContainerAction>
      <TitleAction>
        <FaQuestionCircle color="#404c3f" size={35} />
        <TitleTextAction>Avalie sua experiência com o Ticket</TitleTextAction>
      </TitleAction>
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>Atendimento</div>
      <StarRating
        rating={serviceRating}
        onRatingChange={(rating) => handleStarClick(setServiceRating, rating)}
        editable // Torna o rating editável
      />
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>Satisfação</div>
      <StarRating
        rating={resolutionRating}
        onRatingChange={(rating) =>
          handleStarClick(setResolutionRating, rating)
        }
        editable // Torna o rating editável
      />
      <div style={{ marginBottom: '10px', fontSize: '15px' }}>
        Deixe seu comentário
      </div>
      <textarea
        style={{ width: '100%', minHeight: '100px', marginBottom: '20px' }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Escreva seu comentário aqui..."
      />
      <ButtonContainer>
        <Button
          primary
          onClick={() => onSubmit(serviceRating, resolutionRating, comment)}
        >
          Enviar Avaliação
        </Button>
      </ButtonContainer>
    </ContainerAction>
  );
}
