import React from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import {
  Container,
  Title,
  Subtitle,
  TableStyled,
  TableRowStyled,
  TableHeaderStyled,
  TableCellHeaderStyled,
  TableCellStyled,
  LoadingAnimation,
  LoadingText,
  LoadingContainer,
  ContainerTitle,
  ContainerSearch,
  StyledInput,
} from './styles';
import Label from '../Label';
/**
 * @param noShowBox {boolean};
 */
export function Table({
  title,
  subtitle,
  noShowBox,
  children,
  loading,
  showSearch,
  onChangeFilter,
}) {
  return (
    <Container $subtable={false} noBox={noShowBox}>
      <ContainerTitle>
        <Title $subtitle={subtitle}>{title}</Title>
        {showSearch ? (
          <ContainerSearch>
            <Label text="Filtrar" horizontal />
            <StyledInput
              placeholder="Digite para filtrar os registros..."
              onChange={(a) => {
                if (onChangeFilter) {
                  onChangeFilter(a.target.value);
                }
              }}
            />
          </ContainerSearch>
        ) : (
          ''
        )}
      </ContainerTitle>
      {subtitle ? <Subtitle>{subtitle || ''}</Subtitle> : null}

      {loading ? (
        <LoadingContainer>
          <LoadingText>Carregando...</LoadingText>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <TableStyled>{children}</TableStyled>
      )}
    </Container>
  );
}

export function SubTable({ title, subtitle, children }) {
  return (
    <Container subtable>
      <Title subtitle={subtitle}>{title}</Title>
      {subtitle ? <Subtitle>{subtitle || ''}</Subtitle> : null}

      <TableStyled>{children}</TableStyled>
    </Container>
  );
}

export function TableHeader({ children }) {
  return (
    <thead>
      <TableHeaderStyled>{children}</TableHeaderStyled>
    </thead>
  );
}

export function TableCellHeader({ children, onClick }) {
  return (
    <TableCellHeaderStyled onClick={onClick}>{children}</TableCellHeaderStyled>
  );
}

export function TableCellHeaderWithOrder({
  children,
  onClick,
  sortKey,
  sortConfig,
}) {
  const isActive = sortConfig.key === sortKey;
  return (
    <TableCellHeaderStyled onClick={onClick}>
      {children}
      {isActive && (
        <span className="SortIcon">
          {sortConfig.direction === 'ascending' ? (
            <AiOutlineCaretUp />
          ) : (
            <AiOutlineCaretDown />
          )}
        </span>
      )}
    </TableCellHeaderStyled>
  );
}

export function TableCell({ children, ...props }) {
  return <TableCellStyled {...props}>{children}</TableCellStyled>;
}
export function TableRow({ children, ...props }) {
  return <TableRowStyled {...props}>{children}</TableRowStyled>;
}

export function PaginationComponent({ itemsPerPage, totalItems, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i += 1) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a onClick={() => paginate(number)} href="#!" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
