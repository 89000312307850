import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { lighten } from 'polished';

export const Li = styled.li`
  display: flex;
  height: 30px;
  width: calc(100%);

  a {
    width: 100%;
    border-radius: 4px;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  font-weight: 400;
  color: ${(props) => props.theme.colors.muted};
  transition: all 0.2s;

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.bright)};

    cursor: pointer;
  }

  &.active {
    color: ${({ theme }) => lighten(0.1, theme.colors.bright)};
  }
`;
