import React, { forwardRef } from 'react';
import logo from '../../assets/images/user-default.png';
import {
  StyledButton,
  Text,
  ModalButtonText,
  SearchIcon,
  SearchButtonText,
  StyledSearchButton,
  ImageButton,
  ModalButton,
  StyledButtonToLine,
} from './styles';

export default function Button({
  children,
  disabled,
  loading,
  variant,
  color,
  text,
  size,
  ...rest
}) {
  return (
    <StyledButton
      color={color}
      variant={variant}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {loading || children || <Text variant={variant}>{text}</Text>}
    </StyledButton>
  );
}

export function ButtonToLine({ disabled, variant, text, onClick, ...rest }) {
  return (
    <StyledButtonToLine
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <Text variant={variant}>{text}</Text>
    </StyledButtonToLine>
  );
}

export function SearchButton({
  color,
  variant,
  size,
  disabled,
  onClick,
  type,
}) {
  return (
    <StyledSearchButton
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <SearchIcon />
      <SearchButtonText color={color} variant={variant}>
        Pesquisar
      </SearchButtonText>
    </StyledSearchButton>
  );
}

export const ButtonImage = forwardRef(
  ({ color, variant, size, disabled, onClick }, ref) => (
    <ImageButton
      ref={ref}
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <img src={logo} alt="logo" style={{ marginLeft: '10px' }} />
    </ImageButton>
  )
);

export const ButtonModal = forwardRef(
  (
    { children, disabled, loading, variant, color, text, size, onClick },
    ref
  ) => (
    <ModalButton
      ref={ref}
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <ModalButtonText variant={variant}>{text}</ModalButtonText>
    </ModalButton>
  )
);
