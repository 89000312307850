export function removeNoNumber(value) {
  return value.replace(/[^\d]+/g, '');
}

export function formatCpfCnpj(v) {
  // Remove tudo o que não é dígito
  if (!v || v === '') return '';
  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    // CPF: 000.000.000-00

    // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um ponto entre o sexto e o sétimo dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um hífen entre o nono e o décimo dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    // CNPJ: 00.000.000/0000-00

    // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');

    // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

    // Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');

    // Coloca um hífen depois do bloco de quatro dígitos
    v = v.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }

  return v;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getCurrentDate(separator = '-') {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}
