export function documentRequestCreate(
  bar_code,
  due_date,
  value,
  business_id,
  chartaccount_id,
  subchartaccount_id,
  description,
  issuer_id,
  frequency,
  frequencyRepeat,
  tiket_id
) {
  return {
    type: 'DOCUMENT_REQUEST_CREATE',
    payload: {
      bar_code,
      due_date,
      value,
      business_id,
      chartaccount_id,
      subchartaccount_id,
      description,
      issuer_id,
      frequency,
      frequencyRepeat,
      tiket_id,
    },
  };
}

export function documentRequestUpdate(
  id,
  bar_code,
  due_date,
  value,
  business_id,
  chartaccount_id,
  subchartaccount_id,
  description,
  issuer_id,
  frequency,
  tiket_id
) {
  return {
    type: 'DOCUMENT_REQUEST_UPDATE',
    payload: {
      bar_code,
      due_date,
      value,
      business_id,
      chartaccount_id,
      subchartaccount_id,
      description,
      issuer_id,
      frequency,
      tiket_id,
      id,
    },
  };
}

export function documentRequestDelete(id) {
  return {
    type: 'DOCUMENT_REQUEST_DELETE',
    payload: { id },
  };
}

export function documentFailure() {
  return {
    type: 'DOCUMENT_FAILURE',
  };
}

export function documentSuccess() {
  return {
    type: 'DOCUMENT_SUCCESS',
  };
}
