import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaTrashAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus,
} from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import api from '../../../services/api';
import { showModal } from '../../../store/modules/modal/actions';
import { softwareRequestDelete } from '../../../store/modules/software/actions';
import { softModuleRequestDelete } from '../../../store/modules/softmodule/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListGroup() {
  const [software, setSoftware] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.software.loading);
  const loadingSub = useSelector((state) => state.softmodule.loading);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    const getCharts = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('softwares');
        if (response.data) {
          response.data.forEach((element) => {
            element.hideSub = true;
          });

          setSoftware(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getCharts();
  }, [loading, loadingSub]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o software selecionado?',
      onConfirm: () => {
        dispath(softwareRequestDelete(id));
      },
      loading,
    });
  }

  async function handleDeleteMod(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o módulo selecionado?',
      onConfirm: () => {
        console.log('Aqui vai passar o id do modulo');
        console.log(id);
        dispath(softModuleRequestDelete(id));
      },
      loading,
    });
  }

  function handleHideSub(index) {
    const aux = software;

    aux[index].hideSub = !aux[index].hideSub;

    setSoftware([...aux]);
  }
  // console.log('Aqui que passa o id')
  // console.log(id)
  async function handleCreateSoftModule(id) {
    dispath(showModal('CREATE_SOFTMODULE', { software_id: id }));
  }
  async function handleUpdateSoftModule(id, software_id) {
    dispath(showModal('UPDATE_SOFTMODULE', { id, software_id }));
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/software-create')}
        />
      </ContainerButtons>
      <ModalConfirm />

      <Table title="Sofwares cadastrados" loading={loadingTable}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Descrição</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {software.map((soft, index) => (
            <>
              <TableRow key={soft.id}>
                <TableCell>{soft.id}</TableCell>
                <TableCell>{soft.software_name}</TableCell>
                <TableCell>
                  <ContainerActions>
                    <ButtonIcon
                      data-tooltip-id="Editar"
                      onClick={() => {
                        history.push(`software-edit/${soft.id}`);
                      }}
                    >
                      <FaEdit size={15} color="#c3c3c3" />
                      <ReactTooltip effect="solid" />
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id="Excluir"
                      onClick={() => handleDelete(soft.id)}
                    >
                      <FaTrashAlt size={15} color="#c3c3c3" />
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id={
                        soft.hideSub ? 'Mostrar Módulos' : 'Ocultar Módulos'
                      }
                      onClick={() => {
                        handleHideSub(index);
                        // setShowSub(!showSub);
                      }}
                    >
                      {soft.hideSub ? (
                        <FaAngleDown size={18} color="#c3c3c3" />
                      ) : (
                        <FaAngleUp size={18} color="#c3c3c3" />
                      )}
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id="Novo Módulo"
                      onClick={() => {
                        handleCreateSoftModule(soft.id);
                      }}
                    >
                      <FaPlus size={15} color="#c3c3c3" />
                    </ButtonIcon>
                  </ContainerActions>
                </TableCell>
              </TableRow>
              {soft.softModules && soft.softModules.length > 0 ? (
                <TableRow subtable hide={soft.hideSub}>
                  <TableCell />
                  <TableCell colSpan="3">
                    <SubTable title="Módulos">
                      <TableHeader>
                        <TableCellHeader>#</TableCellHeader>
                        <TableCellHeader>Descrição</TableCellHeader>
                        <TableCellHeader>Detalhes</TableCellHeader>
                        <TableCellHeader>Ações</TableCellHeader>
                      </TableHeader>
                      <tbody>
                        {soft.softModules.length > 0 &&
                          soft.softModules.map((mod) => (
                            <TableRow>
                              <TableCell>{mod.id}</TableCell>
                              <TableCell>{mod.description}</TableCell>
                              <TableCell>{mod.details}</TableCell>
                              <TableCell>
                                <ContainerActions>
                                  <ButtonIcon
                                    data-tooltip-id="Editar"
                                    onClick={() => {
                                      handleUpdateSoftModule(mod.id, soft.id);
                                    }}
                                  >
                                    <FaEdit size={15} color="#c3c3c3" />
                                  </ButtonIcon>
                                  <ButtonIcon
                                    data-tooltip-id="Excluir"
                                    onClick={() => handleDeleteMod(mod.id)}
                                  >
                                    <FaTrashAlt size={15} color="#c3c3c3" />
                                  </ButtonIcon>
                                </ContainerActions>
                              </TableCell>
                            </TableRow>
                          ))}
                      </tbody>
                    </SubTable>
                  </TableCell>
                </TableRow>
              ) : null}
            </>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListGroup;
