import { produce } from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
};

// eslint-disable-next-line default-param-last
export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AUTH_SIGN_IN_REQUEST':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'AUTH_SIGN_IN_SUCCESS':
      return produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
      });
    case 'AUTH_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case 'AUTH_SIGN_OUT_SUCCESS':
      return produce(state, (draft) => {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
      });
    default:
      return state;
  }
}
