import React, { Component } from 'react';
import { Route, Link as Redirect, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthLayout from '../../pages/layout/auth';
import DefaultLayout from '../../pages/layout/default';

import { store } from '../../store';

export default function RouteWrapper({
  isPrivate,
  element,
  rolesPermitted,
  path,
}) {
  const { signed } = store.getState().auth;
  let roles = null;
  if (store.getState().user.user) {
    roles = store.getState().user.user.roles;
  }

  if (!signed && isPrivate) {
    return <Navigate exact to="/" replace />;
    // return <Navigate path="/" render={() => <Redirect to="/" />} />;
  }

  if (signed && !isPrivate) {
    return <Navigate to="/dashboard" replace />;
    // return (
    //   <Navigate path="/dashboard" render={() => <Redirect to="/dashboard" />} />
    // );
  }

  if (signed && isPrivate && rolesPermitted && roles) {
    const permitted = roles.some((r) => rolesPermitted.indexOf(r) >= 0);

    if (!permitted) {
      return <Navigate to="/accessdenied" replace />;
    }
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
