import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import history from '../../../history';
import api from '../../../services/api';
import { showModal } from '../../../store/modules/modal/actions';
import { updateRequestDelete } from '../../../store/modules/update/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListUpdate() {
  const [updates, setupdates] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.update.loading);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataOriSearch, setDataOriSearch] = useState([]);

  useEffect(() => {
    const getupdates = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('updates');
        if (response.data) {
          setupdates(response.data);
          setDataOriSearch(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getupdates();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(updateRequestDelete(id));
      },
      loading,
    });
  }

  async function handleDetails(id) {
    // modalConfirm({
    //   title: 'Confirmar exclusão',
    //   message: 'Deseja realmente excluir o item selecionado?',
    //   onConfirm: () => {
    //     dispath(updateRequestDelete(id));
    //   },
    //   loading,
    // });
    dispath(showModal('DETAILS_UPDATE', { id }));
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setupdates(resultadoFiltrado);
  }
  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Nova Atualização"
          onClick={() => history.push('/update-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table
        title="Atualizações cadastradas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Software</TableCellHeader>
          <TableCellHeader>Versão</TableCellHeader>
          <TableCellHeader>Dt. Cadastro</TableCellHeader>
          <TableCellHeader>Detalhes</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {updates.map((update) => (
            <TableRow key={update.id}>
              <TableCell>{update.id}</TableCell>
              <TableCell>{update.software.software_name}</TableCell>
              <TableCell>{update.version}</TableCell>
              <TableCell>
                {moment(update.created_at).format('DD/MM/YYYY HH:mm z')}
              </TableCell>
              <TableCell>{update.details}</TableCell>

              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`update-edit/${update.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(update.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon onClick={() => handleDetails(update.id)}>
                    <FaEllipsisH size={15} color="#c3c3c3" />
                    <ReactTooltip content="Detalhes da Atualização" />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListUpdate;
