export function issuerRequestCreate(name, cnpj, email, phone, responsable) {
  return {
    type: 'ISSUER_REQUEST_CREATE',
    payload: { name, cnpj, email, phone, responsable },
  };
}

export function issuerRequestUpdate(id, name, cnpj, email, phone, responsable) {
  return {
    type: 'ISSUER_REQUEST_UPDATE',
    payload: { name, cnpj, email, phone, responsable, id },
  };
}

export function issuerRequestDelete(id) {
  return {
    type: 'ISSUER_REQUEST_DELETE',
    payload: { id },
  };
}

export function issuerFailure() {
  return {
    type: 'ISSUER_FAILURE',
  };
}

export function issuerSuccess() {
  return {
    type: 'ISSUER_SUCCESS',
  };
}
