import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
  servers: [], // Adicione um array para armazenar os servidores
};

export default function serverReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CREATE_SERVER_REQUEST':
    case 'UPDATE_SERVER_REQUEST':
    case 'DELETE_SERVER_REQUEST':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'LIST_SERVERS_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.servers = action.payload; // Atualize o estado com a lista de servidores
      });

    case 'CREATE_SERVER_SUCCESS':
    case 'UPDATE_SERVER_SUCCESS':
    case 'DELETE_SERVER_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
      });

    case 'CREATE_SERVER_FAILURE':
    case 'UPDATE_SERVER_FAILURE':
    case 'DELETE_SERVER_FAILURE':
    case 'LIST_SERVERS_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
        // Lidar com erros, se necessário
      });

    default:
      return state;
  }
}
