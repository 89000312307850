import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { documentRequestCreate } from '../../../store/modules/document/actions';
import history from '../../../history';
import api from '../../../services/api';
import { formatBytes } from '../../../utils/functions';
import Input, {
  InputFile,
  InputNoForm,
  InputDatePicker,
  InputCurrency,
} from '../../../components/Input';

import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import { RadioGroup } from '../../../components/Radio';

import ContainerCard from '../../../components/ContainerCard';
import SelectReact from '../../../components/SelectReaact';
import Select from '../../../components/Select';
import {
  ContainerButtons,
  CardFile,
  CardModal,
  ButtonNoMargin,
  GridLayout,
  ContainerInputFile,
  ContainerInputRadio,
} from './styles';

const ValidateSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
  value: Yup.number().required('O valor é obrigatório'),
  bar_code: Yup.string().required('O código de barras é obrigatório'),
  due_date: Yup.date().required('O vencimento é obrigatório'),
  issuer_id: Yup.number().required('O emitente é obrigatório'),
  business_id: Yup.number().required('O destinatário é obrigatório'),
  fileDirectory: Yup.mixed(),
  chartaccount_id: Yup.number().required('O plano de contas é obrigatório'),
  subchartaccount_id: Yup.number().required(
    'O sub-plano de contas é obrigatório'
  ),
  frequency: Yup.string().required('A frequência deve ser informada'),

  frequencyRepeat: Yup.number().when('frequency', {
    is: (frequency) => frequency === 'M',
    then: Yup.number()
      .required('A quantidade de meses é obrigatório')
      .moreThan(0, 'Informe um número maior que zero (0)'),
  }),
  // .required('A Frequência é obriatória')
});

function Create() {
  const [file, setFile] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);
  const [openPass, setOpenPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [password, setPassword] = useState('');
  const [issuers, setIssuer] = useState([]);
  const [charAcount, setCharAccout] = useState([]);
  const [subCharAcount, setSubCharAccout] = useState([]);
  const [companies, setCompany] = useState([]);

  const inputPassRef = useRef(null);

  useEffect(() => {
    const getIssuer = async () => {
      try {
        const response = await api.get(`issuers`);
        if (response.data) {
          setIssuer(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getChartAccount = async () => {
      try {
        const response = await api.get(`chartaccounts`);
        if (response.data) {
          setCharAccout(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getCompany = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setCompany(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getIssuer();
    getCompany();
    getChartAccount();
  }, []);

  useEffect(() => {
    const handleSubmitFile = async () => {
      const data = new FormData();
      data.append('file', file);
      data.append('password', password);
      if (!file) return;
      if (!confirmPass) return;

      if (file.size > 5242880) {
        toast.warn('Selecione um arquivo de até 5BM.');
        return;
      }
      try {
        const responseFile = await api.post('filedocuments', data);
        if (responseFile.data) {
          const response = await api.get(
            `extractdatafiles/${responseFile.data.id}`
          );
          if (response.data) setFileInfo(response.data.infoFile);
          else setFileInfo(null);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.info(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    handleSubmitFile();
  }, [confirmPass]);

  useEffect(() => {
    setConfirmPass(false);
    setPassword('');
    if (!file) setOpenPass(false);
    else {
      setOpenPass(true);
    }
  }, [file]);

  useEffect(() => {
    if (inputPassRef.current && openPass) inputPassRef.current.focus();
  }, [inputPassRef.current]);

  function handleAcceptedFiles(f) {
    setFile(f[0]);
  }
  async function handleSubmit({
    bar_code,
    due_date,
    value,
    business_id,
    chartaccount_id,
    subchartaccount_id,
    description,
    issuer_id,
    frequency,
    frequencyRepeat,
  }) {
    if (openPass) return;

    dispath(
      documentRequestCreate(
        bar_code,
        due_date,
        value,
        business_id,
        chartaccount_id,
        subchartaccount_id,
        description,
        issuer_id,
        frequency,
        frequencyRepeat,
        fileInfo.id // tiket_id
      )
    );
  }
  function handleReset(values, { setStatus, resetForm }) {
    setStatus(true);
  }

  function internalReset(values, setSatus, initialValues) {
    setFile(null);
    setFileInfo(null);
    values = initialValues;
    setSatus(true);
  }
  function handleConfirmPass() {
    setOpenPass(false);
    setConfirmPass(true);
  }
  return (
    <>
      <CardModal opened={openPass}>
        <span>
          <ContainerCard
            title="O arquivo possue senha?"
            removeShadow
            size={350}
          >
            <Label text="Informe aqui a senha do arquivo para que seja possível ler os dados." />
            <Label text="Se não tiver senha, apenas clique em Continuar." />
            <br />
            <InputNoForm
              placeholder=""
              type="password"
              name="password"
              value={password}
              ref={inputPassRef}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonNoMargin
              size="contained"
              text="Continuar"
              type="button"
              onClick={handleConfirmPass}
            />
          </ContainerCard>
        </span>
      </CardModal>

      <ContainerCard title="Criar novo Documento">
        <Formik
          onSubmit={handleSubmit}
          onReset={handleReset}
          enableReinitialize
          initialValues={{
            description: '',
            value: fileInfo ? fileInfo.valor : 0,
            due_date: fileInfo ? new Date(fileInfo.vencimento) : null,
            bar_code: fileInfo ? fileInfo.codigoInput : '',
            chartaccount_id: '',
            subchartaccount_id: '',
            frequency: '',
            frequencyRepeat: '',
          }}
          validationSchema={ValidateSchema}
        >
          {(props) => (
            <StyledForm>
              {file && confirmPass ? (
                <GridLayout>
                  <div>
                    <CardFile>
                      <span>IMG</span>
                      <div>
                        <h2>{file.name}</h2>
                        <h3>{formatBytes(file.size)}</h3>
                      </div>
                    </CardFile>
                    <Label text="Observação" />
                    <Field
                      placeholder=""
                      type="text"
                      name="description"
                      component={Input}
                    />

                    <Label text="Código de barras" />
                    <Field
                      placeholder=""
                      type="text"
                      name="bar_code"
                      component={Input}
                    />
                  </div>
                  <span>
                    <span>
                      <span>
                        <Label horizontal text="Valor" />
                        <Field
                          placeholder=""
                          type="text"
                          name="value"
                          component={InputCurrency}
                          onChange={(event, value) => {
                            props.setFieldValue('value', value);
                          }}
                        />
                      </span>
                      <span>
                        <Label horizontal text="Vencimento" />
                        <Field
                          placeholder=""
                          type="date"
                          name="due_date"
                          selected={props.values.due_date}
                          component={InputDatePicker}
                          onChange={(d) => {
                            props.setFieldValue('due_date', d);
                          }}
                        />
                      </span>
                    </span>
                    <Label text="Emitente" />
                    <Field
                      value={props.values.issuer_id}
                      placeholder="Selecione o emitente"
                      onChange={(d) => {
                        console.log(d);
                        props.setFieldValue('issuer_id', d.value);
                      }}
                      options={issuers.map((issuer) => ({
                        label: issuer.name,
                        value: issuer.id,
                      }))}
                      name="issuer_id"
                      component={SelectReact}
                    />

                    <Label text="Empresa destino" />
                    <Field
                      value={props.values.business_id}
                      placeholder="Selecione o destinatário"
                      onChange={(d) => {
                        props.setFieldValue('business_id', d.value);
                      }}
                      options={companies.map((business) => ({
                        label: business.company_name,
                        value: business.id,
                      }))}
                      name="business_id"
                      component={SelectReact}
                    />
                    <Label text="Selecione um Plano de contas" />
                    <Field
                      name="chartaccount_id"
                      component={Select}
                      type="text"
                      onChange={(e) => {
                        props.setFieldValue('chartaccount_id', e.target.value);
                        const sub = charAcount.filter(
                          (chart) => chart.id === e.target.value
                        );
                        setSubCharAccout(sub);
                        console.log(sub);
                      }}
                    >
                      <option value="" disabled>
                        Selecione um Plano de contas
                      </option>
                      {charAcount.map((chart) => (
                        <option key={chart.id} value={chart.id}>
                          {chart.description}
                        </option>
                      ))}
                    </Field>
                    <Label text="Selecione um Sub plano de contas" />
                    <Field
                      name="subchartaccount_id"
                      component={Select}
                      type="text"
                    >
                      <option value="" disabled>
                        Selecione um Sub plano de contas
                      </option>
                      {subCharAcount && subCharAcount[0]
                        ? subCharAcount[0].subCharts.map((chart) => (
                            <option key={chart.id} value={chart.id}>
                              {chart.description}
                            </option>
                          ))
                        : null}
                    </Field>
                    <Label text="Frequência de repetição do documento" />
                    <Field
                      placeholder=""
                      {...props}
                      options={[
                        {
                          value: 'N',
                          name: 'frequency',
                          label: 'Não se repete',
                        },
                        { value: 'M', name: 'frequency', label: 'Mensal' },
                        { value: 'F', name: 'frequency', label: 'Ano atual' },
                        {
                          value: 'A',
                          name: 'frequency',
                          label: 'Próximos 12 meses',
                        },
                      ]}
                      name="frequency"
                      value={props.values.frequency}
                      component={RadioGroup}
                      onChange={(e) => {
                        props.setFieldValue('frequency', e.target.value);
                      }}
                    />

                    {props.values.frequency === 'M' ? (
                      <>
                        <Label text="Informe quantos meses deseja replicar o documento" />
                        <Field
                          placeholder=""
                          type="text"
                          name="frequencyRepeat"
                          component={Input}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;

                            // if value is not blank, then test the regex

                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            )
                              props.setFieldValue(
                                'frequencyRepeat',
                                e.target.value
                              );
                          }}
                        />
                        {props.values.frequencyrepeat}
                      </>
                    ) : null}
                    <ContainerButtons>
                      <Button
                        size="contained"
                        variant="outline"
                        text="Cancelar"
                        type="button"
                        onClick={() => history.push('/group-list')}
                      />
                      <Button
                        size="contained"
                        variant="outline"
                        text="Limpar e reiniciar"
                        type="button"
                        onClick={() => {
                          internalReset(
                            props.values,
                            props.setStatus,
                            props.initialValues
                          );
                        }}
                      />
                      <Button
                        size="contained"
                        type="submit"
                        text={loading ? 'Aguarde...' : 'Salvar'}
                      />
                    </ContainerButtons>
                  </span>
                </GridLayout>
              ) : (
                <ContainerInputFile>
                  <Label text="Arquivo de boleto" />
                  <InputFile
                    name="fileDirectory"
                    accept="image/jpeg, application/pdf"
                    onDrop={(f) => handleAcceptedFiles(f)}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      props.setFieldValue('fileDirectory', e.target.value);
                    }}
                  />

                  <ContainerButtons>
                    <Button
                      size="contained"
                      variant="outline"
                      text="Cancelar"
                      type="button"
                      onClick={() => history.push('/group-list')}
                    />
                  </ContainerButtons>
                </ContainerInputFile>
              )}
            </StyledForm>
          )}
        </Formik>
      </ContainerCard>
    </>
  );
}

export default Create;
