import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaTrashAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus,
} from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import api from '../../../services/api';
import { showModal } from '../../../store/modules/modal/actions';
import { recurrentExportRequestDelete } from '../../../store/modules/recurrentexport/actions';

import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListGroup() {
  const [recurrents, setRecurrents] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.recurrentexport.loading);
  const [loadingTable, setLoadingTable] = useState(false);
  useEffect(() => {
    const getRecurrent = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('recurrentexports');
        setRecurrents(response.data);
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getRecurrent();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o software selecionado?',
      onConfirm: () => {
        dispath(recurrentExportRequestDelete(id));
      },
      loading,
    });
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/recurrent-export-create')}
        />
      </ContainerButtons>
      <ModalConfirm />

      <Table title="Envios recorrentes cadastrados" loading={loadingTable}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Ativo</TableCellHeader>
          <TableCellHeader>Empresa</TableCellHeader>
          <TableCellHeader>Tipo Doc.</TableCellHeader>
          <TableCellHeader>Sub Tipo Doc.</TableCellHeader>
          <TableCellHeader>Frequência</TableCellHeader>
          <TableCellHeader>Email</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {recurrents.map((rec, index) => (
            <TableRow key={rec.id}>
              <TableCell>{rec.id}</TableCell>
              <TableCell>{rec.active === 'S' ? 'Sim' : 'Não'}</TableCell>
              <TableCell>{rec.business.fantasy_name}</TableCell>
              <TableCell>{rec.typedoc === 55 ? 'NF-e' : 'NFC-e'}</TableCell>
              <TableCell>
                {(() => {
                  // eslint-disable-next-line default-case
                  switch (rec.subtypedoc) {
                    case 1:
                      return 'Emitidos';
                    case 2:
                      return 'Recebidos por Emissão';
                    case 3:
                      return 'Recebidos por Entrada';
                    default:
                      return '';
                  }
                })()}
              </TableCell>
              <TableCell>
                {(() => {
                  // eslint-disable-next-line default-case
                  switch (rec.frequency) {
                    case 1:
                      return 'A cada 5 dias';
                    case 2:
                      return 'A cada dezena';
                    case 3:
                      return 'A cada quinzena';
                    case 4:
                      return 'Mensalmente';
                    default:
                      return '';
                  }
                })()}
              </TableCell>
              <TableCell>{rec.email}</TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`recurrent-export-edit/${rec.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip effect="solid" />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(rec.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListGroup;
