export function recurrentExportRequestCreate(data) {
  return {
    type: 'RECURRENT_EXPORT_REQUEST_CREATE',
    payload: { data },
  };
}
export function recurrentExportOnDemandCreate(data) {
  return {
    type: 'RECURRENT_EXPORT_ONDEMAND_REQUEST_CREATE',
    payload: { data },
  };
}
export function recurrentExportRequestUpdate(id, data) {
  return {
    type: 'RECURRENT_EXPORT_REQUEST_UPDATE',
    payload: { id, data },
  };
}

export function recurrentExportRequestDelete(id) {
  return {
    type: 'RECURRENT_EXPORT_REQUEST_DELETE',
    payload: { id },
  };
}

export function recurrentExportFailure() {
  return {
    type: 'RECURRENT_EXPORT_FAILURE',
  };
}

export function recurrentExportSuccess() {
  return {
    type: 'RECURRENT_EXPORT_SUCCESS',
  };
}
