import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import ContainerCard from '../../../components/ContainerCard';
import StyledForm from '../../../components/Form/StyledForm';
import { SelectReactNoForm } from '../../../components/SelectReaact';
import history from '../../../history';
import api from '../../../services/api';
import { companyRequestDelete } from '../../../store/modules/company/actions';
import { formatCpfCnpj } from '../../../utils/functions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';
import Label from '../../../components/Label';
import Button, { SearchButton } from '../../../components/Button';
import Select from '../../../components/Select';
import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions, Etiqueta } from './styles';

function ListCity() {
  const [companies, setCompanies] = useState([]);
  const [limitQuery, setLimitQuery] = useState(25);
  const [groups, setGroups] = useState([]);
  const [companiesOri, setCompaniesOri] = useState([]);
  const [groupQuery, setGroupQuery] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const dispath = useDispatch();
  const loading = useSelector((state) => state.company.loading);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get(`business`);
        if (response.data) {
          setCompanies(response.data);
          setCompaniesOri(response.data);
        }

        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getCompanies();
    getGroup();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(companyRequestDelete(id));
      },
      loading,
    });
  }
  function trataCertVenceu(dataExpirantion) {
    const expirationDate = moment(dataExpirantion, 'DD/MM/YYYY');
    const currentDate = moment();
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

    if (daysUntilExpiration < 0) {
      return 'Vencido'; // Data já expirou
    }
    if (daysUntilExpiration <= 15) {
      return 'Vencendo'; // Data expira em 15 dias ou menos
    }
    return 'Válido'; // Data ainda válida
  }
  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = companiesOri.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setCompanies(resultadoFiltrado);
  }

  async function handleSubmit() {
    try {
      // const response = await api.get(
      //   `/searchcompany?group_id=${
      //     !groupQuery || groupQuery.length === 0 ? '' : groupQuery
      //   }&limit=${limitQuery}`
      // );
      const response = await api.get(
        `/searchcompany?group_id=${
          !groupQuery || groupQuery.length === 0 ? '' : groupQuery
        }`
      );
      if (response.data) {
        setCompanies(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerList>
      <ModalConfirm />
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/company-create')}
        />
      </ContainerButtons>
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{}}>
        {(props) => (
          <StyledForm autocomplete="off">
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Grupo de Empresas" />
                  <SelectReactNoForm
                    value={(groupQuery, groups.description)}
                    placeholder="Selecione um grupo"
                    onChange={(e) => {
                      if (e) {
                        setGroupQuery(e.value);
                      } else {
                        setGroupQuery(null);
                        // setNfes([]);
                      }
                    }}
                    options={groups.map((g) => ({
                      label: g.description,
                      value: g.id,
                    }))}
                    name="idBusiness"
                  />
                </div>
                <div
                  id="1"
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    maxWidth: '110px',
                    display: 'none',
                  }}
                >
                  <Label text="Qtde. Registros" />
                  <Field
                    name="qtdregister"
                    component={Select}
                    type="text"
                    onChange={(e) => {
                      setLimitQuery(e.target.value);
                    }}
                  >
                    <option key="25" value="25">
                      25
                    </option>
                    <option key="50" value="50">
                      50
                    </option>
                    <option key="100" value="100">
                      100
                    </option>
                    <option key="300" value="300">
                      300
                    </option>
                    <option key="500" value="500">
                      500
                    </option>
                    <option key="750" value="750">
                      750
                    </option>
                    <option key="1000" value="1000">
                      1000
                    </option>
                    <option key="todos" value="todos">
                      Todos
                    </option>
                  </Field>
                </div>
                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Pesquisar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>
      <Table
        title="Empresas cadastradas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Fantasia</TableCellHeader>
          <TableCellHeader>Razão social</TableCellHeader>
          <TableCellHeader>Filial</TableCellHeader>
          <TableCellHeader>CNPJ</TableCellHeader>
          <TableCellHeader>Cidade</TableCellHeader>
          <TableCellHeader>Grupo</TableCellHeader>
          <TableCellHeader>Certificado Dig.</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {companies.map((company) => (
            <TableRow key={company.id}>
              <TableCell>{company.id}</TableCell>
              <TableCell>{company.fantasy_name}</TableCell>
              <TableCell>{company.company_name}</TableCell>
              <TableCell>{company.code}</TableCell>
              <TableCell>{formatCpfCnpj(company.cnpj)}</TableCell>
              <TableCell>{`${company.city.name}-${company.city.uf}`}</TableCell>
              <TableCell>{`${
                company.group ? company.group.description : ''
              }`}</TableCell>
              <TableCell>
                {company.expirationCert ? (
                  <Etiqueta situacao={trataCertVenceu(company.expirationCert)}>
                    {company.expirationCert}
                  </Etiqueta>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`company-edit/${company.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(company.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListCity;
