import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { userFailure, userSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* userCreate({ payload }) {
  try {
    yield call(api.post, 'users', payload);

    yield put(userSuccess());

    history.push('/user-list');
    toast.success('Novo usuário cadastrado');
  } catch (error) {
    yield put(userFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Usuário não cadastrado');
  }
}

export function* userUpdate({ payload }) {
  const displayedMessages = new Set();
  try {
    const { id, name, username, email, access, pass } = payload;

    console.log('Passou aqui', payload);

    const teste = yield call(api.put, `users/${id}`, {
      name,
      user_name: username,
      email,
      password: pass,
    });

    console.log('Opa', teste);
    yield call(api.delete, `userroles/${id}`);

    yield call(api.post, 'userroles', {
      description: access,
      user_id: id,
    });

    yield put(userSuccess());

    history.push('/user-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(userFailure());
    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors && data.errors.length > 0) {
        // Exibir mensagens de erro personalizadas
        data.errors.forEach((err) => {
          // Verificar se a mensagem já foi exibida
          if (!displayedMessages.has(err.message)) {
            toast.error(err.message);

            // Adicionar a mensagem ao conjunto para evitar exibições duplicadas
            displayedMessages.add(err.message);
          }
        });
      } else {
        // Se não houver mensagens personalizadas, exibir uma mensagem padrão
        const defaultMessage =
          'Erro de validação: Não foi possível alterar o usuário.';

        // Verificar se a mensagem padrão já foi exibida
        if (!displayedMessages.has(defaultMessage)) {
          toast.error(defaultMessage);

          // Adicionar a mensagem ao conjunto
          displayedMessages.add(defaultMessage);
        }
      }
    } else {
      // Se o erro não for 422, exibir uma mensagem genérica
      const genericMessage =
        'Erro ao atualizar o Usuário. Tente novamente mais tarde.';

      // Verificar se a mensagem genérica já foi exibida
      if (!displayedMessages.has(genericMessage)) {
        toast.error(genericMessage);

        // Adicionar a mensagem ao conjunto
        displayedMessages.add(genericMessage);
      }
    }
  }
}

export function* userDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `users/${id}`);

    yield put(userSuccess());

    history.push('/user-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(userFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Registro não deletado');
  }
}

export default all([
  takeLatest('USER_REQUEST_CREATE', userCreate),
  takeLatest('USER_REQUEST_UPDATE', userUpdate),
  takeLatest('USER_REQUEST_DELETE', userDelete),
]);
