import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import history from '../../../history';
import api from '../../../services/api';
import { cityRequestDelete } from '../../../store/modules/city/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListCity() {
  const [cities, setCities] = useState([]);
  const [dataOriSearch, setDataOriSearch] = useState([]);
  const dispath = useDispatch();
  const loading = useSelector((state) => state.city.loading);

  useEffect(() => {
    const getCities = async () => {
      try {
        const response = await api.get('cities');
        if (response.data) {
          setCities(response.data);
          setDataOriSearch(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getCities();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(cityRequestDelete(id));
      },
      loading,
    });
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setCities(resultadoFiltrado);
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/city-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table
        title="Cidades cadastradas"
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Nome</TableCellHeader>
          <TableCellHeader>UF</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {cities.map((city) => (
            <TableRow key={city.id}>
              <TableCell>{city.id}</TableCell>
              <TableCell>{city.name}</TableCell>
              <TableCell>{city.uf}</TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`city-edit/${city.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(city.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListCity;
