import styled from 'styled-components';
import { darken } from 'polished';

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
`;

export const ContainerTickets = styled.div`
  width: 10%;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;

  flex-direction: column;
  align-items: 'flex-start';
  padding: 20px 15px;
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerPieChart = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 20px 15px;
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerDetTicketsUp = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 5px;
  padding: 20px 15px;
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerDetTicketsDown = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 5px;
  padding: 20px 15px;
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerDetTickets = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 20px 15px;
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const TicketInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: '100%'; // Ajusta para o padding horizontal de 20px em cada lado
  /* min-width: 200px; // Garantir uma largura mínima */
`;

export const TicketInfo = styled.div`
  margin: 7px 5px;
  padding: 20px 20px;
  border-radius: 5px;
  color: white;
  text-align: center;
  background-color: #ccc; // Cor padrão
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease; // Adiciona a transição suave

  &.total {
    background-color: #007bff;
  }

  &.closed {
    background-color: #28a745;
  }

  &.open {
    background-color: #ffc107;
  }

  &.pending {
    background-color: #dc3545;
  }

  &:hover {
    transform: scale(1.05); // Aumenta o tamanho ao passar o mouse
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Aumenta a sombra ao passar o mouse
  }
`;

export const TicketTitle = styled.h3`
  margin-bottom: 5px;
  font-size: 1rem;
`;

export const TicketValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;
