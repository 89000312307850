import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { issuerFailure, issuerSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* issuerCreate({ payload }) {
  try {
    const { name, cpnj, email, phone, responsable } = payload;

    yield call(api.post, 'issuers', {
      name,
      cpnj,
      email,
      phone,
      responsable,
    });

    yield put(issuerSuccess());

    history.push('/issuer-list');
    toast.success('Novo Emitente cadastrado');
  } catch (error) {
    yield put(issuerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Emitente não cadastrado');
  }
}
export function* issuerUpdate({ payload }) {
  try {
    const { id, name, cpnj, email, phone, responsable } = payload;

    yield call(api.put, `issuers/${id}`, {
      name,
      cpnj,
      email,
      phone,
      responsable,
    });

    yield put(issuerSuccess());

    history.push('/issuer-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(issuerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* issuerDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `issuers/${id}`);

    yield put(issuerSuccess());

    history.push('/issuer-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(issuerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('ISSUER_REQUEST_CREATE', issuerCreate),
  takeLatest('ISSUER_REQUEST_UPDATE', issuerUpdate),
  takeLatest('ISSUER_REQUEST_DELETE', issuerDelete),
]);
