import React from 'react';

import { Container, InputRadio, Error, Text, ContainerOp } from './styles';

function Radio({ text, ...props }) {
  return <InputRadio {...props} />;
}

export function RadioGroup({
  field,
  options,
  onChange: comOnChange,
  form: { touched, errors },
  ...props
}) {
  return (
    <Container>
      <ContainerOp>
        {options.map((op) => (
          <div>
            <Text
              checked={field.value === op.value.toString()}
              htmlFor={field.name + op.value}
            >
              {op.label}
            </Text>
            <InputRadio
              {...props}
              value={op.value}
              name={field.name}
              id={field.name + op.value}
              checked={field.value === op.value}
              onChange={(e) => {
                // Transfere onChange do radio para o onChange do Field
                comOnChange(e);
                // props.setFieldValue(op.name, e.target.value);
              }}
            />
          </div>
        ))}
      </ContainerOp>
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

export function RadioGroupNoForm({
  options,
  selected,
  name,
  onChange: comOnChange,
  ...props
}) {
  return (
    <Container noForm>
      <ContainerOp>
        {options.map((op) => (
          <div>
            <Text
              checked={selected === op.value.toString()}
              htmlFor={name + op.value}
            >
              {op.label}
            </Text>
            <InputRadio
              {...props}
              value={op.value}
              name={name}
              id={name + op.value}
              checked={selected === op.value}
              onChange={(e) => {
                // Transfere onChange do radio para o onChange do Field
                comOnChange(e);
                // props.setFieldValue(op.name, e.target.value);
              }}
            />
          </div>
        ))}
      </ContainerOp>
    </Container>
  );
}
export default Radio;
