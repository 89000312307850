export function showModal(type, props) {
  return {
    type: 'SHOW_MODAL',
    payload: { modalType: type, modalProps: props },
  };
}

export function hideModal() {
  return {
    type: 'HIDE_MODAL',
    payload: {},
  };
}
