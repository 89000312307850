import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: ${({ noForm }) => (noForm ? '0px' : '25px')};
  flex-direction: column;
`;
export const ContainerOp = styled.div`
  /*
  width: 100%;
  display: flex;
  //position: relative;
  margin-bottom: 8px;
  flex-direction: row;
  align-items: center;
*/
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    border: 1px solid #ccc;
    border-right: 0px;
    overflow: hidden;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid #ccc;
    }

    label {
      margin-bottom: 0px;
    }
    input {
      margin-left: 5px;
    }
  }
`;

export const InputRadio = styled.input.attrs({
  type: 'radio',
})`
  display: none;
  margin-right: 10px;
`;

export const Error = styled.div`
  color: ${(props) => props.theme.colors.error};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '18px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 15px;
  padding-left: 0px;
  font-weight: 400;
  font-size: 12px;
`;

export const Text = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  text-align: center;
  padding: 10px;

  cursor: pointer;

  ${({ checked, theme }) => {
    if (checked === true) {
      return `
        background-color: ${theme.colors.accent};
        color: ${theme.colors.bright}
      `;
    }
    return ``;
  }}
`;
