import { produce } from 'immer';

const INITIAL_STATE = {
  categoryCurrent: null,
  pageName: '',
};

export default function sideMenu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_GROUPMENU':
      return produce(state, (draft) => {
        draft.categoryCurrent = action.payload.categoryCurrent;
      });
    case 'CHANGE_PAGE':
      return produce(state, (draft) => {
        draft.pageName = action.payload.pageName;
      });

    default:
      return state;
  }
}
