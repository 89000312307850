import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

import { AiOutlineClose } from 'react-icons/ai';

import { FaRegFileArchive } from 'react-icons/fa'; // Importe o FaRegFileArchive
import MyEditor from '../../../components/ReactQuill/MyReactQuill'; // Ajuste o caminho conforme a estrutura do seu projeto
import { ticketRequestCreate } from '../../../store/modules/ticket/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input, { InputFile } from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import ContainerCard from '../../../components/ContainerCard';
import { SelectReactNoForm } from '../../../components/SelectReaact';

import {
  ContainerButtons,
  TitleDivider,
  Divider,
  CardFile,
  ContainerInputFile,
} from './styles';

const TicketSchema = Yup.object().shape({
  subject: Yup.string().required('O assunto é obrigatório'),
  ticket_categories_id: Yup.number().required('A Categoria é obrigatório'),
  message: Yup.string().required('A mensagem é obrigatória'),
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// function Tooltip({ id, text, children }) {
//   return (
//     <>
//       <div data-tip={text} data-for={id}>
//         {children}
//       </div>
//       <ReactTooltip id={id} place="top" type="dark" effect="solid" />
//     </>
//   );
// }

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.company.loading);
  const [groups, setGroups] = useState([]);
  const [business, setBusiness] = useState([]);
  const [cadServer, setCadServer] = useState([]);
  const [businessQuery, setBusinessQuery] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [ticketCategories, setTicketCategories] = useState([]);
  const [computers, setComputers] = useState([]);
  const [value, setValue] = useState('');
  const [files, setFiles] = useState([]);
  const [filesId, setFilesId] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);
  const { id } = useParams();

  const handleChange = (content) => {
    setValue(content);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const inputPassRef = useRef(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getTicketCategory = async () => {
      try {
        const response = await api.get(`ticketcategories`);
        if (response.data) {
          setTicketCategories(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const persistedData = localStorage.getItem('persist:sisfoco');

        const parsedData = JSON.parse(persistedData);

        const userData = JSON.parse(parsedData.user);

        const userId = userData.user.id;

        const response = await api.get(`userbusinessbyuserid/${userId}`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getGroup();
    getSoftware();
    getTicketCategory();
    getBusiness();
  }, []);

  const getComputers = async (businessId) => {
    try {
      const response = await api.get(`findByBusinessId/${businessId}`);
      // Definir computadores como lista vazia se nenhum for encontrado
      if (response.data && response.data.length > 0) {
        setComputers(response.data);
      } else {
        setComputers([]); // Limpa a lista se nenhum computador for encontrado
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Erro ao buscar computadores');
      }
    }
  };

  useEffect(() => {
    if (selectedBusinessId) {
      getComputers(selectedBusinessId);
    }
  }, [selectedBusinessId]);

  async function handleSubmit({
    subject,
    situation,
    ticket_categories_id,
    message,
    business_id,
    status,
    computer_id,
  }) {
    try {
      const tempFileIds = [];
      // Mapeia os arquivos e obtém um array de promessas
      const filePromises = files.map(async (file) => {
        const data = new FormData();
        data.append('file', file);

        if (!file) return null;

        if (file.size > 1073741824) {
          toast.warn('Selecione um arquivo de até 124BM.');
          return null;
        }

        const responseFile = await api.post('filedocuments', data);

        if (responseFile.data) {
          tempFileIds.push(responseFile.data.id); // Armazena o ID na matriz temporária
        }

        return responseFile.data ? responseFile.data.id : null;
      });

      await Promise.all(filePromises);

      setFileInfo([...fileInfo, ...tempFileIds]);

      const responseTicket = await api.post('tickets', {
        subject,
        situation,
        ticket_categories_id,
        message,
        business_id: businessQuery,
        status,
        computer_id,
      });

      await Promise.all(
        tempFileIds.map(async (infoFile) => {
          await api.post('ticketfiles', {
            ticket_id: responseTicket.data.id,
            file_id: infoFile,
          });
        })
      );
      history.push('/ticket-list');
      toast.success('Ticket Cadastrado com Sucesso');
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.info(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerCard title="Cadastro de Novo Ticket">
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          subject: '',
          situation: 'AIA',
          ticket_categories_id: '',
          computer_id: '',
          message: '',
          business_id: '',
          status: 'O',
        }}
        validationSchema={TicketSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Empresa" />
            <SelectReactNoForm
              value={(businessQuery, business.fantasy_name)}
              placeholder="Selecione um grupo"
              onChange={(e) => {
                if (e) {
                  setBusinessQuery(e.value);
                  setSelectedBusinessId(e.value);
                } else {
                  setBusinessQuery(null);
                  setSelectedBusinessId(null);
                }
              }}
              options={business.map((ss) => ({
                label: `${ss.code} - ${ss.fantasy_name}`,
                value: ss.id,
              }))}
              name="idGroup"
            />
            <Label text="Assunto do Ticket" />
            <Field
              placeholder=""
              type="text"
              name="subject"
              component={Input}
            />
            <Label text="Categoria" />
            <Field name="ticket_categories_id" component={Select} type="text">
              <option value="" disabled>
                Selecione uma Categoria
              </option>
              {ticketCategories.map((ticketCategory) => (
                <option key={ticketCategory.id} value={ticketCategory.id}>
                  {ticketCategory.description}
                </option>
              ))}
            </Field>
            <Label text="Computador" />
            <Field name="computer_id" component={Select} type="text">
              <option value="" disabled>
                Selecione um Computador
              </option>
              {computers.map((Computer) => (
                <option key={Computer.id} value={Computer.id}>
                  {Computer.description}
                </option>
              ))}
            </Field>
            <Label text="Mensagem" />
            <MyEditor
              onChange={(content) => props.setFieldValue('message', content)}
            />
            <Divider>
              <TitleDivider>Arquivos</TitleDivider>
            </Divider>
            <ContainerInputFile>
              <InputFile multiple name="archive" onDrop={onDrop} />
            </ContainerInputFile>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center', // Centralizar itens horizontalmente
                overflowX: 'hidden', // Remover barra de rolagem horizontal
              }}
            >
              {files.map((file, index) => (
                <CardFile
                  key={file.name}
                  style={{
                    border: '1px solid #ccc',
                    width: '170px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: '1 0 auto',
                  }}
                >
                  <div style={{ marginTop: '5px' }}>
                    <FaRegFileArchive size={30} />
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      flex: '1',
                    }}
                  >
                    <h2
                      style={{
                        maxWidth: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      data-tooltip-id={file.name}
                      data-tooltip-content={file.name}
                    >
                      {file.name},
                      <Tooltip id={file.name} />
                    </h2>
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    <h3>{formatBytes(file.size)}</h3>
                  </div>
                  <button
                    type="button"
                    aria-label="Remover arquivo"
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      color: 'red',
                      marginTop: '5px',
                    }}
                    onClick={() => handleRemoveFile(index)}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </CardFile>
              ))}
            </div>
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/company-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Create;
