import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  //max-width: 500px;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;
  //position: relative;
  margin-bottom: 8px;
  flex-direction: column;
  align-items: 'flex-start';
  padding: 22px 20px;
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerArchive = styled.div`
  width: ${({ size }) => (size ? `${size}px` : '48%')};
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 10px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #ccc;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const ContainerArchiveExample = styled.div`
  width: ${({ size }) => (size ? `${size}px` : '48%')};
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 10px;
  background-color: white;
  border-radius: 4px;
  border: 2px dotted #ccc;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0; /* Mudar a cor de fundo */
    border: 2px solid #aaa; /* Alterar a borda */
    box-shadow: 0px 0px 10px #ccc; /* Adicionar sombra */
    transform: scale(1.02); /* Transformação de escala */
    opacity: 0.9; /* Alterar a opacidade */
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${({ subtitle, showBorder }) =>
    subtitle || showBorder ? '1px' : '20px'};

  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
`;

export const TitleDiscussion = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 1px;
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};

  div {
    order: ${({ alignRight }) => (alignRight ? 2 : 1)};
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ContainerChildren = styled.div`
  width: 100%;
`;
export const Border = styled.div`
  flex: 1;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
`;

export const ContainerNote = styled.div`
  width: 100%;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px 20px;

  background-color: #cff2cc;

  border-radius: 4px;
  // marginbottom: 10px;
`;

export const TitleNote = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TitleTextNote = styled.div`
  margin-left: 8px;
  font-size: 19px;
  font-weight: 700;
  color: #404c3f;
`;

export const ContainerNoteAction = styled.div`
  width: 100%;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px 20px;
  background-color: #cff2cc;
  border-radius: 4px;
`;

export const TitleNoteAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TitleTextNoteAction = styled.div`
  margin-left: 8px;
  font-size: 19px;
  font-weight: 700;
  color: #404c3f;
`;

// Novos estilos
export const ContainerAction = styled.div`
  width: 100%;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  padding: 22px 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
`;

export const TitleAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const TitleTextAction = styled.div`
  margin-left: 8px;
  font-size: 19px;
  font-weight: 700;
  color: #404c3f;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Alinha os botões verticalmente */
  align-items: center; /* Centraliza os botões horizontalmente */
  margin-top: 20px;
  width: 100%; /* Ou ajuste conforme necessário */
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? '#007bff' : '#6c757d')};
  color: white;
  margin: 5px 0; /* Espaçamento entre os botões */

  &:hover {
    opacity: 0.8;
  }
`;
