export function changeCategoryMenu(categoryCurrent) {
  return {
    type: 'CHANGE_GROUPMENU',
    payload: { categoryCurrent },
  };
}

export function changePage(pageName) {
  return {
    type: 'CHANGE_PAGE',
    payload: { pageName },
  };
}
