/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import api from '../../../services/api';
import { licenseRequestDelete } from '../../../store/modules/license/actions';
import { InputCheckBoxNoForm } from '../../../components/Input';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import { ContainerButtons } from '../Create/styles';
import ContainerList from '../../../components/ContainerList';

function Listlicense() {
  const [licenses, setlicenses] = useState([]);

  const [dataOriSearch, setDataOriSearch] = useState([]);
  const [table, setTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.license.loading);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('license');
        if (response.data) {
          setlicenses(response.data);
          setDataOriSearch(response.data);
          // setTable(response.data)
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getCompanies();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(licenseRequestDelete(id));
      },
      loading,
    });
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setlicenses(resultadoFiltrado);
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/license-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table
        title="Licenças cadastradas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Empresa</TableCellHeader>
          <TableCellHeader>Grupo</TableCellHeader>
          <TableCellHeader>Emissão</TableCellHeader>
          <TableCellHeader>Vencimento</TableCellHeader>
          <TableCellHeader>Inativo</TableCellHeader>
        </TableHeader>
        <tbody>
          {licenses.map((license, index) => (
            <TableRow key={license.license.id}>
              <TableCell>{license.license.id}</TableCell>
              <TableCell>{license.company_name}</TableCell>
              <TableCell>
                {`${license.group ? license.group.description : ''}`}
              </TableCell>
              <TableCell>
                {moment(license.license.emission).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>
                {moment(license.license.due).format('DD/MM/YYYY')}
              </TableCell>

              <TableCell>
                <InputCheckBoxNoForm
                  noError
                  placeholder=""
                  type="checkbox"
                  name="block"
                  checked={license.license.blocked_license}
                />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default Listlicense;
