import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import moment from 'moment/moment';
import { setHours, setMinutes, addMinutes } from 'date-fns';
import api from '../../../services/api';
import { updateRequestCreate } from '../../../store/modules/update/actions';
import history from '../../../history';
import { RadioGroupNoForm } from '../../../components/Radio';

import {
  // eslint-disable-next-line import/named
  InputDateTimePickerNoForm,
  InputCheckBoxNoForm,
} from '../../../components/Input';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import Select from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import online from '../../../assets/images/wifi.png';
import ofline from '../../../assets/images/no-wifi.png';
import ContainerCard from '../../../components/ContainerCard';
import { formatBytes } from '../../../utils/functions';

import { ContainerButtons } from './styles';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';
import { updateReleasedRequestCreate } from '../../../store/modules/updatereleased/actions';

const UpdateSchema = Yup.object().shape({
  update_id: Yup.number().required('A Atualização é obrigatória'),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.updateReleased.loading);
  const [fileInfoExe, setFileInfoExe] = useState(null);
  const [fileInfoScript, setFileInfoScript] = useState(null);
  const [fileInfoAditional, setFileInfoAditional] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [dataOriSearch, setDataOriSearch] = useState([]);
  const [business, setBusiness] = useState([]);
  const [servers, setServers] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);
  useEffect(() => {
    const getUpdates = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get(`updates`);
        if (response.data) {
          setUpdates(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getServers = async () => {
      try {
        const response = await api.get(`servers`);
        if (response.data) {
          response.data.forEach((element) => {
            element.hideSub = true;
          });
          setServers(response.data);
          setDataOriSearch(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getBusiness();
    getUpdates();
    getServers();
  }, []);

  function selecionarEstIndex(acao, index) {
    const novoArray = [...servers];

    novoArray[index].sel = acao;
    if (!acao) {
      novoArray[index].scheduling = '';
    }

    setServers(novoArray);
  }
  function selecionarEstSubIndex(acao, index, subindex) {
    const novoArray = [...servers];

    novoArray[index].databases[subindex].sel = acao;

    if (!acao) {
      novoArray[index].databases[subindex].scheduling = '';
    }

    if (!acao) {
      novoArray[index].databases[subindex].execute_immediately = acao;
    }

    if (acao && subindex > 0) {
      novoArray[index].databases.forEach((a) => {
        if (a.scheduling && a.scheduling !== '') {
          novoArray[index].databases[subindex].scheduling = a.scheduling;
        }
      });
    }
    setServers(novoArray);
  }

  function selecionarExecImediatoEstSubIndex(acao, index, subindex) {
    const novoArray = [...servers];

    novoArray[index].databases[subindex].execute_immediately = acao;

    setServers(novoArray);
  }

  function selecionarTipoAtualizacaoSubIndex(value, index, subindex) {
    const novoArray = [...servers];

    novoArray[index].databases[subindex].type_of_execution = value;

    setServers(novoArray);
  }

  function selecionarAgendamentoIndex(scheduling, index) {
    const novoArray = [...servers];

    novoArray[index].scheduling = scheduling;

    setServers(novoArray);
  }
  function selecionarAgendamentoSubIndex(scheduling, index, subindex) {
    const novoArray = [...servers];

    const dataAtual = moment();
    const dataAgendado = moment(scheduling);

    const dataAtualInicioDia = dataAtual.startOf('day');
    const dataAgendadoInicioDia = dataAgendado.startOf('day');

    // Compara apenas as datas (ignorando a parte da hora)
    if (dataAgendadoInicioDia.isBefore(dataAtualInicioDia)) {
      toast.warn('Selecione uma data maior que a data atual.');
      return;
    }
    // const tempoEmMilissegundos = dataAgendado.diff(dataAtual);
    // if (tempoEmMilissegundos / 1000 / 60 < 10) {
    //   toast.warn(
    //     'Selecione uma data e hora ao menos 10 minutos a frente da data e hora atual'
    //   );
    //   return;
    // }

    novoArray[index].databases[subindex].scheduling = scheduling;

    setServers(novoArray);
  }

  function handleHideSub(index) {
    const aux = servers;

    aux[index].hideSub = !aux[index].hideSub;

    setServers([...aux]);
  }
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return addMinutes(currentDate, 10).getTime() < selectedDate.getTime();
  };

  async function handleSubmit({ update_id }) {
    const novoArray = [...servers];
    const data = [];
    let erroNoAgendamento = false;
    let erroNaVersao = false;

    novoArray.forEach((a) => {
      a.databases.forEach((b) => {
        if (b.scheduling < new Date() && b.sel) {
          erroNoAgendamento = true;
        }

        if (b.updatesReleaseds.length > 0 && b.sel) {
          // eslint-disable-next-line eqeqeq
          const up = updates.filter((c) => c.id == update_id);
          const versionSel = parseInt(up[0].version_int, 10);

          const versionAtual = parseInt(
            b.updatesReleaseds[0].update.version_int,
            10
          );

          if (versionAtual > versionSel) erroNaVersao = true;
        }
      });
    });

    if (erroNoAgendamento) {
      toast.warning(
        'Pelo menos um agendamento está com data e hora menor que a atual'
      );
      return;
    }

    if (erroNaVersao) {
      toast.warning(
        'Pelo menos um agendamento já possui Versão de atualização maior que a liberação atual'
      );
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-plusplus
    for (let i = 0; i < novoArray.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < novoArray[i].databases.length; j++) {
        if (novoArray[i].databases[j].sel === true) {
          data.push({
            update_id,
            server_database_id: novoArray[i].databases[j].id,
            date_scheduled: novoArray[i].databases[j].scheduling
              ? novoArray[i].databases[j].scheduling
              : new Date(),
            execute_immediately:
              novoArray[i].databases[j].execute_immediately === true
                ? 'S'
                : 'N',
            type_of_execution: novoArray[i].databases[j].type_of_execution
              ? novoArray[i].databases[j].type_of_execution
              : 'C',
          });
        }
      }
    }

    if (data.length === 0) {
      toast.warn('Nenhuma empresa foi selecionada para ser atualizada');
    } else dispath(updateReleasedRequestCreate(data));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setServers(resultadoFiltrado);
  }
  return (
    <ContainerCard title="Liberação de Atualização">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          update_id: '',
        }}
        validationSchema={UpdateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Selecione uma Atualização" />
            <Field name="update_id" component={Select} type="text">
              <option value="" disabled>
                Selecione uma Atualização
              </option>
              {updates.map((up) => (
                <option key={up.id} value={up.id}>
                  {`Sistema ${up.software.software_name} Versão ${up.version}`}
                </option>
              ))}
            </Field>
            <Table
              title="Servers Cadastradas"
              loading={loadingTable}
              showSearch
              onChangeFilter={filtrarArrayPorCampo}
            >
              <TableHeader>
                <TableCellHeader>#</TableCellHeader>
                <TableCellHeader>Server</TableCellHeader>
                <TableCellHeader>Nome PC.</TableCellHeader>
                <TableCellHeader>On.</TableCellHeader>
                <TableCellHeader>Ação</TableCellHeader>
              </TableHeader>
              <tbody>
                {servers.map((server, index) => {
                  servers[index].business_id = server.id;
                  /*
                  servers[index].due === ''
                    ? (servers[index].dueOld = moment(server.license.due).format(
                        'YYYY-MM-DD'
                      ))
                    : // eslint-disable-next-line no-self-assign
                      (servers[index].due = servers[index].due);
                  */
                  return (
                    <>
                      <TableRow key={server.id}>
                        <TableCell>{server.id}</TableCell>
                        <TableCell>{server.label}</TableCell>
                        <TableCell>{server.name_pc}</TableCell>
                        <TableCell>
                          {server.online ? (
                            <img
                              style={{ maxWidth: '20px' }}
                              src={online}
                              alt="wifi"
                            />
                          ) : (
                            <img
                              style={{ maxWidth: '20px' }}
                              src={ofline}
                              alt="nowifi"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <ButtonIcon
                            type="button"
                            data-tooltip-id={
                              server.hideSub
                                ? 'Mostrar Módulos'
                                : 'Ocultar Módulos'
                            }
                            onClick={() => {
                              handleHideSub(index);
                              // setShowSub(!showSub);
                            }}
                          >
                            {server.hideSub ? (
                              <FaAngleDown size={18} color="#c3c3c3" />
                            ) : (
                              <FaAngleUp size={18} color="#c3c3c3" />
                            )}
                          </ButtonIcon>
                        </TableCell>
                      </TableRow>
                      {server.databases && server.databases.length > 0 ? (
                        <TableRow subtable hide={server.hideSub}>
                          <TableCell />
                          <TableCell colSpan="3">
                            <SubTable title="Database">
                              <TableHeader>
                                <TableCellHeader>#</TableCellHeader>
                                <TableCellHeader>Database</TableCellHeader>
                                <TableCellHeader>Versão</TableCellHeader>
                                <TableCellHeader>Sel.</TableCellHeader>
                                <TableCellHeader>Agendamento</TableCellHeader>
                                <TableCellHeader>
                                  Executar Imediato após Baixar
                                </TableCellHeader>
                                <TableCellHeader>
                                  Tipo de Execução
                                </TableCellHeader>
                              </TableHeader>
                              <tbody>
                                {server.databases.length > 0 &&
                                  server.databases.map((db, subindex) => (
                                    <TableRow>
                                      <TableCell>{db.id}</TableCell>
                                      <TableCell>{db.database}</TableCell>
                                      <TableCell>
                                        {db.updatesReleaseds &&
                                        db.updatesReleaseds.length > 0
                                          ? db.updatesReleaseds[0].update
                                              .version
                                          : '--'}
                                      </TableCell>
                                      <TableCell>
                                        <InputCheckBoxNoForm
                                          noError
                                          placeholder=""
                                          type="checkbox"
                                          name="selected2"
                                          // disabled={!server.online}
                                          checked={db.sel}
                                          onChange={(e) =>
                                            selecionarEstSubIndex(
                                              e.target.checked,
                                              index,
                                              subindex
                                            )
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <InputDateTimePickerNoForm
                                          noError
                                          placeholder=""
                                          name={`a${index}`}
                                          selected={db.scheduling}
                                          autocomplete="off"
                                          // minTime={addMinutes(new Date(), 10)}
                                          // maxTime={setHours(
                                          //   setMinutes(new Date(), 59),
                                          //   23
                                          // )}
                                          minDate={new Date()}
                                          filterTime={filterPassedTime}
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                          onChange={(e) =>
                                            selecionarAgendamentoSubIndex(
                                              e,
                                              index,
                                              subindex
                                            )
                                          }
                                          disabled={!db.sel}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <InputCheckBoxNoForm
                                          noError
                                          placeholder=""
                                          type="checkbox"
                                          name="selected3"
                                          disabled={!db.sel}
                                          checked={db.execute_immediately}
                                          onChange={(e) =>
                                            selecionarExecImediatoEstSubIndex(
                                              e.target.checked,
                                              index,
                                              subindex
                                            )
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <RadioGroupNoForm
                                          name={`selected4${index}${subindex}`}
                                          disabled={!db.sel}
                                          selected={db.type_of_execution || 'C'}
                                          onChange={(e) =>
                                            selecionarTipoAtualizacaoSubIndex(
                                              e.target.value,
                                              index,
                                              subindex
                                            )
                                          }
                                          options={[
                                            {
                                              value: 'S',
                                              label: 'Somente Banco de Dados',
                                            },
                                            { value: 'C', label: 'Completo' },
                                          ]}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </tbody>
                            </SubTable>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            </Table>
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/update-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Create;
