import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cityRequestUpdate } from '../../../store/modules/city/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const UFS = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];
const CitySchema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório')
    .min(4, 'Nome muito curto'),

  uf: Yup.string().required('A Uf é obrigatória'),
});

function Login() {
  const [city, setCity] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.city.loading);

  const { id } = useParams();

  useEffect(() => {
    const getCity = async () => {
      try {
        const response = await api.get(`cities/${id}`);
        if (response.data) {
          setCity(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getCity();
  }, []);

  // alert(id);
  async function handleSubmit({ name, uf }) {
    dispath(cityRequestUpdate(id, name, uf));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Alteração de Cidade">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          name: city.name,
          uf: city.uf,
        }}
        validationSchema={CitySchema}
      >
        <StyledForm>
          <Label text="Nome da Cidade" />
          <Field placeholder="" type="text" name="name" component={Input} />
          <Label text="Selecione a Uf" />
          <Field name="uf" component={Select} type="text">
            <option value="" disabled selected>
              Selecione um estado
            </option>
            {UFS.map((uf) => (
              <option value={uf.value}>{uf.label}</option>
            ))}
          </Field>
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/city-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Login;
