import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { companyFailure, companySuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* companyCreate({ payload }) {
  const displayedMessages = new Set();
  try {
    yield call(api.post, 'business', payload);

    yield put(companySuccess());

    history.push('/company-list');
    toast.success('Nova empresa cadastrada');
  } catch (error) {
    yield put(companyFailure());
    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors && data.errors.length > 0) {
        // Exibir mensagens de erro personalizadas
        data.errors.forEach((err) => {
          // Verificar se a mensagem já foi exibida
          if (!displayedMessages.has(err.message)) {
            toast.error(err.message);

            // Adicionar a mensagem ao conjunto para evitar exibições duplicadas
            displayedMessages.add(err.message);
          }
        });
      } else {
        // Se não houver mensagens personalizadas, exibir uma mensagem padrão
        const defaultMessage =
          'Erro de validação: Empresa não cadastrada corretamente.';

        // Verificar se a mensagem padrão já foi exibida
        if (!displayedMessages.has(defaultMessage)) {
          toast.error(defaultMessage);

          // Adicionar a mensagem ao conjunto
          displayedMessages.add(defaultMessage);
        }
      }
    } else {
      // Se o erro não for 422, exibir uma mensagem genérica
      const genericMessage =
        'Erro ao cadastrar a Empresa. Tente novamente mais tarde.';

      // Verificar se a mensagem genérica já foi exibida
      if (!displayedMessages.has(genericMessage)) {
        toast.error(genericMessage);

        // Adicionar a mensagem ao conjunto
        displayedMessages.add(genericMessage);
      }
    }
  }
}
export function* companyUpdate({ payload }) {
  try {
    const { id, data } = payload;
    console.log(data);
    yield call(api.put, `business/${id}`, data);

    yield put(companySuccess());

    history.push('/company-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(companyFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* companyDelete({ payload }) {
  const displayedMessages = new Set();
  try {
    const { id } = payload;

    yield call(api.delete, `business/${id}`);

    yield put(companySuccess());

    history.push('/company-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(companyFailure());
    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors && data.errors.length > 0) {
        // Exibir mensagens de erro personalizadas
        data.errors.forEach((err) => {
          // Verificar se a mensagem já foi exibida
          if (!displayedMessages.has(err.message)) {
            toast.error(err.message);

            // Adicionar a mensagem ao conjunto para evitar exibições duplicadas
            displayedMessages.add(err.message);
          }
        });
      } else {
        // Se não houver mensagens personalizadas, exibir uma mensagem padrão
        const defaultMessage =
          'Erro de validação: Empresa não cadastrada corretamente.';

        // Verificar se a mensagem padrão já foi exibida
        if (!displayedMessages.has(defaultMessage)) {
          toast.error(defaultMessage);

          // Adicionar a mensagem ao conjunto
          displayedMessages.add(defaultMessage);
        }
      }
    } else {
      // Se o erro não for 422, exibir uma mensagem genérica
      const genericMessage =
        'Erro ao cadastrar a Empresa. Tente novamente mais tarde.';

      // Verificar se a mensagem genérica já foi exibida
      if (!displayedMessages.has(genericMessage)) {
        toast.error(genericMessage);

        // Adicionar a mensagem ao conjunto
        displayedMessages.add(genericMessage);
      }
    }
  }
}

export default all([
  takeLatest('COMPANY_REQUEST_CREATE', companyCreate),
  takeLatest('COMPANY_REQUEST_UPDATE', companyUpdate),
  takeLatest('COMPANY_REQUEST_DELETE', companyDelete),
]);
