import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { showModal } from '../../../store/modules/modal/actions';
import history from '../../../history';
import api from '../../../services/api';
import { ticketRequestDelete } from '../../../store/modules/ticket/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  TableCellHeaderWithOrder,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button, { SearchButton } from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions, Etiqueta } from './styles';
import ContainerCard from '../../../components/ContainerCard';
import Input, { InputFile } from '../../../components/Input';
import Select, { SelectNoForm } from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';

moment.locale('pt-br');

function ManagerTicket() {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [tickets, settickets] = useState([]);
  const [ticketsOri, setticketsOri] = useState([]);
  const [business, setBusiness] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.ticket.loading);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    const gettickets = async () => {
      try {
        const response = await api.get('/searchallticket', {
          params: {
            status: 'O',
          },
        });

        if (response.data) {
          settickets(response.data);
          setticketsOri(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    gettickets();
    getBusiness();
  }, [loading]);

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = ticketsOri.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return settickets(resultadoFiltrado);
  }

  const handleRowClick = (id) => {
    // Navegar para a outra página ou realizar outras ações ao clicar na linha
    history.push(`/ticket-detresponse/${id}`);
  };

  async function handleSubmit({ situation, status, business_id }) {
    try {
      const response = await api.get('/searchallticket', {
        params: {
          status,
          situation,
          business_id,
        },
      });
      if (response.data) {
        settickets(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  const requestSort = (key) => {
    settickets((currentTickets) => {
      // Cria uma nova cópia para ordenar
      const ticketsToSort = [...currentTickets];
      let direction = 'ascending';

      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }

      ticketsToSort.sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      // Atualiza o config de ordenação
      setSortConfig({ key, direction });
      // Retorna o array ordenado para atualizar o estado
      return ticketsToSort;
    });
  };

  const getSubtitle = (situation) => {
    switch (situation) {
      case 'AIA':
        return 'Aguardando Inicio do Atendimento';
      case 'AIC':
        return 'Aguardando Interação do Cliente';
      case 'ARS':
        return 'Aguardando Resposta do Suporte';
      case 'EAT':
        return 'Em Análise Técnica';
      case 'AFC':
        return 'Aguardando Finalizacao do Cliente';
      case 'AAT':
        return 'Aguardando Avaliação Ticket';
      case 'RCS':
        return 'Resolvido com Sucesso';
      case 'FSR':
        return 'Fechado sem Resolução';
      default:
        return situation;
    }
  };

  return (
    <ContainerList>
      <ModalConfirm />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          status: 'O',
          situation: '',
          business_id: '',
        }}
      >
        {(props) => (
          <StyledForm>
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '0 0 20%', marginRight: '10px' }}>
                  <Label text="Status" />
                  <Field name="status" component={Select} type="text">
                    <option key="OPEN" value="O">
                      Aberto
                    </option>
                    <option key="FINISHED" value="F">
                      Finalizado
                    </option>
                  </Field>
                </div>
                <div id="1" style={{ flex: '0 0 20%', marginRight: '10px' }}>
                  <Label text="Situação" />
                  <Field name="situation" component={Select} type="text">
                    <option key="ALL" value="">
                      Todos
                    </option>
                    <option
                      key="AIA"
                      value="AIA"
                      selected={tickets.situation === 'AIA'}
                    >
                      Aguardando Inicio do Atendimento
                    </option>
                    <option
                      key="AIC"
                      value="AIC"
                      selected={tickets.situation === 'AIC'}
                    >
                      Aguardando Interação do Cliente
                    </option>
                    <option
                      key="ARS"
                      value="ARS"
                      selected={tickets.situation === 'ARS'}
                    >
                      Aguardando Resposta do Suporte
                    </option>
                    <option
                      key="EAT"
                      value="EAT"
                      selected={tickets.situation === 'EAT'}
                    >
                      Em Análise Técnica
                    </option>
                    <option
                      key="AFC"
                      value="AFC"
                      selected={tickets.situation === 'AFC'}
                    >
                      Aguardando Finalizacao do Cliente
                    </option>
                    <option
                      key="AAT"
                      value="AAT"
                      selected={tickets.situation === 'AAT'}
                    >
                      Aguardando Avaliação Ticket
                    </option>
                    <option
                      key="RCS"
                      value="RCS"
                      selected={tickets.situation === 'RCS'}
                    >
                      Resolvido com Sucesso
                    </option>
                    <option
                      key="FSR"
                      value="FSR"
                      selected={tickets.situation === 'FSR'}
                    >
                      Fechado sem Resolução
                    </option>
                  </Field>
                </div>
                <div id="2" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Empresa" />
                  <Field name="business_id" component={Select} type="text">
                    <option value="" disabled>
                      Selecione um grupo
                    </option>
                    {business.map((businesses) => (
                      <option key={businesses.id} value={businesses.id}>
                        {businesses.fantasy_name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div style={{ marginTop: '6px' }}>
                  <SearchButton
                    size="contained"
                    type="submit"
                    text={loading ? 'Aguarde...' : 'Salvar'}
                  />
                </div>
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>

      <Table
        title="Tickets do Usuário"
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('id')}
            sortKey="id"
            sortConfig={sortConfig}
          >
            #
          </TableCellHeaderWithOrder>
          <TableCellHeader>Empresa</TableCellHeader>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('subject')}
            sortKey="subject"
            sortConfig={sortConfig}
          >
            Assunto
          </TableCellHeaderWithOrder>
          <TableCellHeader>Criado por</TableCellHeader>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('created_at')}
            sortKey="created_at"
            sortConfig={sortConfig}
          >
            Criado em
          </TableCellHeaderWithOrder>
          <TableCellHeader>Responsável</TableCellHeader>
          <TableCellHeader>Ultima atividade</TableCellHeader>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('situation')}
            sortKey="situation"
            sortConfig={sortConfig}
          >
            Situação
          </TableCellHeaderWithOrder>
        </TableHeader>
        <tbody>
          {tickets.map((ticket) => {
            let latestUpdate = ticket.updated_at;

            if (ticket.discussions && ticket.discussions.length > 0) {
              latestUpdate = ticket.discussions.reduce(
                (latest, discussion) =>
                  moment(discussion.updated_at).isAfter(moment(latest))
                    ? discussion.updated_at
                    : latest,
                ticket.updated_at
              );
            }
            return (
              <TableRow
                key={ticket.id}
                onClick={() => handleRowClick(ticket.id)}
                selected={selectedTicket === ticket.id}
                showPointer // Ou false, dependendo da necessidade
              >
                <TableCell>{ticket.id}</TableCell>
                <TableCell>
                  {ticket.business && ticket.business.fantasy_name}
                </TableCell>
                <TableCell>{ticket.subject}</TableCell>
                <TableCell>{ticket.createdBy.name}</TableCell>
                <TableCell>
                  {moment(ticket.created_at).format('DD/MM/YYYY HH:mm z')}
                </TableCell>
                <TableCell>{ticket.takenBy && ticket.takenBy.name}</TableCell>
                <TableCell>{moment(latestUpdate).fromNow()}</TableCell>
                <TableCell>
                  <Etiqueta situacao={ticket.situation}>
                    {getSubtitle(tickets && ticket.situation)}
                  </Etiqueta>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ManagerTicket;
