import axios from 'axios';
import { store } from '../../store';

import { signOut } from '../../store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, // 'http://192.168.0.113:3333',
  // baseURL: `${URL_SERVER}/`,
});

// add token before all request
// eslint-disable-next-line func-names
api.interceptors.request.use((config) => {
  try {
    const { token } = store.getState().auth.token;

    config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    config.headers.Authorization = `Bearer ${''}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line func-names
  (error) => {
    // eslint-disable-next-line no-unused-vars
    const originalRequest = error.config;

    if (error.response ? error.response.status === 401 : false) {
      // router.push('/login');
      store.dispatch(signOut());
      // console.log(error);
      return;
      // Promise.reject(error);
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  }
);

export default api;
