export function ticketCategoryRequestCreate(description) {
  return {
    type: 'TICKETCATEGORY_REQUEST_CREATE',
    payload: { description },
  };
}

export function ticketCategoryRequestUpdate(id, description) {
  return {
    type: 'TICKETCATEGORY_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function ticketCategoryRequestDelete(id) {
  return {
    type: 'TICKETCATEGORY_REQUEST_DELETE',
    payload: { id },
  };
}

export function ticketCategoryFailure() {
  return {
    type: 'TICKETCATEGORY_FAILURE',
  };
}

export function ticketCategorySuccess() {
  return {
    type: 'TICKETCATEGORY_SUCCESS',
  };
}
