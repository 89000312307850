import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { recurrentExportOnDemandCreate } from '../../../store/modules/recurrentexport/actions';
import history from '../../../history';
import api from '../../../services/api';
import {
  InputDatePickerNoForm,
  InputDatePicker,
} from '../../../components/Input';
import { RadioGroup } from '../../../components/Radio';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import SelectReact from '../../../components/SelectReaact';
import { ContainerButtons } from './styles';

const ValidateSchema = Yup.object().shape({
  business_id: Yup.number().required('O destinatário é obrigatório'),

  typedoc: Yup.number().required('O Tipo do documento fiscal é obrigatório'),
  subtypedoc: Yup.number().required(
    'O Sub-Tipo do documento fiscal é obrigatório'
  ),
  rangedate: Yup.array()
    .of(Yup.date().required('A data é obrigatória'))
    .length(
      2,
      'O período para exportação deve incluir uma data inicial e uma data final'
    )
    .required('O período para exportação é obrigatório'),
});

function OnDemand() {
  const dispath = useDispatch();
  const [companies, setCompany] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const loading = useSelector((state) => state.recurrentexport.loading);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setCompany(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getCompany();
  }, []);

  async function handleSubmit({ business_id, typedoc, subtypedoc, rangedate }) {
    dispath(
      recurrentExportOnDemandCreate({
        business_id,
        typedoc,
        subtypedoc,
        data_ini: rangedate[0],
        data_fin: rangedate[1],
      })
    );
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Exportação sob demanda">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: '',
          typedoc: null,
          subtypedoc: null,
          business_id: null,
          rangedate: [],
        }}
        validationSchema={ValidateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Empresa Emitente/ Destinatário" />
            <Field
              value={props.values.business_id}
              placeholder="Selecione o emitente/ destinatário"
              onChange={(d) => {
                props.setFieldValue('business_id', d.value);
              }}
              options={companies.map((business) => ({
                label: business.company_name,
                value: business.id,
              }))}
              name="business_id"
              component={SelectReact}
            />

            <Label text="Tipo do Documento Fiscal" />
            <Field
              {...props}
              placeholder=""
              type="text"
              name="typedoc"
              component={RadioGroup}
              onChange={(e) => {
                props.setFieldValue('typedoc', e.target.value);
              }}
              options={[
                { value: 55, label: 'NF-e' },
                { value: 65, label: 'NFC-e' },
              ]}
            />

            <Label text="Sub-tipo do Documento Fiscal" />
            <Field
              {...props}
              placeholder=""
              type="text"
              name="subtypedoc"
              component={RadioGroup}
              onChange={(e) => {
                props.setFieldValue('subtypedoc', e.target.value);
              }}
              options={[
                { value: 1, label: 'Emitidas' },
                { value: 2, label: 'Recebidas (Data de Emissão)' },
                { value: 3, label: 'Recebidas (Data de Entrada)' },
              ]}
            />
            <Label text="Período da exportação " />

            <Field
              placeholder=""
              type="date"
              range
              name="rangedate"
              component={InputDatePicker}
              startDate={props.values.rangedate[0]}
              endDate={props.values.rangedate[1]}
              onChange={(e) => {
                // setDateRange(e);
                props.setFieldValue('rangedate', e);
              }}
            />

            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/recurrent-export-list')}
              />

              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Solicitar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default OnDemand;
