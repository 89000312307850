import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { ticketCategoryFailure, ticketCategorySuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* ticketCategoryCreate({ payload }) {
  try {
    const { description } = payload;

    yield call(api.post, 'ticketcategories', {
      description,
    });

    yield put(ticketCategorySuccess());

    history.push('/ticketcategory-list');
    toast.success('Nova categoria de ticket cadastrada');
  } catch (error) {
    yield put(ticketCategoryFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Categoria de ticket não cadastrada');
  }
}

export function* ticketCategoryUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `ticketcategories/${id}`, {
      description,
    });

    yield put(ticketCategorySuccess());

    history.push('/ticketcategory-list');
    toast.success('Cadastro de categoria de ticket alterado');
  } catch (error) {
    yield put(ticketCategoryFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro de categoria de ticket não alterado');
  }
}

export function* ticketCategoryDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `ticketcategories/${id}`);

    yield put(ticketCategorySuccess());

    history.push('/ticketcategory-list');
    toast.success('Registro de categoria de ticket deletado');
  } catch (error) {
    yield put(ticketCategoryFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Registro de categoria de ticket não deletado');
  }
}

export default all([
  takeLatest('TICKETCATEGORY_REQUEST_CREATE', ticketCategoryCreate),
  takeLatest('TICKETCATEGORY_REQUEST_UPDATE', ticketCategoryUpdate),
  takeLatest('TICKETCATEGORY_REQUEST_DELETE', ticketCategoryDelete),
]);
