import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: calc(100%);
  height: 100%;
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px;

  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return `2px 2px 15px ${theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;

  ${(props) => {
    if (props.subtable) {
      return `
        box-shadow: none;
        padding: 5px 0px;
        background-color: ${darken(0.03, props.theme.colors.bright)};
      `;
    }

    if (props.noBox) {
      return `
        box-shadow: none;
        padding: 0px 0px;
      `;
    }
    return '';
  }};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-left: 30px;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '20px')};
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 30px;
  width: 100%;
`;
export const ContainerSearch = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  min-width: 360px;
`;

export const StyledInput = styled.input`
  padding: 9px 15px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  //height: 52px;
  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const Subtitle = styled.div`
  margin-left: 30px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const TableStyled = styled.table`
  //padding-left: 30px;
  //padding-right: 30px;
  width: 100%;
  border-spacing: 0px;

  td,
  th {
    color: ${({ theme }) => theme.colors.secondary};
    text-align: left;

    border-spacing: 0px;
    border-collapse: collapse;
    border-width: 0px;

    border-style: solid;
    border-bottom: 1px solid ${(props) => props.theme.colors.shade};
    line-height: 1.5;
  }

  td:first-child {
    padding-left: 32px;
    padding-right: 0;
  }
  th:first-child {
    padding-left: 32px;
    padding-right: 0;
  }

  td:last-child {
    padding-right: 32px;
  }
  th:last-child {
    padding-right: 32px;
  }
`;

export const TableCellHeaderStyled = styled.th`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer; // Adicione isso para indicar que é clicável
`;

export const TableCellStyled = styled.td``;

export const TableRowStyled = styled.tr`
  &:hover {
    background-color: ${(props) => darken(0.03, props.theme.colors.bright)};
    cursor: ${(props) => (props.showPointer ? 'pointer' : 'default')};
  }
  display: ${({ hide }) => (hide ? 'none' : 'default')};

  //background-color: ${(props) => props.$subtable}
  ${(props) => {
    if (props.$subtable) {
      return `

        background-color: ${darken(0.03, props.theme.colors.bright)};
      `;
    }
    return '';
  }};

  ${(props) => {
    if (props.$height) {
      return `

      height: ${props.$height};
      `;
    }
    return '';
  }};
`;

export const TableHeaderStyled = styled.tr``;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
`;

export const LoadingText = styled.div`
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  margin-left: 30px;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '20px')};
  color: ${({ theme }) => theme.colors.secondary};
`;

export const LoadingAnimation = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-radius: 50%;
  border-top-color: #3498db;
  animation: ${rotate} 1s linear infinite; /* Aplica a animação de rotação */
`;
