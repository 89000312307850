/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { io } from 'socket.io-client';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import DOMPurify from 'dompurify';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { FiEye, FiDownload, FiPlus } from 'react-icons/fi';

import 'react-tooltip/dist/react-tooltip.css';

import { FaUser, FaUserTie } from 'react-icons/fa';
import { ticketDiscussionRequestCreate } from '../../../store/modules/ticketdiscussion/actions';
import history from '../../../history';
import api from '../../../services/api';
import MyEditor from '../../../components/ReactQuill/MyReactQuill';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';

import StarRating from '../../../components/StartRating';
import Input, { InputNoForm, InputFile } from '../../../components/Input';
import Button, { ButtonToLine } from '../../../components/Button';
import Select, { SelectNoForm } from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard, {
  ContainerCardDiscussion,
  ContainerCardArchives,
  ContainerCardArchivesExample,
  ContainerCardNote,
  ContainerCardAction,
  ContainerCardActionAdm,
  ContainerCardReview,
} from '../../../components/ContainerCard';

import {
  ContainerButtons,
  ArchiveContent,
  DivInput,
  StyledButtonCloseTicket,
  LabelArchive,
  TitleDivider,
  Divider,
  CardFile,
  ContainerInputFile,
  Scroll,
  ContainerDetTicketsUp,
} from './styles';
import ticket from '../../../store/modules/ticket/reducer';

moment.locale('pt-br');

const TicketDiscussionSchema = Yup.object().shape({
  message: Yup.string().required('A Mensagem é obrigatória'),
  ticket_id: Yup.number().required('O ID do Ticket é obrigatório'),
  support_response: Yup.string().required(
    'O tipo support_response é obrigatória'
  ),
});

function Create() {
  const dispath = useDispatch();
  const [clearEditor, setClearEditor] = useState(false);

  const loading = useSelector((state) => state.ticketDiscussion.loading);
  const [tickets, setTickets] = useState([]);
  const [ticketReviews, setTicketReviews] = useState([]);
  const [ticketfiles, setTicketFiles] = useState([]);
  const { id } = useParams();

  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [attPage, setAttPage] = useState(false);
  const listRef = useRef();

  const [situationTicket, setSituationTicket] = useState('');
  const fileInputRef = useRef(null);

  function handleDivClick() {
    fileInputRef.current.click();
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fileInputRef.current.click();
    }
  };
  async function handleFileChange(event) {
    const { files } = event.target;
    if (!files.length) return;

    const Arquive = files[0];
    if (Arquive.size > 1073741824) {
      toast.warn('Selecione um arquivo de até 1GB.');
      return;
    }

    const data = new FormData();
    data.append('file', Arquive);

    try {
      const responseFile = await api.post('filedocuments', data);

      await api.post('ticketfiles', {
        ticket_id: id,
        file_id: responseFile.data.id,
      });

      toast.success('Arquivo Adicionado com Sucesso');
      setAttPage((prevState) => !prevState);
    } catch (error) {
      // Handle errors here, such as displaying a notification
    }
  }

  // Função para rolar a lista para baixo
  const scrollToBottomHandler = () => {
    console.log('Chegou no scroll');
    if (listRef.current) {
      console.log('Chegou no scroll 2');
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottomHandler();
  }, [tickets.discussions]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL);

    socket.on('nova-notificacao', (data) => {
      if (
        data &&
        data['Ticket Resposta'] &&
        Number(data['Ticket Resposta'].idTicket) === Number(id)
      ) {
        // Normalizar e atualizar a lista de discussões com a nova mensagem
        const newDiscussion = data['Ticket Resposta'];

        setTickets((prevTickets) =>
          // Atualizando as discussões no estado
          ({
            ...prevTickets,
            discussions: [...prevTickets.discussions, newDiscussion],
          })
        );
        // Acionar rolar para baixo
        // setScrollToBottom(true);
      }
    });

    return () => {
      socket.off('nova-notificacao');
      socket.close();
    };
  }, [id]);

  async function ReturnTicket() {
    modalConfirm({
      title: 'Reabrir Conversa',
      message: 'Deseja realmente voltar ao atendimento?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'ARS',
          status: 'O',
        });
        // history.push('/ticket-manager');
        window.location.reload();
      },
      loading,
    });
  }

  async function UserFinalizeTicket() {
    modalConfirm({
      title: 'Finalização do Ticket',
      message: 'Deseja realmente finalizar este ticket?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'AAT',
          status: 'F',
        });
        // history.push('/ticket-manager');
        toast.success('Ticket Finalizado com Sucesso');
        window.location.reload();
      },
      loading,
    });
  }

  async function CloseTicketResolved() {
    modalConfirm({
      title: 'Solucionar Ticket',
      message: 'Deseja realmente solucionar este ticket?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'RCS',
          status: 'F',
        });
        history.push('/ticket-manager');
        toast.success('Ticket Finalizado com Sucesso');
      },
      loading,
    });
  }

  async function FinalizaAvaliation(serviceRating, resolutionRating, comment) {
    try {
      let userId;
      const persistedData = localStorage.getItem('persist:sisfoco');

      if (persistedData) {
        const parsedData = JSON.parse(persistedData);

        if (parsedData.user) {
          const userData = JSON.parse(parsedData.user);
          userId = userData.user.id;
        }
      }

      if (!userId) {
        throw new Error('User ID não encontrado');
      }

      // Atualiza a situação e o status do ticket
      await api.put(`/tickets/${id}`, {
        situation: 'RCS',
        status: 'F',
      });

      // Salva Avaliações do usuário
      await api.post(`/ticketReviews/`, {
        servicenote: serviceRating,
        resolutionnote: resolutionRating,
        description: comment,
        user_id: userId,
        ticket_id: id, // Certifique-se de ter o ID do ticket disponível
      });

      // Redireciona para a página do gerenciador de tickets
      history.push('/ticket-manager');

      // Exibe uma mensagem de sucesso
      toast.success('Ticket Finalizado com Sucesso');
    } catch (error) {
      // Lida com erros, exibindo uma mensagem de erro
      toast.error('Erro ao finalizar o ticket. Tente novamente.');
    }
  }

  async function FinalizeTicket() {
    modalConfirm({
      title: 'Finalizar Ticket',
      message:
        'Este ticket será marcado para finalizar, e enviara um questionario para o criador do ticket se pode ser finalizado. Deseja prosseguir?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'AFC',
          status: 'O',
        });
        history.push('/ticket-manager');
        toast.success('Ticket Marcado para a Finalização');
      },
      loading,
    });
  }

  async function CloseTicketNotResolved() {
    modalConfirm({
      title: 'Fechar Ticket',
      message: 'Deseja realmente fechar este ticket sem solucionar?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'FSR',
          status: 'F',
        });
        history.push('/ticket-manager');
        toast.success('Ticket Finalizado com Sucesso');
      },
      loading,
    });
  }

  // Recuperando o item do localStorage
  const persistedData1 = localStorage.getItem('persist:sisfoco');

  // Convertendo a string principal em um objeto
  const parsedData1 = JSON.parse(persistedData1);

  // Convertendo a string interna da propriedade "user" em um objeto
  const userData1 = JSON.parse(parsedData1.user);

  // Acessando o ID do usuário
  const userIdLogged = userData1.user.id;

  // Efeito para rolar para baixo sempre que a lista é atualizada
  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottomHandler();
    }
  }, [scrollToBottom]);

  // Efeito para rolar para o topo quando a tela é atualizada
  useEffect(
    () => {
      setScrollToBottom(false);

      setTimeout(() => {
        setScrollToBottom(true);
      }, 0);
    },
    [
      /* dependências para a atualização da tela */
    ]
  );

  useEffect(() => {
    const getTicket = async () => {
      try {
        const response = await api.get(`tickets/${id}`);
        if (response.data) {
          setTickets(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getTicketReviews = async () => {
      try {
        const response = await api.get(`reviewsByTicketId/${id}`);
        if (response.data) {
          setTicketReviews(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getTicketFile = async () => {
      try {
        const response = await api.get(`filesbyticketid/${id}`);
        if (response.data) {
          setTicketFiles(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getTicket();
    getTicketFile();
    getTicketReviews();
  }, [id, loading, attPage]);

  async function AssumeTicket() {
    try {
      await api.put(`/tickettakeson/${id}`);
      setAttPage((prevState) => !prevState);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  async function UpdateTicket() {
    try {
      if (situationTicket !== 'RCS' && situationTicket !== 'FSR') {
        await api.put(`/tickets/${id}`, {
          situation: situationTicket,
          status: 'O',
        });
      } else {
        await api.put(`/tickets/${id}`, {
          situation: situationTicket,
          status: 'F',
        });
      }
      setAttPage((prevState) => !prevState);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  async function handleSendMessage(
    { ticket_id, message, support_response },
    { setFieldValue }
  ) {
    dispath(
      ticketDiscussionRequestCreate(ticket_id, message, support_response)
    );

    setScrollToBottom(false);

    setTimeout(() => {
      setScrollToBottom(true);
    }, 200);

    setClearEditor(true);
    setFieldValue('message', '');
    setTimeout(() => {
      setClearEditor(false);
    }, 400);
  }

  const getSubtitle = (situation) => {
    switch (situation) {
      case 'AIA':
        return 'Aguardando Inicio do Atendimento';
      case 'AIC':
        return 'Aguardando Interação do Cliente';
      case 'ARS':
        return 'Aguardando Resposta do Suporte';
      case 'EAT':
        return 'Em Análise Técnica';
      case 'AFC':
        return 'Aguardando Finalizacao do Cliente';
      case 'AAT':
        return 'Aguardando Avaliação Ticket';
      case 'RCS':
        return 'Resolvido com Sucesso';
      case 'FSR':
        return 'Fechado sem Resolução';
      default:
        return situation;
    }
  };

  function renderTicketDiscussion() {
    if (tickets.discussions && tickets.discussions.length > 0) {
      return (
        <>
          {!tickets.discussions.some(
            (discussion) => discussion.support_response === 'S'
          ) &&
          tickets.situation !== 'RCS' &&
          tickets.situation !== 'FSR' ? (
            <div
              style={{
                flex: 1,
                marginBottom: '5px',
              }}
            >
              <ContainerCardNote
                title="Nota"
                subtitle="Este ticket ainda não recebeu nenhuma resposta do suporte!"
              />
            </div>
          ) : null}

          {tickets.discussions &&
            tickets.discussions.map((discussions) => {
              // Sanitiza a mensagem HTML de cada discussão
              const sanitizedMessage = DOMPurify.sanitize(discussions.message);

              return (
                <div
                  key={discussions.id}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start', // Para alinhar os itens ao topo da div
                    marginBottom: '10px',
                  }}
                >
                  {discussions.support_response === 'N' && (
                    <div style={{ marginRight: '1px', fontSize: '40px' }}>
                      <FaUser color="#696969" />
                    </div>
                  )}
                  <div
                    style={{
                      flex: 1,
                      marginLeft:
                        discussions.support_response === 'S' ? '100px' : '5px',
                      marginRight:
                        discussions.support_response === 'S' ? '5px' : '100px',
                    }}
                  >
                    <ContainerCardDiscussion
                      title={
                        discussions.createdBy && discussions.createdBy.name
                      }
                      subtitle={moment(discussions.created_at).fromNow()}
                      alignRight={discussions.support_response === 'S'}
                    >
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sanitizedMessage,
                        }}
                      />
                    </ContainerCardDiscussion>
                  </div>

                  {discussions.support_response === 'S' && (
                    <div
                      style={{
                        marginLeft: '1px',
                        fontSize: '40px',
                      }}
                    >
                      <FaUserTie color="#000C66" />
                    </div>
                  )}
                </div>
              );
            })}
        </>
      );
    }
    if (tickets.situation !== 'RCS' && tickets.situation !== 'FSR') {
      return (
        <div style={{ flex: 1, marginBottom: '5px' }}>
          <ContainerCardNote
            title="Nota"
            subtitle="Este ticket ainda não recebeu nenhuma resposta do suporte!"
          />
        </div>
      );
    }
    return null;
  }

  const divStyle = {
    flex: 1,
    maxWidth:
      tickets.situation === 'AFC' || tickets.situation === 'AAT'
        ? '600px'
        : '100%', // Ajuste o tamanho máximo conforme necessário
    margin:
      tickets.situation === 'AFC' || tickets.situation === 'AAT'
        ? '0 auto'
        : '0', // Centraliza a div se a situação for 'AFC' ou 'AAT'
    marginTop:
      tickets.situation === 'AFC' || tickets.situation === 'AAT' ? '20px' : '0', // Adiciona um espaçamento acima se a situação for 'AFC' ou 'AAT'
  };

  // Verifica se há dados antes de renderizar
  const hasReviews = ticketReviews.length > 0;
  const review = hasReviews ? ticketReviews[0] : {};

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div id="DivFixo" style={{ width: '29%', paddingRight: 10 }}>
        <div>
          <ContainerCardDiscussion
            title={`Código: #${tickets.id}`}
            subtitle={getSubtitle(tickets.situation)}
          >
            <DivInput>
              <Label text="Cliente - Proprietário do Ticket" />
              <InputNoForm
                placeholder=""
                type="text"
                name="proprietario"
                value={tickets.createdBy && tickets.createdBy.name}
                readOnly
              />
            </DivInput>
            {tickets.computer && (
              <DivInput>
                <Label text="Computador" />
                <InputNoForm
                  placeholder=""
                  type="text"
                  name="proprietario"
                  value={tickets.computer.description}
                  readOnly
                />
              </DivInput>
            )}

            <DivInput>
              <Label text="Ticket Criado" />
              <InputNoForm
                placeholder=""
                type="text"
                name="criado"
                value={moment(tickets.created_at).format('DD/MM/YYYY HH:mm z')}
                readOnly
              />
            </DivInput>
            <DivInput>
              <Label text="Categoria do Ticket" />
              <InputNoForm
                placeholder=""
                type="text"
                name="categoria"
                value={
                  tickets.ticketCategory && tickets.ticketCategory.description
                }
                readOnly
              />
            </DivInput>
            <DivInput>
              <Label text="Responsável" />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputNoForm
                  placeholder=""
                  horizontal
                  type="text"
                  name="responsavel"
                  value={tickets.takenBy && tickets.takenBy.name}
                  readOnly
                />
                {(!tickets.takenBy || tickets.takenBy.id !== userIdLogged) && (
                  <ButtonToLine
                    size="contained"
                    type="button"
                    text="Assumir"
                    onClick={AssumeTicket}
                  />
                )}
              </div>
            </DivInput>
            <DivInput>
              <Label text="Situação" />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SelectNoForm
                  onChange={(a) => setSituationTicket(a.target.value)}
                >
                  <option
                    key="AIA"
                    value="AIA"
                    selected={tickets.situation === 'AIA'}
                  >
                    Aguardando Inicio do Atendimento
                  </option>
                  <option
                    key="AIC"
                    value="AIC"
                    selected={tickets.situation === 'AIC'}
                  >
                    Aguardando Interação do Cliente
                  </option>
                  <option
                    key="ARS"
                    value="ARS"
                    selected={tickets.situation === 'ARS'}
                  >
                    Aguardando Resposta do Suporte
                  </option>
                  <option
                    key="EAT"
                    value="EAT"
                    selected={tickets.situation === 'EAT'}
                  >
                    Em Análise Técnica
                  </option>
                  <option
                    key="AFC"
                    value="AFC"
                    selected={tickets.situation === 'AFC'}
                  >
                    Aguardando Finalizacao do Cliente
                  </option>
                  <option
                    key="AAT"
                    value="AAT"
                    selected={tickets.situation === 'AAT'}
                  >
                    Aguardando Avaliação Ticket
                  </option>
                  <option
                    key="RCS"
                    value="RCS"
                    selected={tickets.situation === 'RCS'}
                  >
                    Resolvido com Sucesso
                  </option>
                  <option
                    key="FSR"
                    value="FSR"
                    selected={tickets.situation === 'FSR'}
                  >
                    Fechado sem Resolução
                  </option>
                </SelectNoForm>
                <ButtonToLine
                  size="contained"
                  type="button"
                  text="Alterar"
                  onClick={UpdateTicket}
                />
              </div>
            </DivInput>
            <DivInput>
              <Label text="Ultima Atualização" />
              <InputNoForm
                placeholder=""
                type="text"
                name="criado"
                value={moment(tickets.updated_at).fromNow()}
                readOnly
              />
            </DivInput>
            {tickets.situation !== 'RCS' && tickets.situation !== 'FSR' ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <StyledButtonCloseTicket
                  style={{ marginRight: '2px', backgroundColor: 'white' }}
                  onClick={CloseTicketNotResolved}
                >
                  <h1 style={{ color: 'red' }}>Fechar sem Resolução</h1>
                </StyledButtonCloseTicket>

                {tickets.situation !== 'AFC' ? (
                  <StyledButtonCloseTicket
                    style={{ marginLeft: '2px', backgroundColor: '#2a3042' }}
                    onClick={FinalizeTicket}
                  >
                    <h1 style={{ color: 'white' }}>Finalizar Conversa</h1>
                  </StyledButtonCloseTicket>
                ) : null}

                <StyledButtonCloseTicket
                  style={{ marginLeft: '2px', backgroundColor: '#2a3042' }}
                  onClick={CloseTicketResolved}
                >
                  <h1 style={{ color: 'white' }}>Marcar como Resolvido</h1>
                </StyledButtonCloseTicket>
              </div>
            ) : null}
          </ContainerCardDiscussion>
        </div>
        <div>
          <ContainerCard title="Arquivos">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {ticketfiles.map((files) => (
                <ContainerCardArchives>
                  <ArchiveContent>
                    <div
                      style={{
                        maxWidth: 'calc(100% - 30px)',
                        overflow: 'hidden',
                      }}
                    >
                      <h1
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {files.file.file_name}
                      </h1>
                    </div>
                    <div style={{ marginLeft: 'Auto' }}>
                      <Link
                        to={files.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiEye
                          size="25px"
                          color="#696969"
                          style={{ marginLeft: '5px' }}
                        />
                      </Link>
                    </div>
                  </ArchiveContent>
                </ContainerCardArchives>
              ))}
              <ContainerCardArchivesExample>
                <ArchiveContent>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    onClick={handleDivClick}
                    onKeyDown={handleKeyPress}
                    aria-label="Remover arquivo"
                    role="button"
                    tabIndex="0"
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <FiPlus
                      size="25px"
                      color="#696969"
                      style={{ alignSelf: 'center' }}
                    />
                  </div>
                </ArchiveContent>
              </ContainerCardArchivesExample>
            </div>
          </ContainerCard>
        </div>
      </div>
      <div style={{ width: '100%', maxHeight: '100%' }}>
        {/* <div style={{ flexDirection: 'column', flex: 1 }}> */}
        <div
          style={{
            flex: 1,
            marginBottom: '15px',
          }}
        >
          <ContainerCard title={`${tickets.subject}`}>
            <div dangerouslySetInnerHTML={{ __html: tickets.message }} />
          </ContainerCard>
        </div>

        <div style={{ maxHeight: '400px', overflowY: 'auto' }} ref={listRef}>
          {renderTicketDiscussion()}
        </div>

        <div style={divStyle}>
          {(() => {
            if (!tickets.createdBy || tickets.createdBy.id === userIdLogged) {
              if (tickets.situation === 'AFC') {
                // Bloco para a situação 'AFC'
                return (
                  <ContainerCardAction
                    title="Ticket em Finalização"
                    message="O Suporte marcou o ticket como resolvido, deseja finalizar o atendimento?"
                    onBack={ReturnTicket}
                    onFinish={UserFinalizeTicket}
                  />
                );
              }
            }

            if (!tickets.createdBy || tickets.createdBy.id !== userIdLogged) {
              if (tickets.situation === 'AFC') {
                // Bloco para a situação 'AFC'
                return (
                  <ContainerCardActionAdm
                    title="Ticket em Finalização"
                    message="Ticket aguardando ação do criador, deseja cancelar pedido de Finalização?"
                    onBack={ReturnTicket}
                  />
                );
              }
            }

            if (tickets.situation === 'AAT') {
              // Bloco CHAMAR MODAL DE AVALIAÇÂO
              return (
                <ContainerCardReview
                  onSubmit={(serviceRating, resolutionRating, comment) => {
                    // Faça algo com as avaliações e o comentário, como enviar para um servidor
                    FinalizaAvaliation(
                      serviceRating,
                      resolutionRating,
                      comment
                    );
                  }}
                />
              );
            }

            if (tickets.situation !== 'RCS' && tickets.situation !== 'FSR') {
              // Bloco para situações diferentes de 'RCS', 'FSR' e 'AFC'
              return (
                <ContainerCard title="Mensagem">
                  <Formik
                    onSubmit={handleSendMessage}
                    enableReinitialize
                    initialValues={{
                      ticket_id: tickets.id,
                      support_response: 'S',
                      message: '',
                    }}
                    validationSchema={TicketDiscussionSchema}
                  >
                    {(props) => (
                      <StyledForm>
                        <MyEditor
                          id="MyEditor"
                          onChange={(content) =>
                            props.setFieldValue('message', content)
                          }
                          clear={clearEditor}
                        />
                        <ContainerButtons>
                          <Button
                            size="contained"
                            type="submit"
                            text={loading ? 'Aguarde...' : 'Enviar'}
                          />
                        </ContainerButtons>
                      </StyledForm>
                    )}
                  </Formik>
                </ContainerCard>
              );
            }

            // Bloco para situação 'RCS', 'FSR' e qualquer outra não especificada acima
            return (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ContainerDetTicketsUp
                      style={{
                        width: '100%', // Define a largura como 50% da tela
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '48%', textAlign: 'center' }}>
                          <p
                            style={{ fontSize: '1.5rem', margin: '0 0 10px 0' }}
                          >
                            Atendimento
                          </p>
                          <p style={{ fontSize: '4rem', margin: 0 }}>
                            {hasReviews
                              ? Number(review.servicenote).toFixed(2)
                              : 'N/A'}
                          </p>
                          <StarRating
                            rating={hasReviews ? Number(review.servicenote) : 0}
                            editable={false}
                            size="large"
                          />
                        </div>
                        <div style={{ width: '48%', textAlign: 'center' }}>
                          <p
                            style={{ fontSize: '1.5rem', margin: '0 0 10px 0' }}
                          >
                            Satisfação
                          </p>
                          <p style={{ fontSize: '4rem', margin: 0 }}>
                            {hasReviews
                              ? Number(review.resolutionnote).toFixed(2)
                              : 'N/A'}
                          </p>
                          <StarRating
                            rating={
                              hasReviews ? Number(review.resolutionnote) : 0
                            }
                            editable={false}
                            size="large"
                          />
                        </div>
                      </div>
                    </ContainerDetTicketsUp>
                  </div>

                  <button
                    key={review.id}
                    type="button"
                    style={{
                      display: 'flex',
                      backgroundColor: '#fff',
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      alignItems: 'flex-start', // Alinhamento vertical dos itens
                      minHeight: '80px', // Altura mínima para os comentários
                      border: 'none',
                      cursor: 'pointer',
                      textAlign: 'left',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      outline: 'none',
                      width: '95%', // Define a largura do botão para combinar com o container acima
                      marginTop: '20px', // Espaço entre o container e o botão
                    }}
                    onClick={() => {}}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)'; // Aumenta o tamanho ao passar o mouse
                      e.currentTarget.style.boxShadow =
                        '0 4px 8px rgba(0, 0, 0, 0.2)'; // Aumenta a sombra
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = 'scale(1)'; // Volta ao tamanho original
                      e.currentTarget.style.boxShadow =
                        '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                    }}
                    onFocus={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)'; // Efeito semelhante ao passar o mouse
                      e.currentTarget.style.boxShadow =
                        '0 4px 8px rgba(0, 0, 0, 0.2)'; // Sombra semelhante ao passar o mouse
                    }}
                    onBlur={(e) => {
                      e.currentTarget.style.transform = 'scale(1)'; // Remove o efeito quando o foco é perdido
                      e.currentTarget.style.boxShadow =
                        '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.currentTarget.click(); // Simula o clique com a tecla Enter ou Espaço
                      }
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        marginRight: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          borderBottom: '1px solid #ddd',
                          marginBottom: '10px',
                        }}
                      />
                      <div
                        style={{
                          fontSize: '1.2rem',
                          color: '#333',
                          fontStyle: 'italic',
                          padding: '0 10px',
                          maxWidth: '100%',
                          wordWrap: 'break-word',
                          textAlign: 'justify', // Justifica o texto
                          whiteSpace: 'pre-wrap', // Mantém as quebras de linha e quebra o texto conforme o tamanho do contêiner
                          lineHeight: '1.2em', // Altura da linha
                          overflow: 'visible', // Garante que o texto não seja ocultado
                        }}
                      >
                        &quot;
                        {review.description || 'Usuário não comentou!'}
                        &quot;
                      </div>
                    </div>
                  </button>
                </div>
                <div style={{ flex: 1, marginTop: '15px' }}>
                  <ContainerCardNote
                    title="Nota"
                    subtitle="Este ticket já foi fechado."
                  />
                </div>
              </div>
            );
          })()}
        </div>

        {/* </div> */}
      </div>
      <ModalConfirm />
    </div>
  );
}

export default Create;
