import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { softwareFailure, softwareSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* softwareCreate({ payload }) {
  try {
    console.log('Chegou aqu11i');
    const { software_name } = payload;
    console.log('Chegou aqui');
    yield call(api.post, 'softwares', {
      software_name,
    });

    yield put(softwareSuccess());

    history.push('/software-list');
    toast.success('Novo software cadastrado');
  } catch (error) {
    yield put(softwareFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Software não cadastrado');
  }
}
export function* softwareUpdate({ payload }) {
  try {
    const { id, software_name } = payload;
    console.log(id);
    console.log(software_name);
    yield call(api.put, `softwares/${id}`, {
      software_name,
    });

    yield put(softwareSuccess());

    history.push('/software-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(softwareFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* softwareDelete({ payload }) {
  try {
    const { id } = payload;
    console.log(id);
    yield call(api.delete, `softwares/${id}`);
    console.log('Passou aqui');
    yield put(softwareSuccess());

    history.push('/software-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(softwareFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('SOFTWARE_REQUEST_CREATE', softwareCreate),
  takeLatest('SOFTWARE_REQUEST_UPDATE', softwareUpdate),
  takeLatest('SOFTWARE_REQUEST_DELETE', softwareDelete),
]);
