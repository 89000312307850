import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: auto;
  display: flex;

  flex-direction: column;
  align-items: center;

  border-radius: 4px;
`;
