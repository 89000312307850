import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  flex-direction: column;
`;

export const StyledInput = styled.select`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';
    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  -webkit-appearance: none;
  -moz-appearance: none;
  svg {
    fill: blue;
  }
  background-image: ${
    ({ theme }) =>
      `url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill='${theme.colors.shadeSVG}' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path></svg>")`
    // return `2px 2px 15px ${theme.colors.shade}`;
  };

  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;

  padding-right: 0px;

  option {
    width: 100%;
    background-color: ${(props) => props.theme.colors.bright};
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border-width: 0px;
    display: flex;
    height: 25px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 400;
    font-size: 14px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const Error = styled.div`
  color: ${(props) => props.theme.colors.error};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '20px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 15px;
  padding-left: 0px;
  font-weight: 400;
  font-size: 12px;
`;
