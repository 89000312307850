import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { userRequestUpdate } from '../../../store/modules/user/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons, TitleDivider, Divider } from './styles';

const userSchema = Yup.object().shape({
  // ...outras validações...
  confpassword: Yup.string().oneOf(
    [Yup.ref('pass'), null],
    'As senhas devem corresponder uma com a outra'
  ),
});

function Update() {
  const [user, setUser] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.user.loading);

  const { id } = useParams();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await api.get(`users/${id}`);
        if (response.data) {
          setUser(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getUser();
  }, []);

  // alert(id);
  async function handleSubmit({ name, username, email, access, pass }) {
    dispath(userRequestUpdate(id, name, username, email, access, pass));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Informações do Usuário">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          name: user.name,
          username: user.user_name,
          email: user.email,
          access: user.userRoles?.[0]?.description,
        }}
        validationSchema={userSchema}
      >
        <StyledForm>
          <Label text="Nome" />
          <Field placeholder="" type="text" name="name" component={Input} />
          <Label text="Nome de Usuário" />
          <Field placeholder="" type="text" name="username" component={Input} />
          <Label text="E-mail" />
          <Field placeholder="" type="text" name="email" component={Input} />
          <Divider>
            <TitleDivider>Senha do Usuário</TitleDivider>
          </Divider>
          <div style={{ display: 'flex', width: '100%' }}>
            <div id="0" style={{ flex: '1', marginRight: '10px' }}>
              <Label text="Nova Senha" />
              <Field
                placeholder=""
                type="password"
                name="pass"
                component={Input}
              />
            </div>
            <div id="1" style={{ flex: '1' }}>
              <Label text="Confirmar Nova Senha" />
              <Field
                placeholder=""
                type="password"
                name="confpassword"
                component={Input}
              />
            </div>
          </div>
          <Divider>
            <TitleDivider>Nível de Acesso</TitleDivider>
          </Divider>
          <Label text="Acesso" />
          <Field name="access" component={Select} type="text">
            <option key="Basic" value="Basic">
              Básico
            </option>
            <option key="Administrator" value="Administrator">
              Administrador
            </option>
          </Field>

          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/user-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Update;
