import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledButton = styled.button`
  border: 1px solid transparent;
  height: 36px;
  width: 36px;

  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &:active {
    background-color: ${({ theme }) => theme.colors.bright};
  }
  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.bright)};
  }
`;
