import styled from 'styled-components';
import { lighten } from 'polished';

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  height: 45px;
  width: calc(100%);
  margin-left: 8px;
  a {
    width: 100%;
    border-radius: 4px;
  }
`;

export const StyledNavCategory = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  //min-height: 45px;

  font-weight: 500;
  color: ${(props) => props.theme.colors.muted};
  transition: all 0.3s;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.bright)};

    cursor: pointer;
  }

  color: ${({ theme, open }) => {
    if (open) return lighten(0.1, theme.colors.bright);
    return theme.colors.bright;
  }};

  max-height: ${(props) => (props.open ? 'auto' : '45px')};
  transition: all 0.3s;
`;

export const Title = styled.div`
  padding: 8px;
  font-weight: 500;
  font-size: 13px;
`;
export const Category = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  span {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    svg {
      transition: all 0.2s;
      margin-right: 8px;
      ${({ open }) => {
        if (open)
          return `
      transform: rotate(180deg);
      `;
        return null;
      }};
    }
  }
`;

export const IconAndTatle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const ContainerItens = styled.ul`
  padding-top: 5px;
  padding-left: 18px;

  transform: translateY(-500px);
  transform-origin: top;
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.open ? 'translateY(0px)' : 'translateY(-500px)'};
`;
