import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { softModuleFailure, softModuleSuccess } from './actions';
import { hideModal } from '../modal/actions';
import history from '../../../history';

import api from '../../../services/api';

export function* softModuleCreate({ payload }) {
  try {
    const { description, details, software_id } = payload;

    yield call(api.post, 'softmodules', {
      description,
      details,
      software_id,
    });

    yield put(softModuleSuccess());
    yield put(hideModal());

    history.push('/software-list');
    toast.success('Novo módel cadastrado');
  } catch (error) {
    yield put(softModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Módel não cadastrado');
  }
}
export function* softModuleUpdate({ payload }) {
  try {
    const { id, description, details, software_id } = payload;
    console.log('Passando o id para atualizar');
    // const software_id = id;
    yield call(api.put, `softmodules/${id}`, {
      description,
      details,
      software_id,
    });

    yield put(softModuleSuccess());
    yield put(hideModal());

    history.push('/software-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(softModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* softModuleDelete({ payload }) {
  try {
    console.log('Aqui vai chegar para enviar para o backend');
    const { id } = payload;

    yield call(api.delete, `softmodules/${id}`);

    yield put(softModuleSuccess());

    history.push('/software-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(softModuleFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('SOFTMODULE_REQUEST_CREATE', softModuleCreate),
  takeLatest('SOFTMODULE_REQUEST_UPDATE', softModuleUpdate),
  takeLatest('SOFTMODULE_REQUEST_DELETE', softModuleDelete),
]);
