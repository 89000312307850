import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { MdFileDownload, MdFileDownloadOff } from 'react-icons/md';
import { serverDBRequestUpdate } from '../../../store/modules/serverdatabase/actions';
import api from '../../../services/api';
import { InputNoForm, TextAreaNoForm } from '../../../components/Input';
import { DivInput, ContainerButtons } from './styles';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import { hideModal } from '../../../store/modules/modal/actions';
import ContainerCard from '../../../components/ContainerCard';

const validateSchema = Yup.object().shape({
  database: Yup.string()
    .required('A descrição é obrigatória')
    .min(3, 'Descrição muito curta'),
});

function Datails({ id }) {
  const [update, setUpdate] = useState({});
  const dispath = useDispatch();
  const loading = useSelector((state) => state.serverdatabase.loading);

  useEffect(() => {
    const getUpdate = async () => {
      try {
        const response = await api.get(`updates/${id}`);
        if (response.data) {
          setUpdate(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getUpdate();
  }, []);

  // alert(id);

  return (
    <ContainerCard title="Detalhes da Atualização" removeShadow>
      <DivInput>
        <Label text="Software" />
        <InputNoForm
          placeholder=""
          type="text"
          name="scriado"
          value={update && update.software && update.software.software_name}
          readOnly
        />
      </DivInput>
      <DivInput>
        <Label text="Versão" />
        <InputNoForm
          placeholder=""
          type="text"
          name="vcriado"
          value={update && update.version}
          readOnly
        />
      </DivInput>
      <DivInput>
        <Label text="Data de Cadastro" />
        <InputNoForm
          placeholder=""
          type="text"
          name="ffcriado"
          value={moment(update && update.created_at).format(
            'DD/MM/YYYY HH:mm z'
          )}
          readOnly
        />
      </DivInput>
      <DivInput>
        <Label text="Detalhes" />
        <TextAreaNoForm
          resize="none"
          placeholder=""
          name="ddcriado"
          style={{ resize: 'none' }}
          value={update && update.details}
          readOnly
        />
      </DivInput>
      <Label text="Arquivos" />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Label text="Arquivo Exe." />
          {update && update.exe ? (
            <Link to={update.exe.url} target="_blank" rel="noopener noreferrer">
              <MdFileDownload size="25px" color="#696969" />
            </Link>
          ) : (
            <MdFileDownloadOff
              size="25px"
              color="#696969"
              style={{ marginLeft: '5px' }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Label text="Arquivo de Scripts" />
          {update && update.script ? (
            <Link
              to={update.script.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdFileDownload size="25px" color="#696969" />
            </Link>
          ) : (
            <MdFileDownloadOff
              size="25px"
              color="#696969"
              style={{ marginLeft: '5px' }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Label text="Arquivo Adicional" />

          {update && update.aditional ? (
            <Link
              to={update.aditional.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdFileDownload size="25px" color="#696969" />
            </Link>
          ) : (
            <MdFileDownloadOff
              size="25px"
              color="#696969"
              style={{ marginLeft: '5px' }}
            />
          )}
        </div>
      </div>
    </ContainerCard>
  );
}

export default Datails;
