import React from 'react';
import { connect, useDispatch } from 'react-redux';

import CreateSubChart from '../ChartAccount/SubChart/Create';
import UpdateSubChart from '../ChartAccount/SubChart/Update';

import CreateSoftModule from '../Software/SoftModule/Create';
import UpdateSoftModule from '../Software/SoftModule/Update';

import CreateServerDb from '../Server/ServerDatabase/Create';
import UpdateServerDb from '../Server/ServerDatabase/Update';

import ListLogReleaseds from '../UpdateReleased/Details/List';
import DetailsUpdate from '../Update/Details';

import Modal from '../../components/Modal';
import { hideModal } from '../../store/modules/modal/actions';

const MODAL_COMPONENTS = {
  CREATE_SUBCHART: CreateSubChart,
  UPDATE_SUBCHART: UpdateSubChart,
  CREATE_SOFTMODULE: CreateSoftModule,
  UPDATE_SOFTMODULE: UpdateSoftModule,
  CREATE_SERVERDB: CreateServerDb,
  UPDATE_SERVERDB: UpdateServerDb,
  LIST_LOGRELEASEDS: ListLogReleaseds,
  DETAILS_UPDATE: DetailsUpdate,
  /* other modals */
};

function ModalRoot({ modalType, modalProps }) {
  const dispath = useDispatch();
  if (!modalType) {
    return <Modal $opened={false} />;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return (
    <Modal
      opened
      close={() => {
        dispath(hideModal());
      }}
      dynamicWrapper
    >
      <SpecificModal {...modalProps} />
    </Modal>
  );
}

export default connect((state) => state.modal)(ModalRoot);
