import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaRegFileArchive } from 'react-icons/fa';
import { companyRequestCreate } from '../../../store/modules/company/actions';
import history from '../../../history';
import api from '../../../services/api';
import { formatBytes, removeNoNumber } from '../../../utils/functions';

import Input, {
  InputMask,
  InputFile,
  InputNoForm,
} from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import ContainerCard from '../../../components/ContainerCard';
import {
  UFS,
  CELL_PHONEREG,
  CNPJ_CPFREG,
  ENDNR_REG,
} from '../../../utils/constants';

import {
  ContainerButtons,
  TitleDivider,
  Divider,
  CardFile,
  CardModal,
  ButtonNoMargin,
  ContainerInputFile,
} from './styles';

const CompanySchema = Yup.object().shape({
  fantasy_name: Yup.string()
    .required('O nome fantasia é obrigatório')
    .min(2, 'Nome muito curto'),

  company_name: Yup.string().required('A razão social é obrigatória'),
  code: Yup.string().required('O código é obrigatório'),
  cnpj: Yup.string()
    .required('o CNPJ é obrigatório')
    .matches(CNPJ_CPFREG, 'Número de CPNJ inválido'),
  ie: Yup.string().required('A inscrição estadual é obrigatória'),
  // start_date: Yup.date(),
  // site: Yup.string().url('Site inválido'),
  email: Yup.string().email('Email inválido'),
  cep: Yup.string().length(8, 'CEP inválido').required('O CEP é obrigatório'),

  address: Yup.string().required('O endereço é obrigatório'),
  number: Yup.string().matches(ENDNR_REG, 'Número de endereço inválido'),
  district: Yup.string().required('O bairro é obrigatório'),
  city_id: Yup.number().required('A cidade é obrigatória'),
  state: Yup.string().required('A UF é obrigatória'),
  nfestorage: Yup.string().required('Esse campo é obrigatório'),
  ultNSUsystem: Yup.string(),
  cell_phone: Yup.string().matches(CELL_PHONEREG, 'Número de celular inválido'),
  landline: Yup.string().matches(CELL_PHONEREG, 'Número de telefone inválido'),
  group_id: Yup.number().required('O Grupo é obrigatório'),
  fileDirectoryPfx: Yup.mixed(),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.company.loading);
  const [cities, setCities] = useState([]);
  const [groups, setGroups] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [filePfx, setFilePfx] = useState();
  const [openPass, setOpenPass] = useState(false);
  const [password, setPassword] = useState('');
  const [fileInfoPfx, setFileInfoPfx] = useState();
  const [confirmPass, setConfirmPass] = useState(false);

  const [loadingFilePfx, setLoadingFilePfx] = useState(false);
  // const [softwareQuery, setSoftwareQuery] = useState([]);

  const inputPassRef = useRef(null);
  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getGroup();
    getSoftware();
  }, []);

  useEffect(() => {
    if (inputPassRef.current && openPass) inputPassRef.current.focus();
  }, [inputPassRef.current]);

  useEffect(() => {
    setConfirmPass(false);
    setPassword('');
    if (!filePfx) setOpenPass(false);
    else {
      setOpenPass(true);
    }
  }, [filePfx]);

  useEffect(() => {
    const handleSubmitFile = async () => {
      const data = new FormData();
      data.append('file', filePfx);
      data.append('password', password);

      if (!filePfx) return;
      if (!confirmPass) return;

      if (filePfx.size > 1073741824) {
        toast.warn('Selecione um arquivo de até 124BM.');
        return;
      }
      try {
        setLoadingFilePfx(true);
        const responseFile = await api.post('filedocuments', data);

        if (responseFile.data) {
          setFileInfoPfx(responseFile.data);
        } else setFileInfoPfx(null);

        setLoadingFilePfx(false);
      } catch (error) {
        setLoadingFilePfx(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.info(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    handleSubmitFile();
  }, [confirmPass]);

  async function handleSubmit(values) {
    dispath(
      companyRequestCreate({
        pfx_id: fileInfoPfx ? fileInfoPfx.id : null,
        ...values,
      })
    );
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  function handleConfirmPass() {
    setOpenPass(false);
    setConfirmPass(true);
  }

  async function handleChangeUf(e) {
    // citiesUf

    try {
      const response = await api.get(`citiesUf/${e.target.value}`);
      if (response.data) {
        setCities(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  }
  return (
    <>
      <CardModal opened={openPass}>
        <span>
          <ContainerCard
            title="O arquivo possue senha?"
            removeShadow
            size={350}
          >
            <Label text="Informe aqui a senha do arquivo para que seja possível ler os dados." />
            <Label text="Se não tiver senha, apenas clique em Continuar." />
            <br />
            <InputNoForm
              placeholder=""
              type="password"
              name="password"
              value={password}
              ref={inputPassRef}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonNoMargin
              size="contained"
              text="Continuar"
              type="button"
              onClick={handleConfirmPass}
            />
          </ContainerCard>
        </span>
      </CardModal>

      <ContainerCard title="Produto">
        <Formik
          onSubmit={handleSubmit}
          onReset={handleReset}
          initialValues={{
            fantasy_name: '',
            company_name: '',
            code: '',
            cnpj: '',
            ie: '',
            // start_date: 0,
            // site: '',
            email: '',

            cep: '',
            address: '',
            number: '',
            district: '',
            state: '',
            city_id: '',
            cell_phone: '',
            landline: '',
            group_id: '',
            software_id: '',
            nfestorage: '',
            ultNSUsystem: '',
          }}
          validationSchema={CompanySchema}
        >
          {(props) => (
            <StyledForm>
              <Label text="Selecione um Produto" />
              <Field name="software_id" component={Select} type="text">
                <option value="" disabled>
                  Selecione um produto
                </option>
                {softwares.map((software) => (
                  <option key={software.id} value={software.id}>
                    {software.software_name}
                  </option>
                ))}
              </Field>
              {/* <Label text = "Software" />
              <SelectReactNoForm
              value        = {softwareQuery,  softwares.software_name}
              placeholder  ="Selecione um Produto"
              onChange     ={(e) => {
                if (e){
                  setSoftwareQuery(e.value);
                }
                else{
                  setSoftwareQuery(null);
                }
              }}

              options={softwares.map((software) => ({

                label: software.software_name,
                value: software.id,
              }))}
              name="idSoftware"
            /> */}

              <Divider>
                <TitleDivider>Cadastro de Empresa</TitleDivider>
              </Divider>
              <Label text="Nome fantasia" />
              <Field
                placeholder=""
                type="text"
                name="fantasy_name"
                component={Input}
              />
              <Label text="Razão social" />
              <Field
                placeholder=""
                type="text"
                name="company_name"
                component={Input}
              />
              <Label text="Código da Filial no Sistema" />
              <Field placeholder="" type="text" name="code" component={Input} />
              <Label text="CNPJ" />
              <Field
                mask="99.999.999/9999-99"
                type="text"
                name="cnpj"
                component={InputMask}
                onChange={(e) => {
                  props.setFieldValue('cnpj', removeNoNumber(e.target.value));
                }}
              />

              <Label text="Inscrição estadual" />
              <Field placeholder="" type="text" name="ie" component={Input} />

              <Label text="Email" />
              <Field
                placeholder=""
                type="text"
                name="email"
                component={Input}
              />

              <Divider>
                <TitleDivider>Dados de endereço</TitleDivider>
              </Divider>
              <Label text="CEP" />
              <Field
                mask="99999-999"
                placeholder=""
                type="text"
                name="cep"
                onChange={(e) => {
                  props.setFieldValue('cep', removeNoNumber(e.target.value));
                }}
                component={InputMask}
              />

              <Label text="Endereço" />
              <Field
                placeholder=""
                type="text"
                name="address"
                component={Input}
              />
              <Label text="Número" />
              <Field
                placeholder=""
                type="text"
                name="number"
                component={Input}
              />

              <Label text="Bairro" />
              <Field
                placeholder=""
                type="text"
                name="district"
                component={Input}
              />

              <Label text="Selecione a Uf" />
              <Field
                name="state"
                component={Select}
                type="text"
                // setFieldValue={(value) => handleChangeUf(value)}
                onChange={(e) => {
                  handleChangeUf(e);
                  props.setFieldValue('state', e.target.value);
                }}
              >
                <option value="" disabled>
                  Selecione um estado
                </option>
                {UFS.map((uf) => (
                  <option key={uf.value} value={uf.value}>
                    {uf.label}
                  </option>
                ))}
              </Field>

              <Label text="Selecione a Cidade" />
              <Field name="city_id" component={Select} type="text">
                <option value="" disabled>
                  Selecione uma cidade
                </option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Field>
              <Label text="Celular" />
              <Field
                placeholder=""
                mask="(99)99999-9999"
                type="text"
                name="cell_phone"
                onChange={(e) => {
                  props.setFieldValue(
                    'cell_phone',
                    removeNoNumber(e.target.value)
                  );
                }}
                component={InputMask}
              />
              <Label text="Fone fixo" />
              <Field
                mask="(99)9999-9999"
                type="text"
                name="landline"
                onChange={(e) => {
                  props.setFieldValue(
                    'landline',
                    removeNoNumber(e.target.value)
                  );
                }}
                component={InputMask}
              />
              <Divider>
                <TitleDivider>Grupo</TitleDivider>
              </Divider>
              <Label text="Selecione um Grupo" />
              <Field name="group_id" component={Select} type="text">
                <option value="" disabled>
                  Selecione um grupo
                </option>
                {groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.description}
                  </option>
                ))}
              </Field>
              <Divider>
                <TitleDivider>NF-e Storage</TitleDivider>
              </Divider>
              <Label text="Utiliza NF-e Storage" />
              <Field name="nfestorage" component={Select} type="text">
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option key="S" value="S">
                  Sim
                </option>
                <option key="N" value="N">
                  Não
                </option>
              </Field>
              <Label text="Último NSU baixado pelo ERP" />
              <Field
                placeholder=""
                type="text"
                name="ultNSUsystem"
                component={Input}
              />
              <Divider>
                <TitleDivider>Certificado digital</TitleDivider>
              </Divider>

              <div style={{ width: '100%' }}>
                <Label text="Certificado (.pfx)" />
                {filePfx ? (
                  <CardFile>
                    <FaRegFileArchive size={25} />
                    <div>
                      <h2>{filePfx && filePfx.name}</h2>
                      <h3>{formatBytes(filePfx && filePfx.size)}</h3>
                    </div>
                  </CardFile>
                ) : (
                  <ContainerInputFile>
                    <InputFile
                      name="fileDirectoryPfx"
                      accept=".pfx"
                      acceptDrop={{ 'appliction/pfx': ['.pfx'] }}
                      onDrop={(f) => setFilePfx(f[0])}
                      onChange={(e) => {
                        setFilePfx(e.target.files[0]);
                        props.setFieldValue('fileDirectoryPfx', e.target.value);
                      }}
                    />
                  </ContainerInputFile>
                )}
              </div>
              <ContainerButtons>
                <Button
                  size="contained"
                  variant="outline"
                  text="Cancelar"
                  type="button"
                  onClick={() => history.push('/company-list')}
                />
                <Button
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Salvar'}
                />
              </ContainerButtons>
            </StyledForm>
          )}
        </Formik>
      </ContainerCard>
    </>
  );
}

export default Create;
