export function userRequestCreate(data) {
  return {
    type: 'USER_REQUEST_CREATE',
    payload: data,
  };
}

export function userRequestUpdate(id, name, username, email, access, pass) {
  return {
    type: 'USER_REQUEST_UPDATE',
    payload: { id, name, username, email, access, pass },
  };
}

export function userRequestDelete(id) {
  return {
    type: 'USER_REQUEST_DELETE',
    payload: { id },
  };
}

export function userFailure() {
  return {
    type: 'USER_FAILURE',
  };
}

export function userSuccess() {
  return {
    type: 'USER_SUCCESS',
  };
}
