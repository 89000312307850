import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
// import 'react-tooltip/dist/react-tooltip.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

  * {
    margin: 0;
    padding : 0;
    outline: 0;
    box-sizing: border-box;

  }

  *:focus {
    outline: 0;
    //line-height: 1.5;

  }

  html, body, #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, h1,h2, h3, textarea {
    font: 14px 'Poppins', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }


`;
