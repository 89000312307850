import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { ticketCategoryRequestCreate } from '../../../store/modules/ticketcategory/actions';
import history from '../../../history';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const TicketCategorySchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obrigatória')
    .min(3, 'Descrição muito curta'),
});

function Create() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ticketCategory.loading);

  async function handleSubmit({ description }) {
    dispatch(ticketCategoryRequestCreate(description));
  }

  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Categoria de Ticket">
      {' '}
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: '',
        }}
        validationSchema={TicketCategorySchema}
      >
        <StyledForm>
          <Label text="Descrição da Categoria de Ticket" />
          <Field
            placeholder=""
            type="text"
            name="description"
            component={Input}
          />
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/ticket-category-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
