import { produce } from 'immer';

const INITIAL_STATE = {
  modalType: null,
  modalProps: {},
  show: false,
};

export default function modal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return produce(state, (draft) => {
        draft.modalType = action.payload.modalType;
        draft.modalProps = action.payload.modalProps;
        draft.show = false;
      });
    case 'HIDE_MODAL':
      return INITIAL_STATE;
    default:
      return state;
  }
}
