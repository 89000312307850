// MyEditor.js
import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function MyEditor({ onChange, clear, ...rest }) {
  const quillRef = useRef();

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule('toolbar');

      if (clear) {
        editor.setText('');
      }

      if (toolbar) {
        toolbar.addHandler('font', (value) => {
          editor.format('font', value);
        });

        toolbar.addHandler('size', (value) => {
          editor.format('size', value);
        });
      }
    }
  }, [clear]);

  return (
    <ReactQuill
      {...rest} // Use spread operator para passar as outras props
      onChange={(content, delta, source, editor) => {
        onChange && onChange(content);
      }}
      ref={quillRef}
      theme="snow"
      modules={{
        toolbar: {
          container: [
            [{ font: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ direction: 'rtl' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
          ],
          handlers: {},
        },
      }}
      style={{
        width: '100%',
        marginBottom: '40px',
        height: '120px',
        minHeight: '120px',
        maxHeight: '700px',
      }}
    />
  );
}

export default MyEditor;
