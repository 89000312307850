import React from 'react';
import {
  FaBuilding,
  FaProjectDiagram,
  FaServer,
  FaMap,
  FaModx,
  FaLockOpen,
  FaUpload,
  FaAtom,
  FaFileExport,
  FaTicketAlt,
  FaUser,
  FaTools,
  FaDesktop,
} from 'react-icons/fa';
import { Nav, Ul } from './styles';
import NavItem from '../NavItem';
import { store } from '../../store';

import NavCategory from '../NavCategory';

function NavItems({ clicked }) {
  if (!store.getState().user) return <> </>;

  const { roles } = store.getState().user.user;

  if (!roles) return <> </>;
  let menus = null;
  if (roles.indexOf('Basic') >= 0) {
    menus = (
      <Nav>
        <Ul>
          <NavItem clicked={clicked} link="/dashboard">
            Dashboard
          </NavItem>
          <NavCategory Icon={FaTicketAlt} linkGroup="ticket" title="Tickets">
            <NavItem clicked={clicked} link="/ticket-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/ticket-create">
              Cadastrar
            </NavItem>
          </NavCategory>
        </Ul>
      </Nav>
    );
  }
  if (roles.indexOf('Administrator') >= 0) {
    menus = (
      <Nav>
        <Ul>
          <NavItem clicked={clicked} link="/dashboard">
            Dashboard
          </NavItem>
          <NavCategory Icon={FaUser} linkGroup="user" title="Usuários">
            <NavItem clicked={clicked} link="/user-list">
              Listar
            </NavItem>
          </NavCategory>
          <NavCategory Icon={FaTicketAlt} linkGroup="ticket" title="Tickets">
            <NavItem clicked={clicked} link="/ticket-manager">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/ticket-create">
              Cadastrar
            </NavItem>
            <NavItem clicked={clicked} link="/ticket-monitoring">
              Monitoramento
            </NavItem>
          </NavCategory>
          <NavCategory Icon={FaBuilding} linkGroup="company" title="Empresa">
            <NavItem clicked={clicked} link="/company-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/company-create">
              Cadastrar
            </NavItem>
          </NavCategory>
          <NavCategory
            Icon={FaDesktop}
            linkGroup="computer"
            title="Computadores"
          >
            <NavItem clicked={clicked} link="/computer-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/computer-create">
              Cadastrar
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaMap} linkGroup="city" title="Cidade">
            <NavItem clicked={clicked} link="/city-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/city-create">
              Cadastrar
            </NavItem>
          </NavCategory>
          <NavCategory
            Icon={FaProjectDiagram}
            linkGroup="group"
            title="Grupo de Empresa"
          >
            <NavItem clicked={clicked} link="/group-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/group-create">
              Cadastrar
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaServer} linkGroup="server" title="Servers">
            <NavItem clicked={clicked} link="/server-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/server-create">
              Cadastrar
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaLockOpen} linkGroup="license" title="Licença">
            <NavItem clicked={clicked} link="/license-list">
              Listar
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaUpload} linkGroup="update" title="Atualização">
            <NavItem clicked={clicked} link="/update-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/update-create">
              Cadastrar
            </NavItem>
            <NavItem clicked={clicked} link="/update-released-create">
              Liberar Atualização
            </NavItem>
            <NavItem clicked={clicked} link="/update-released-list">
              Atualizações Liberadas
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaAtom} linkGroup="software" title="Software">
            <NavItem clicked={clicked} link="/software-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/software-create">
              Cadastrar
            </NavItem>
          </NavCategory>

          <NavCategory
            Icon={FaModx}
            linkGroup="softwaremodule"
            title="Gerenciamento de Módulos"
          >
            <NavItem clicked={clicked} link="/softwaremodule-create">
              Gerenciar Módulos
            </NavItem>
          </NavCategory>

          <NavCategory
            Icon={FaFileExport}
            linkGroup="recurrent-export"
            title="Exportação Recorrente"
          >
            <NavItem clicked={clicked} link="/recurrent-export-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/recurrent-export-create">
              Cadastrar
            </NavItem>
            <NavItem clicked={clicked} link="export-historic-list">
              Histórico de Exportação
            </NavItem>
            <NavItem clicked={clicked} link="search-dfe">
              Pesquisar NFe
            </NavItem>
            <NavItem clicked={clicked} link="export-ondemand">
              Sob demanda
            </NavItem>
          </NavCategory>

          <NavCategory Icon={FaTools} linkGroup="monitor" title="Monitoramento">
            <NavItem clicked={clicked} link="/backup-list">
              Backups
            </NavItem>
            <NavItem clicked={clicked} link="/markdown-create">
              MarkDown
            </NavItem>
          </NavCategory>

          <NavCategory
            Icon={FaTicketAlt}
            linkGroup="ticketcategory"
            title="Categoria de Ticket"
          >
            <NavItem clicked={clicked} link="/ticketcategory-list">
              Listar
            </NavItem>
            <NavItem clicked={clicked} link="/ticketcategory-create">
              Cadastrar
            </NavItem>
          </NavCategory>

          {/* <NavCategory
          Icon={FaFileInvoice}
          linkGroup="chartaccount"
          title="Plano de Contas"
        >
          <NavItem clicked={clicked} link="/chartaccount-list">
            Listar
          </NavItem>
          <NavItem clicked={clicked} link="/chartaccount-create">
            Cadastrar
          </NavItem>
        </NavCategory> */}
          {/* <NavCategory
          Icon={FaFileInvoice}
          linkGroup="document|issuer"
          title="Documentos"
        >
          <NavItem clicked={clicked} link="/issuer-list">
            Listar Emitentes
          </NavItem>
          <NavItem clicked={clicked} link="/issuer-create">
            Cadastrar Emitente
          </NavItem>

          <NavItem clicked={clicked} link="/document-create">
            Cadastrar Documento
          </NavItem>
        </NavCategory> */}
        </Ul>
      </Nav>
    );
  }
  return menus;
}

export default NavItems;
