import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaFileArchive, FaArchive, FaRegFileArchive } from 'react-icons/fa';
import api from '../../../services/api';
import { updateRequestCreate } from '../../../store/modules/update/actions';
import history from '../../../history';

import Input, { InputFile } from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import { formatBytes } from '../../../utils/functions';

import {
  ContainerButtons,
  GridLayout,
  CardFile,
  ContainerInputFile,
} from './styles';

const UpdateSchema = Yup.object().shape({
  details: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
  version: Yup.string(),
  software_id: Yup.number().required('O Software é obrigatório'),
  fileDirectoryExe: Yup.mixed(),
  fileDirectoryScript: Yup.mixed(),
  fileDirectoryAditional: Yup.mixed(),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.update.loading);
  const [fileInfoExe, setFileInfoExe] = useState(null);
  const [fileInfoScript, setFileInfoScript] = useState(null);
  const [fileInfoAditional, setFileInfoAditional] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [fileExe, setFileExe] = useState(null);
  const [fileScript, setFileScript] = useState(null);
  const [fileAditional, setFileAditional] = useState(null);

  const [loadingExe, setLoadingExe] = useState(false);
  const [loadingScript, setLoadingScript] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  function handleAcceptedFiles(f, tipo) {
    if (tipo === 'EXE') {
      setFileExe(f[0]);
    } else if (tipo === 'SCRIPT') {
      setFileScript(f[0]);
    } else if (tipo === 'ADITIONAL') {
      setFileAditional(f[0]);
    }
  }
  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getSoftware();
  }, []);

  useEffect(() => {
    const handleSubmitFile = async () => {
      const data = new FormData();
      data.append('file', fileExe);

      if (!fileExe) return;

      if (fileExe.size > 1073741824) {
        toast.warn('Selecione um arquivo de até 124BM.');
        return;
      }
      try {
        setLoadingExe(true);
        const responseFile = await api.post('filedocuments', data);

        if (responseFile.data) {
          setFileInfoExe(responseFile.data);
        } else setFileInfoExe(null);

        setLoadingExe(false);
      } catch (error) {
        setLoadingExe(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.info(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    handleSubmitFile();
  }, [fileExe]);

  useEffect(() => {
    const handleSubmitFile = async () => {
      const data = new FormData();
      data.append('file', fileScript);

      if (!fileScript) return;

      if (fileExe.size > 1073741824) {
        toast.warn('Selecione um arquivo de até 124BM.');
        return;
      }
      try {
        setLoadingScript(true);
        const responseFile = await api.post('filedocuments', data);
        if (responseFile.data) {
          setFileInfoScript(responseFile.data);
        } else setFileInfoScript(null);

        setLoadingScript(false);
      } catch (error) {
        setLoadingScript(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.info(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    handleSubmitFile();
  }, [fileScript]);

  useEffect(() => {
    const handleSubmitFile = async () => {
      const data = new FormData();
      data.append('file', fileAditional);

      if (!fileAditional) return;

      if (fileExe.size > 1073741824) {
        toast.warn('Selecione um arquivo de até 124BM.');
        return;
      }
      try {
        setLoadingAdd(true);
        const responseFile = await api.post('filedocuments', data);
        if (responseFile.data) {
          setFileInfoAditional(responseFile.data);
        } else setFileInfoAditional(null);
        setLoadingAdd(false);
      } catch (error) {
        setLoadingAdd(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.info(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    handleSubmitFile();
  }, [fileAditional]);

  async function handleSubmit({ details, software_id, version }) {
    dispath(
      updateRequestCreate(
        software_id,
        details,
        version,
        fileInfoExe && fileInfoExe.id, // exe_id:
        fileInfoScript && fileInfoScript.id, // script_id:
        fileInfoAditional && fileInfoAditional.id // aditional_id:
      )
    );
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Atualizações">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          details: '',
          software_id: '',
          version: '',
        }}
        validationSchema={UpdateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Detalhes sobre essa atualização" />
            <Field
              placeholder=""
              type="text"
              name="details"
              component={Input}
            />

            <Label text="Versão" />
            <Field
              placeholder=""
              type="text"
              name="version"
              component={Input}
            />

            <Label text="Selecione um Software" />
            <Field name="software_id" component={Select} type="text">
              <option value="" disabled>
                Selecione um produto
              </option>
              {softwares.map((software) => (
                <option key={software.id} value={software.id}>
                  {software.software_name}
                </option>
              ))}
            </Field>
            <GridLayout>
              <div>
                <Label text="Arquivo de EXE (.zip)" />
                {fileExe ? (
                  <CardFile>
                    <FaRegFileArchive size={25} />
                    <div>
                      <h2>{fileExe && fileExe.name}</h2>
                      <h3>{formatBytes(fileExe && fileExe.size)}</h3>
                    </div>
                  </CardFile>
                ) : (
                  <ContainerInputFile>
                    <InputFile
                      name="fileDirectoryExe"
                      accept=".zip"
                      acceptDrop={{ 'appliction/zip': ['.zip'] }}
                      onDrop={(f) => handleAcceptedFiles(f, 'EXE')}
                      onChange={(e) => {
                        setFileExe(e.target.files[0]);
                        props.setFieldValue('fileDirectoryExe', e.target.value);
                      }}
                    />
                  </ContainerInputFile>
                )}
              </div>
              <div>
                <Label text="Arquivo de Scripts (.zip)" />
                {fileScript ? (
                  <CardFile>
                    <FaRegFileArchive size={25} />
                    <div>
                      <h2>{fileScript && fileScript.name}</h2>
                      <h3>{formatBytes(fileScript && fileScript.size)}</h3>
                    </div>
                  </CardFile>
                ) : (
                  <ContainerInputFile>
                    <InputFile
                      name="fileDirectoryScript"
                      accept=".zip"
                      acceptDrop={{ 'appliction/zip': ['.zip'] }}
                      onDrop={(f) => handleAcceptedFiles(f, 'SCRIPT')}
                      onChange={(e) => {
                        setFileScript(e.target.files[0]);
                        props.setFieldValue(
                          'fileDirectoryScript',
                          e.target.value
                        );
                      }}
                    />
                  </ContainerInputFile>
                )}
              </div>
              <div>
                <Label text="Arquivo Adicional (.zip)" />
                {fileAditional ? (
                  <CardFile>
                    <FaRegFileArchive size={25} />
                    <div>
                      <h2>{fileAditional && fileAditional.name}</h2>
                      <h3>
                        {formatBytes(fileAditional && fileAditional.size)}
                      </h3>
                    </div>
                  </CardFile>
                ) : (
                  <ContainerInputFile>
                    <InputFile
                      name="fileDirectoryAdicional"
                      accept=".zip"
                      acceptDrop={{ 'appliction/zip': ['.zip'] }}
                      onDrop={(f) => handleAcceptedFiles(f, 'ADITIONAL')}
                      onChange={(e) => {
                        setFileAditional(e.target.files[0]);
                        props.setFieldValue(
                          'fileDirectoryAdicional',
                          e.target.value
                        );
                      }}
                    />
                  </ContainerInputFile>
                )}
              </div>
            </GridLayout>
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/update-list')}
              />
              <Button
                size="contained"
                type="submit"
                disabled={loadingExe || loadingScript || loadingAdd}
                text={loading || loadingExe ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Create;
