import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { showModal } from '../../../store/modules/modal/actions';
import history from '../../../history';
import api from '../../../services/api';
import { ticketRequestDelete } from '../../../store/modules/ticket/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCellHeaderWithOrder,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button, { SearchButton } from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions, Etiqueta } from './styles';
import ContainerCard from '../../../components/ContainerCard';
import Input, { InputFile } from '../../../components/Input';
import Select from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import { SelectReactNoForm } from '../../../components/SelectReaact';

moment.locale('pt-br');

function ListBackup() {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [servers, setServers] = useState([]);
  const [serversOri, setServersOri] = useState([]);
  const [cadServer, setCadServer] = useState([]);
  const [serverQuery, setServerQuery] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const loading = useState(false);

  useEffect(() => {
    const getServers = async () => {
      try {
        const response = await api.get('/searchbackuplog', {
          params: {
            typeServer: '',
          },
        });

        if (response.data) {
          setServers(response.data);
          setServersOri(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getServers();

    const getCadServers = async () => {
      try {
        const response = await api.get('/servers');

        if (response.data) {
          setCadServer(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getServers();
    getCadServers();
  }, []);

  const requestSort = (key) => {
    setServers((currentTickets) => {
      // Cria uma nova cópia para ordenar
      const ticketsToSort = [...currentTickets];
      let direction = 'ascending';

      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      ticketsToSort.sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      // Atualiza o config de ordenação
      setSortConfig({ key, direction });
      // Retorna o array ordenado para atualizar o estado
      return ticketsToSort;
    });
  };

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = serversOri.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setServers(resultadoFiltrado);
  }
  async function handleSubmit({ typeServer }) {
    try {
      const response = await api.get('/searchbackuplog', {
        params: {
          typeServer,
          server_id: serverQuery,
        },
      });
      if (response.data) {
        setServers(response.data);
        setServersOri(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerList>
      <ModalConfirm />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          typeServer: '',
        }}
        // validationSchema={TicketSchema}
      >
        {(props) => (
          <StyledForm>
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Tipo do Servidor" />
                  <Field name="typeServer" component={Select} type="text">
                    <option key="Todos" value="">
                      Todos
                    </option>
                    <option key="Geral" value="G">
                      Sevidor Principal
                    </option>
                    <option key="Geral" value="C">
                      Sevidor Contingente
                    </option>
                  </Field>
                </div>
                <div id="1" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Servidor" />
                  <SelectReactNoForm
                    value={(serverQuery, cadServer.label)}
                    placeholder="Selecione um Servidor"
                    onChange={(e) => {
                      if (e) {
                        setServerQuery(e.value);
                      } else {
                        setServerQuery(null);
                      }
                    }}
                    options={cadServer.map((ss) => ({
                      label:
                        ss.label || (ss.type === 'G' ? 'Geral' : 'Contingente'),
                      value: ss.id,
                    }))}
                    name="idGroup"
                  />
                </div>

                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Pesquisar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>

      <Table
        title="Servidores e Backups"
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('id')}
            sortKey="id"
            sortConfig={sortConfig}
          >
            #
          </TableCellHeaderWithOrder>
          <TableCellHeaderWithOrder
            onClick={() => requestSort('label')}
            sortKey="label"
            sortConfig={sortConfig}
          >
            Servidor
          </TableCellHeaderWithOrder>

          <TableCellHeaderWithOrder
            onClick={() => requestSort('database')}
            sortKey="database"
            sortConfig={sortConfig}
          >
            Banco de dados
          </TableCellHeaderWithOrder>
          <TableCellHeader>Dt. Último backup</TableCellHeader>
          <TableCellHeader>Dias do último backup</TableCellHeader>
          <TableCellHeader>Tamanho (MB)</TableCellHeader>
        </TableHeader>
        <tbody>
          {servers.map((ss) => (
            <TableRow key={ss.id} height={36}>
              <TableCell>{ss.server_id}</TableCell>
              <TableCell>{ss.label}</TableCell>
              <TableCell>{ss.database}</TableCell>
              <TableCell>
                {ss.last_backup &&
                  moment(ss.last_backup).format('DD/MM/YYYY HH:mm:ss')}
              </TableCell>
              <TableCell>
                {ss.backup_days > 1
                  ? `${ss.backup_days} dias`
                  : `${ss.backup_days} dia`}
              </TableCell>
              <TableCell>{ss.size ? `${ss.size.toFixed(2)} MB` : ''}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListBackup;
