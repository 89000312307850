import styled from 'styled-components';
import { lighten } from 'polished';

export const ContainerTop = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props) => lighten(0.3, props.theme.colors.accent)};
  display: flex;
  align-items: center;

  justify-content: space-around;
  padding: 8px;
  flex-direction: row;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  img {
    width: 120px;
    height: auto;
  }

  span {
    width: 60%;
    h2,
    h3 {
      color: ${(props) => props.theme.colors.accent};
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    h3 {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  //max-width: 500px;
  max-width: ${({ size }) => (size ? `${size}px` : '800px')};
  height: auto;
  display: flex;
  //position: relative;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;

  padding: 0px;
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${(props) => {
    if (props.theme.name === 'dark') return '';
    if (props.removeShadow) return '';

    return `2px 2px 15px ${props.theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;

export const ContainerContent = styled.div`
  width: 100%;
  //max-width: 500px;
  max-width: ${({ size }) => (size ? `${size}px` : '800px')};
  height: auto;
  display: flex;
  //position: relative;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px 20px;
  padding-top: 30px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 22px;
  margin-bottom: 30px;
`;

export const Logo = styled.img`
  height: auto;
  width: 250px;
`;

export const TextBtnLogin = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
`;

export const TextBtnSignUp = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
`;
