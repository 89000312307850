import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import city from './city/reducer';
import group from './group/reducer';
import computer from './computer/reducer';
import ticket from './ticket/reducer';
import ticketCategory from './ticketcategory/reducer';
import ticketDiscussion from './ticketdiscussion/reducer';
import company from './company/reducer';
import license from './license/reducer';
import businessmodule from './businessmodule/reducer';
import software from './software/reducer';
import update from './update/reducer';
import updateReleased from './updatereleased/reducer';
import softmodule from './softmodule/reducer';
import server from './server/reducer';
import serverdatabase from './serverdatabase/reducer';

import recurrentexport from './recurrentexport/reducer';
// import chartaccount from './chartaccount/reducer';
// import subchart from './subchart/reducer';
import issuer from './issuer/reducer';
// import document from './document/reducer';

import modal from './modal/reducer';
import sideMenu from './sideMenu/reducer';

export default combineReducers({
  auth,
  user,
  city,
  group,
  ticket,
  ticketCategory,
  ticketDiscussion,
  company,
  license,
  businessmodule,
  software,
  computer,
  update,
  server,
  softmodule,
  updateReleased,
  serverdatabase,
  recurrentexport,
  // chartaccount,
  // subchart,
  modal,
  sideMenu,
  issuer,
  // document,
});
