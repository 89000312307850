import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { computerFailure, computerSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* computerCreate({ payload }) {
  try {
    const { description, business_id, viewer_connection_id, situation } =
      payload;

    yield call(api.post, 'computers', {
      description,
      business_id,
      viewer_connection_id,
      situation,
    });

    yield put(computerSuccess());

    history.push('/computer-list');
    toast.success('Novo computador cadastrado');
  } catch (error) {
    yield put(computerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Computador não cadastrado');
  }
}
export function* computerUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `computers/${id}`, {
      description,
    });

    yield put(computerSuccess());

    history.push('/computer-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(computerSuccess());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* computerDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `computers/${id}`);

    yield put(computerSuccess());

    history.push('/computer-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(computerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('COMPUTER_REQUEST_CREATE', computerCreate),
  takeLatest('COMPUTER_REQUEST_UPDATE', computerUpdate),
  takeLatest('COMPUTER_REQUEST_DELETE', computerDelete),
]);
