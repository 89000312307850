export function computerRequestCreate(
  description,
  business_id,
  viewer_connection_id,
  situation
) {
  return {
    type: 'COMPUTER_REQUEST_CREATE',
    payload: { description, business_id, viewer_connection_id, situation },
  };
}

export function computerRequestUpdate(id, description) {
  return {
    type: 'COMPUTER_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function computerRequestDelete(id) {
  return {
    type: 'COMPUTER_REQUEST_DELETE',
    payload: { id },
  };
}

export function computerFailure() {
  return {
    type: 'COMPUTER_FAILURE',
  };
}

export function computerSuccess() {
  return {
    type: 'COMPUTER_SUCCESS',
  };
}
