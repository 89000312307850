// actions.js

// Ação para criar um novo servidor
export function serverRequestCreate(
  label,
  ip,
  operation_system,
  memory,
  harddisck_main,
  name_pc,
  type,
  email,
  email_drive,
  key_drive,
  scope_drive
) {
  return {
    type: 'CREATE_SERVER_REQUEST',
    payload: {
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    },
  };
}

export function createServerSuccess(server) {
  return {
    type: 'CREATE_SERVER_SUCCESS',
    payload: server,
  };
}

export function createServerFailure(error) {
  return {
    type: 'CREATE_SERVER_FAILURE',
    payload: error,
  };
}

// Ação para atualizar um servidor existente
export function serverRequestUpdate(
  id,
  label,
  ip,
  operation_system,
  memory,
  harddisck_main,
  name_pc,
  type,
  email,
  email_drive,
  key_drive,
  scope_drive
) {
  return {
    type: 'UPDATE_SERVER_REQUEST',
    payload: {
      id,
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    },
  };
}

export function updateServerSuccess(server) {
  return {
    type: 'UPDATE_SERVER_SUCCESS',
    payload: server,
  };
}

export function updateServerFailure(error) {
  return {
    type: 'UPDATE_SERVER_FAILURE',
    payload: error,
  };
}

// Ação para excluir um servidor
export function deleteServerRequest(id) {
  return {
    type: 'DELETE_SERVER_REQUEST',
    payload: { id },
  };
}

export function deleteServerSuccess() {
  return {
    type: 'DELETE_SERVER_SUCCESS',
  };
}

export function deleteServerFailure(error) {
  return {
    type: 'DELETE_SERVER_FAILURE',
    payload: error,
  };
}

// Ação para listar todos os servidores
export function listServersRequest() {
  return {
    type: 'LIST_SERVERS_REQUEST',
  };
}

export function listServersSuccess(servers) {
  return {
    type: 'LIST_SERVERS_SUCCESS',
    payload: servers,
  };
}

export function listServersFailure(error) {
  return {
    type: 'LIST_SERVERS_FAILURE',
    payload: error,
  };
}
