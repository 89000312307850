import React, { useState } from 'react';

import Modal from '../Modal';
import { TextInfo, ContainerButtons } from './styles';
import Button from '../Button';

let openModalFn;
function ModalConfirm() {
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('Confirmação');
  const [message, setMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(null);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line func-names
  // eslint-disable-next-line no-shadow
  openModalFn = function ({ title, message, onConfirm, loading }) {
    // eslint-disable-next-line func-names
    const confirm = function () {
      onConfirm();
      setOpenModal(false);
    };

    setOpenModal(true);
    setTitle(title);
    setMessage(message);
    setOnConfirm(() => confirm);
    setLoading(loading);
  };
  return (
    <Modal title={title} opened={openModal} close={() => setOpenModal(false)}>
      <TextInfo>{message}</TextInfo>
      <ContainerButtons>
        <Button
          size="contained"
          variant="outline"
          text="Cancelar"
          type="button"
          onClick={() => setOpenModal(false)}
        />
        <Button
          size="contained"
          text={loading ? 'Aguarde...' : 'Confirmar'}
          onClick={() => onConfirm()}
        />
      </ContainerButtons>
    </Modal>
  );
}

export default ModalConfirm;

export function modalConfirm({ title, message, onConfirm, loading }) {
  openModalFn({ title, message, onConfirm, loading });

  /*
  return (
    <ModalConfirm
      title={title}
      message={message}
      onConfirm={onConfirm}
      loading={loading}
    />
  );
  */

  // openModalFn(true);
}
