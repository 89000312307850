import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createServerSuccess,
  createServerFailure,
  updateServerSuccess,
  updateServerFailure,
  deleteServerSuccess,
  deleteServerFailure,
  listServersSuccess,
  listServersFailure,
} from './actions';
import history from '../../../history';
import api from '../../../services/api';

export function* createServer({ payload }) {
  try {
    const {
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    } = payload;

    yield call(api.post, 'servers', {
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    });

    yield put(createServerSuccess());
    history.push('/server-list');
    toast.success('Novo servidor cadastrado com sucesso');
  } catch (error) {
    yield put(createServerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Servidor não cadastrado');
    }
  }
}

export function* updateServer({ payload }) {
  try {
    const {
      id,
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    } = payload;

    yield call(api.put, `servers/${id}`, {
      label,
      ip,
      operation_system,
      memory,
      harddisck_main,
      name_pc,
      type,
      email,
      email_drive,
      key_drive,
      scope_drive,
    });

    yield put(updateServerSuccess());
    history.push('/server-list');
    toast.success('Dados do servidor atualizados com sucesso');
  } catch (error) {
    yield put(updateServerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Dados do servidor não atualizados');
    }
  }
}

export function* deleteServer({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `servers/${id}`);
    yield put(deleteServerSuccess());
    history.push('/server-list');
    toast.success('Servidor excluído com sucesso');
  } catch (error) {
    yield put(deleteServerFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Servidor não excluído');
    }
  }
}

export function* listServers() {
  try {
    const response = yield call(api.get, 'servers');
    yield put(listServersSuccess(response.data));
  } catch (error) {
    yield put(listServersFailure());
    if (error.response && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else {
      toast.error('Erro ao buscar a lista de servidores');
    }
  }
}

export default all([
  takeLatest('CREATE_SERVER_REQUEST', createServer),
  takeLatest('UPDATE_SERVER_REQUEST', updateServer),
  takeLatest('DELETE_SERVER_REQUEST', deleteServer),
  takeLatest('LIST_SERVERS_REQUEST', listServers),
]);
