import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { serverRequestCreate } from '../../../store/modules/server/actions';
import history from '../../../history';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import Select from '../../../components/Select';

import { ContainerButtons, Divider, TitleDivider } from './styles';

const ValidateSchema = Yup.object().shape({
  label: Yup.string().required('O campo "Label" é obrigatório'),
  ip: Yup.string(),
  operation_system: Yup.string(),
  memory: Yup.string(),
  harddisck_main: Yup.string(),
  email: Yup.string().email('Email inválido'),
  name_pc: Yup.string().required('O campo "Nome do PC" é obrigatório'),
  type: Yup.string().required('O campo "Tipo do Servidor" é obrigatório'),
});

function Create() {
  const dispath = useDispatch();

  const loading = useSelector((state) => state.server.loading);

  async function handleSubmit({
    label,
    name_pc,
    type,
    email,
    email_drive,
    key_drive,
    scope_drive,
  }) {
    console.log('teste');
    dispath(
      serverRequestCreate(
        label,
        null,
        null,
        null,
        null,
        name_pc,
        type,
        email,
        email_drive,
        key_drive,
        scope_drive
      )
    );
    console.log();
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Servidores">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          label: '',
          ip: '',
          operation_system: '',
          memory: '',
          harddisck_main: '',
          name_pc: '',
          type: '',
          email: '',
          email_drive: '',
          key_drive: '',
          scope_drive: '',
        }}
        // validationSchema={ValidateSchema}
      >
        <StyledForm>
          <Label text="Descrição" />
          <Field placeholder="" type="text" name="label" component={Input} />

          <Label text="Nome do Computador (certifique-se de escrever corretamente o nome)" />
          <Field placeholder="" type="text" name="name_pc" component={Input} />

          <Label text="Email para Notificação" />
          <Field placeholder="" type="text" name="email" component={Input} />
          <Label text="Tipo do Servidor" />
          <Field name="type" component={Select} type="text">
            <option value="" disabled>
              Selecione uma opção
            </option>
            <option key="G" value="G">
              Geral
            </option>
            <option key="C" value="C">
              Contigente
            </option>
          </Field>
          <Divider>
            <TitleDivider>Google Drive - Configurações</TitleDivider>
          </Divider>
          <Label text="Email" />
          <Field
            placeholder=""
            type="text"
            name="email_drive"
            component={Input}
          />
          <Label text="Private Key" />
          <Field
            placeholder=""
            type="text"
            name="key_drive"
            component={Input}
          />
          <Label text="Scope" />
          <Field
            placeholder=""
            type="text"
            name="scope_drive"
            component={Input}
          />
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/server-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
