import styled from 'styled-components';
import Button from '../../../components/Button';

export const ContainerButtons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CardModal = styled.div`
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: hidden;
  position: fixed;
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  transition: all 0.2s;
`;

export const ButtonNoMargin = styled(Button)`
  margin: 0px;
`;

export const CardFile = styled.div`
  width: 100%;
  height: 65px;
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 1px solid ${({ theme }) => theme.colors.shade};
  span {
    width: 65px;
    height: 100%;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  div {
    margin-left: 5px;
    width: auto;
    height: 65px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 14px;
      font-weight: 600;
    }
    h3 {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

export const GridLayout = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }

  span {
    span {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
      display: grid;
      grid-gap: 10px;
      width: 100%;
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
`;

export const ContainerInputFile = styled.div`
  width: 100%;
`;

export const ContainerInputRadio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    label {
      margin-bottom: 0px;
    }
    input {
      margin-left: 5px;
    }
  }
`;
