import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { signInRequest } from '../../store/modules/auth/actions';

import Input from '../../components/Input';
import Button from '../../components/Button';
import StyledForm from '../../components/Form';
import Label from '../../components/Label';

import { ContainerTop, Container, ContainerContent } from './styles';
import loginIlustra from '../../assets/images/login.png';

const publicVapidKey = 'FOCO$1010';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('O e-mail é obrigatório')
    .email('E-mail inválido'),

  password: Yup.string()
    .required('A senha é obrigatória')
    .min(6, 'Muito curto'),
});

function handleReset(values, { setStatus }) {
  setStatus(true);
}

function Login() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  async function handleSubmit({ email, password }) {
    dispath(signInRequest(email, password));
  }

  return (
    <Container size="500">
      <ContainerTop>
        <span>
          <h2>Bem-vindo!</h2>
          <h3>Faça login para continuar</h3>
        </span>
        <img src={loginIlustra} alt="Logo" />
      </ContainerTop>
      <ContainerContent>
        <Formik
          onSubmit={handleSubmit}
          onReset={handleReset}
          initialValues={{
            email: '',
            password: '', // 123456
          }}
          validationSchema={LoginSchema}
        >
          <StyledForm>
            <Label text="Seu email" />
            <Field
              placeholder=""
              textError="senha errada"
              type="text"
              name="email"
              component={Input}
            />
            <Label text="Sua senha" />
            <Field
              placeholder=""
              textError="senha errada"
              type="password"
              name="password"
              component={Input}
            />
            <Button type="submit" text={loading ? 'Carregando...' : 'Entrar'} />
            {/* <Button
              variant="outline"
              text="Criar conta"
              type="button"
              onClick={() => history.push('/signup')}
            /> */}

            {/* <Button variant="text" text="Esqueci a senha" /> */}
          </StyledForm>
        </Formik>
      </ContainerContent>
    </Container>
  );
}

export default Login;
