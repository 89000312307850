import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { subChartRequestCreate } from '../../../../store/modules/subchart/actions';
import { hideModal } from '../../../../store/modules/modal/actions';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import StyledForm from '../../../../components/Form';
import Label from '../../../../components/Label';
import ContainerCard from '../../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const ValidateSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
});

function Create({ chartaccountId }) {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.subchart.loading);

  async function handleSubmit({ description }) {
    dispath(subChartRequestCreate(description, chartaccountId));
  }
  function handleReset(values, { setStatus }) {
    dispath(hideModal());
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Sub Plano de Contas" removeShadow>
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: '',
        }}
        validationSchema={ValidateSchema}
      >
        <StyledForm>
          <Label text="Descrição do sub Plano" />
          <Field
            placeholder=""
            type="text"
            name="description"
            component={Input}
          />

          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="reset"
              onClick={() => {
                dispath(hideModal());
              }}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
