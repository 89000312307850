import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import api from '../../../services/api';
import { ticketCategoryRequestDelete } from '../../../store/modules/ticketcategory/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListComputer() {
  const [computers, setComputers] = useState([]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.computer.loading);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    const getTicketCategories = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('computers');
        if (response.data) {
          setComputers(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getTicketCategories();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispatch(ticketCategoryRequestDelete(id));
      },
      loading,
    });
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/computer-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table title="Computadores Cadastrados" loading={loadingTable}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Descrição</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {computers.map((Computer) => (
            <TableRow key={Computer.id}>
              <TableCell>{Computer.id}</TableCell>
              <TableCell>{Computer.description}</TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`computer-edit/${Computer.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(Computer.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListComputer;
