import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  flex-direction: column;
`;

export const StyledInput = styled.select`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';
    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  option {
    width: 100%;
    background-color: ${(props) => props.theme.colors.bright};
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border-width: 0px;
    display: flex;
    height: 25px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 400;
    font-size: 14px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const Error = styled.div`
  color: ${(props) => props.theme.colors.error};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '20px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 15px;
  padding-left: 0px;
  font-weight: 400;
  font-size: 12px;
`;
