import React from 'react';

import { Tooltip as ReactTooltip, Tooltip } from 'react-tooltip';
import { StyledButton } from './styles';

export default function Button({
  children,
  disabled,
  loading,
  variant,
  color,
  text,
  size,
  ...rest
}) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Tooltip) {
      // Passar a prop data-tooltip-id para o ReactTooltip
      return React.cloneElement(child, {
        id: rest['data-tooltip-id'],
        place: 'bottom',
      });
    }
    return child;
  });

  return (
    <StyledButton
      color={color}
      variant={variant}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {childrenWithProps}
    </StyledButton>
  );
}
