import styled from 'styled-components';

export const ContainerButtons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TitleDivider = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '20px')};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
`;
