import styled from 'styled-components';
import { Form } from 'formik';

const StyledForm = styled(Form)`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export default StyledForm;
