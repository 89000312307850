import styled from 'styled-components';

export const Container = styled.div`
  width: ${({ horizontal }) => (horizontal ? 'auto' : '100%')};
  display: flex;
  //position: relative;
  margin-bottom: ${({ horizontal }) => (horizontal ? '0px' : '8px')};
  flex-direction: column;
  align-items: flex-start;

  margin-right: ${({ horizontal }) => (horizontal ? '10px' : '0px')};
`;

export const Text = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
`;
