import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import moment from 'moment';
import history from '../../../history';
import api from '../../../services/api';
import { companyRequestDelete } from '../../../store/modules/company/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button, { SearchButton } from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerActions, ContainerButtons } from './styles';
import ContainerCard from '../../../components/ContainerCard';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import Input, { InputFile } from '../../../components/Input';

moment.locale('pt-br');

function ListUser() {
  const [users, setUsers] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.company.loading);

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('/searchuser');
        if (response.data) {
          setUsers(response.data);
        }

        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getUsers();
  }, [loading]);

  async function handleSubmit({ name }) {
    try {
      const response = await api.get('/searchuser', {
        params: {
          name,
        },
      });
      if (response.data) {
        setUsers(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerList>
      <ModalConfirm />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          name: '',
        }}
        // validationSchema={TicketSchema}
      >
        {(props) => (
          <StyledForm>
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Usuário" />
                  <Field
                    placeholder="Digite o nome do Usuário"
                    type="text"
                    name="name"
                    component={Input}
                  />
                </div>

                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Salvar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>
      <Table title="Usuários cadastrados" loading={loadingTable}>
        {' '}
        {/* Update title */}
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Nome</TableCellHeader> {/* Change header */}
          <TableCellHeader>E-mail</TableCellHeader> {/* Change header */}
          <TableCellHeader>Usuário</TableCellHeader>
          <TableCellHeader>Último Login</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.name}</TableCell> {/* Change data */}
              <TableCell>{user.email}</TableCell> {/* Change data */}
              <TableCell>{user.user_name}</TableCell>
              <TableCell>
                {user.ultimologin && moment(user.ultimologin).fromNow()}
              </TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`user-edit/${user.id}`); // Change route
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Acessos"
                    onClick={() => {
                      history.push(`user-access/${user.id}`); // Change route
                    }}
                  >
                    <FiUnlock size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListUser;
