import React from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { Container, Error } from './styles';

export default function SelectReact({
  field,
  children,
  options,
  form: { touched, errors },
  ...props
}) {
  const theme = useTheme();
  const CustomStyle = {
    option: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: theme.colors.bright,
      fontWeight: 400,
      color: theme.colors.primary,
      height: '25px',
      ':hover': {
        backgroundColor: theme.colors.accent,
        color: theme.colors.bright,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: theme.colors.bright,
      color: theme.colors.primary,
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      marginBottom: '0px',
      borderColor: state.isFocused ? theme.colors.accent : theme.colors.shade,
      boxShadow: 'none',

      ':hover': {
        borderColor: theme.colors.accent,
      },
      ':focus:hover': {
        borderColor: theme.colors.accent,
      },
      ':focus': {
        borderColor: theme.colors.accent,
      },
      ':active': {
        borderColor: theme.colors.accent,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      visibility: 'hidden',
    }),
    dropdownIndicator: (privided) => ({
      ...privided,
      color: theme.colors.shade,
    }),
    menu: (provided) => ({
      ...provided,
      padding: '0px',
      borderRadius: '0px',
    }),
  };
  return (
    <Container>
      <Select
        styles={CustomStyle}
        {...field}
        {...props}
        options={options}
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
      />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </Container>
  );
}

export function SelectReactNoForm({ options, ...props }) {
  const theme = useTheme();
  const CustomStyle = {
    option: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: theme.colors.bright,
      fontWeight: 400,
      color: theme.colors.primary,
      // Adjust the height if necessary to fit the content
      height: '25px',
      display: 'flex', // This ensures content is centered
      alignItems: 'center', // Vertically center the text
      justifyContent: 'left', // Horizontally center the text
      ':hover': {
        backgroundColor: theme.colors.accent,
        color: theme.colors.bright,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: theme.colors.bright,
      color: theme.colors.primary,
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      marginBottom: '0px',
      borderColor: state.isFocused ? theme.colors.accent : theme.colors.shade,
      boxShadow: 'none',

      ':hover': {
        borderColor: theme.colors.accent,
      },
      ':focus:hover': {
        borderColor: theme.colors.accent,
      },
      ':focus': {
        borderColor: theme.colors.accent,
      },
      ':active': {
        borderColor: theme.colors.accent,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      visibility: 'hidden',
    }),
    dropdownIndicator: (privided) => ({
      ...privided,
      color: theme.colors.shade,
    }),
    menu: (provided) => ({
      ...provided,
      padding: '0px',
      borderRadius: '0px',
    }),
  };
  return (
    <Container>
      <Select isClearable styles={CustomStyle} {...props} options={options} />
    </Container>
  );
}
