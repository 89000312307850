export function softModuleRequestCreate(description, details, software_id) {
  return {
    type: 'SOFTMODULE_REQUEST_CREATE',
    payload: { description, details, software_id },
  };
}

export function softModuleRequestUpdate(id, description, details, software_id) {
  return {
    type: 'SOFTMODULE_REQUEST_UPDATE',
    payload: { details, description, id, software_id },
  };
}

export function softModuleRequestDelete(id) {
  return {
    type: 'SOFTMODULE_REQUEST_DELETE',
    payload: { id },
  };
}

export function softModuleFailure() {
  return {
    type: 'SOFTMODULE_FAILURE',
  };
}

export function softModuleSuccess() {
  return {
    type: 'SOFTMODULE_SUCCESS',
  };
}
