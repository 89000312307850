import styled from 'styled-components';
import Select from 'react-select';
import { select } from 'redux-saga/effects';
import DatePicker from 'react-datepicker';
import Input from '../../../components/Input';
import { StyledInput } from '../../../components/Input/styles';

export const InputLittle = styled(Input)`
  width: 100%;
  margin-bottom: 25px;
`;

export const InputNoMandatory = styled(StyledInput)`
  width: 100%;
  height: 25px;
  justify-content: flex-center;
  align-items: center;
  /* padding:20; */
  flex: 1;
  /* border_center: 0px; */
  /* border_left: 200px; */
  /* margin-right :5px; */
  /* div:not(:last-child) {
    margin-right :5px;
  } */
`;

export const ContainerButtons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ContainerAlignToTheSide = styled.div`
  display: block;
  padding: 10px;
  /* display: flex; */
`;

export const ContainerInputs = styled.div`
  width: 100%;
  height: auto;
  /* max-width: ${({ size }) => (size ? `${size}px` : '800px')}; */
  display: flex;
  /* display: inline-block; */

  /* text-align: left; */
  justify-content: flex-end;

  align-items: center;

  & > span {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  span:not(:last-child) {
    margin-right: 5px;
  }
`;

export const TitleDivider = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '20px')};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
`;

export const Divider = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectSearch = styled(Select)`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '15px 20px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return `2px 2px 15px ${theme.colors.shade}`;
  }};
  height: 52px;
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
`;

export const ContainerSpaceTable = styled.div`
  margin-right: 5px;
`;

export const InputCheckBox = styled.input`
  width: 25px;
  height: 25px;
  border-width: 1;
  justify-content: 'center';
  align-items: 'center';
`;

export const StyledInputDateNoForm = styled.input`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
`;
export const ContainerAuxTable = styled.div`
  width: calc(100%);
  //height: 100%;
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px;

  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return `2px 2px 15px ${theme.colors.shade}`;
  }};
  background-color: ${(props) => props.theme.colors.bright};
  border-radius: 4px;
`;
