import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { groupRequestCreate } from '../../../store/modules/group/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import Select from '../../../components/Select';

import { ContainerButtons } from './styles';

const GroupSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
  // software_id: Yup.number().required('O Software é obrigatório'),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);
  const [softwares, setSoftwares] = useState([]);

  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getSoftware();
  }, []);

  async function handleSubmit({ description }) {
    dispath(groupRequestCreate(description));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Grupo de Empresa">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: '',
          // software_id: '',
        }}
        validationSchema={GroupSchema}
      >
        <StyledForm>
          <Label text="Descrição do Grupo de Empresa" />
          <Field
            placeholder=""
            type="text"
            name="description"
            component={Input}
          />
          {/*
          <Label text="Software" />
          <Field name="software_id" component={Select} type="text">
            <option value="" disabled>
              Selecione um Software
            </option>
            {softwares.map((software) => (
              <option key={software.id} value={software.id}>
                {software.software_name}
              </option>
            ))}
          </Field>
            */}
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/group-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
