import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import { cityFailure, citySuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* cityCreate({ payload }) {
  try {
    const { name, uf } = payload;

    yield call(api.post, 'cities', {
      name,
      uf,
    });

    yield put(citySuccess());

    history.push('/city-list');
    toast.success('Nova cidade cadastrada');
  } catch (error) {
    yield put(cityFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cidade não cadastrada');
  }
}
export function* cityUpdate({ payload }) {
  try {
    const { id, name, uf } = payload;

    yield call(api.put, `cities/${id}`, {
      name,
      uf,
    });

    yield put(citySuccess());

    history.push('/city-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(cityFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* cityDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `cities/${id}`);

    yield put(citySuccess());

    history.push('/city-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(cityFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('CITY_REQUEST_CREATE', cityCreate),
  takeLatest('CITY_REQUEST_UPDATE', cityUpdate),
  takeLatest('CITY_REQUEST_DELETE', cityDelete),
]);
