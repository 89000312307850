import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { licenseFailure, licenseSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* licenseCreate({ payload }) {
  try {
    const { table } = payload;
    console.log('Testando2');
    yield call(api.post, 'license', { a: table });

    yield put(licenseSuccess());

    history.push('/license-list');
    toast.success('Nova licença cadastrada');
  } catch (error) {
    yield put(licenseFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Não há licença para esta empresa');
  }
}
export function* licenseUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `licenses/${id}`, {
      description,
    });

    yield put(licenseSuccess());

    history.push('/license-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(licenseFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* licenseDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `licenses/${id}`);

    yield put(licenseSuccess());

    history.push('/license-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(licenseFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('LICENSE_REQUEST_CREATE', licenseCreate),
  takeLatest('LICENSE_REQUEST_UPDATE', licenseUpdate),
  takeLatest('LICENSE_REQUEST_DELETE', licenseDelete),
]);
