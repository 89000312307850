export function ticketDiscussionRequestCreate(
  ticket_id,
  message,
  support_response
) {
  return {
    type: 'TICKETDISCUSSION_REQUEST_CREATE',
    payload: { ticket_id, message, support_response },
  };
}

export function ticketDiscussionRequestUpdate(id, message) {
  return {
    type: 'TICKETDISCUSSION_REQUEST_UPDATE',
    payload: { id, message },
  };
}

export function ticketDiscussionRequestDelete(id) {
  return {
    type: 'TICKETDISCUSSION_REQUEST_DELETE',
    payload: { id },
  };
}

export function ticketDiscussionFailure() {
  return {
    type: 'TICKETDISCUSSION_FAILURE',
  };
}

export function ticketDiscussionSuccess() {
  return {
    type: 'TICKETDISCUSSION_SUCCESS',
  };
}
