import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import api from '../../../services/api';
import { groupRequestDelete } from '../../../store/modules/group/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';

function ListGroup() {
  const [groups, setGroups] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    const getGroups = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('groups');
        if (response.data) {
          setGroups(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getGroups();
  }, [loading]);

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(groupRequestDelete(id));
      },
      loading,
    });
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/group-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table title="Grupos cadastradas" loading={loadingTable}>
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Descrição</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {groups.map((group) => (
            <TableRow key={group.id}>
              <TableCell>{group.id}</TableCell>
              <TableCell>{group.description}</TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      history.push(`group-edit/${group.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                  <ButtonIcon
                    data-tooltip-id="Excluir"
                    onClick={() => handleDelete(group.id)}
                  >
                    <FaTrashAlt size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListGroup;
