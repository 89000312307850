// reducer.js
import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
  ticketId: null,
};

export default function ticket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TICKET_REQUEST_CREATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'TICKET_REQUEST_UPDATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'TICKET_REQUEST_DELETE':
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case 'TICKET_ID_RECEIVED':
      return produce(state, (draft) => {
        draft.ticketId = action.payload.ticketId;
      });

    case 'TICKET_SUCCESS':
      if (!action.payload || !action.payload.skipCreate) {
        // Faça o que você precisar fazer aqui
        // Evite redespachar a ação TICKET_REQUEST_CREATE
      }
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case 'TICKET_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });

    case 'TICKET_REQUEST_CREATE_SUCCESS':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.ticketId = action.payload.ticketId; // Armazena o ID do ticket no estado
      });
    default:
      return state;
  }
}
