import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
};

export default function recurrentExport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECURRENT_EXPORT_REQUEST_CREATE':
    case 'RECURRENT_EXPORT_REQUEST_UPDATE':
    case 'RECURRENT_EXPORT_REQUEST_DELETE':
    case 'RECURRENT_EXPORT_ONDEMAND_REQUEST_CREATE':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case 'RECURRENT_EXPORT_SUCCESS':
    case 'RECURRENT_EXPORT_FAILURE':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
}
