import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaFileArchive, FaArchive, FaRegFileArchive } from 'react-icons/fa';
import api from '../../../services/api';
import { updateRequestCreate } from '../../../store/modules/update/actions';
import history from '../../../history';

import {
  // eslint-disable-next-line import/named
  InputDateTimePickerNoForm,
  InputCheckBoxNoForm,
} from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import { formatBytes } from '../../../utils/functions';

import { ContainerButtons } from './styles';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';
import { updateReleasedRequestCreate } from '../../../store/modules/updatereleased/actions';

const UpdateSchema = Yup.object().shape({
  update_id: Yup.number().required('O Software é obrigatório'),
});

function Update() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.updateReleased.loading);
  const [fileInfoExe, setFileInfoExe] = useState(null);
  const [fileInfoScript, setFileInfoScript] = useState(null);
  const [fileInfoAditional, setFileInfoAditional] = useState(null);
  const [updates, setUpdates] = useState([]);

  const [business, setBusiness] = useState([]);

  const { id } = useParams;

  function selecionarEstIndex(acao, index) {
    const novoArray = [...business];

    novoArray[index].sel = acao;
    if (!acao) {
      novoArray[index].scheduling = '';
    }

    setBusiness(novoArray);
  }

  function selecionarAgendamentoIndex(scheduling, index) {
    const novoArray = [...business];

    novoArray[index].scheduling = scheduling;

    setBusiness(novoArray);
  }
  useEffect(() => {
    const getUpdates = async () => {
      try {
        const response = await api.get(`updates`);
        if (response.data) {
          setUpdates(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getBusiness();
    getUpdates();
  }, []);

  async function handleSubmit({ update_id }) {
    const novoArray = [...business];
    const data = [];

    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-plusplus
    for (let i = 0; i < novoArray.length; i++) {
      if (novoArray[i].sel === true) {
        data.push({
          update_id,
          business_id: novoArray[i].id,
          date_scheduled: novoArray[i].scheduling
            ? novoArray[i].scheduling
            : new Date(),
        });
      }
    }

    console.log(data);

    if (data.length === 0) {
      toast.warn('Nenhuma empresa foi selecionada para ser atualizada');
    } else dispath(updateReleasedRequestCreate(data));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Liberação de Atualização">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          update_id: '',
        }}
        validationSchema={UpdateSchema}
      >
        {(props) => (
          <StyledForm>
            <Label text="Selecione uma Atualização" />
            <Field name="update_id" component={Select} type="text">
              <option value="" disabled>
                Selecione uma Atualização
              </option>
              {updates.map((up) => (
                <option key={up.id} value={up.id}>
                  {`Sistema ${up.software.software_name} Versão ${up.version}`}
                </option>
              ))}
            </Field>
            <Table title="Empresas cadastradas">
              <TableHeader>
                <TableCellHeader>#</TableCellHeader>
                <TableCellHeader>Sel</TableCellHeader>
                <TableCellHeader>Agendamento</TableCellHeader>
                <TableCellHeader>Fantasia</TableCellHeader>
                <TableCellHeader>Razão social</TableCellHeader>
                <TableCellHeader>Cidade</TableCellHeader>
                <TableCellHeader>Grupo</TableCellHeader>
                <TableCellHeader>Software</TableCellHeader>
              </TableHeader>
              <tbody>
                {business.map((company, index) => {
                  business[index].business_id = company.id;
                  /*
                  business[index].due === ''
                    ? (business[index].dueOld = moment(company.license.due).format(
                        'YYYY-MM-DD'
                      ))
                    : // eslint-disable-next-line no-self-assign
                      (business[index].due = business[index].due);
                  */
                  return (
                    <TableRow key={company.id}>
                      <TableCell>{company.id}</TableCell>

                      <TableCell>
                        <InputCheckBoxNoForm
                          noError
                          placeholder=""
                          type="checkbox"
                          name="selected"
                          checked={company.sel}
                          onChange={(e) =>
                            selecionarEstIndex(e.target.checked, index)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputDateTimePickerNoForm
                          noError
                          placeholder=""
                          name={`a${index}`}
                          selected={company.scheduling}
                          onChange={(e) => selecionarAgendamentoIndex(e, index)}
                          disabled={!company.sel}
                        />
                      </TableCell>

                      <TableCell>{company.fantasy_name}</TableCell>
                      <TableCell>{company.company_name}</TableCell>

                      <TableCell>
                        {`${company.city.name}-${company.city.uf}`}
                      </TableCell>
                      <TableCell>
                        {`${company.group ? company.group.description : ''}`}
                      </TableCell>

                      <TableCell>
                        {/* {company.software.software_name} */}
                        {`${
                          company.software ? company.software.software_name : ''
                        }`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/update-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Update;
