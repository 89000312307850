import styled from 'styled-components';
import InputMask from 'react-input-mask';
import IntlCurrencyInput from 'react-intl-currency-input';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes, addMinutes } from 'date-fns';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: ${(props) => (props.noError ? '4px' : '25px')};
  margin-top: ${({ noError }) => (noError ? '4px' : '')};
  flex-direction: column;
`;

export const ContainerInputFile = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 230px;
  border: 2px dashed;

  border-color: ${(props) => props.theme.colors.shade};
  border-radius: 4px;
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  svg {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
  }
`;

export const StyledInput = styled.input`
  padding: 9px 15px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &:disabled {
    background-color: ${(props) => darken(0.03, props.theme.colors.bright)};
  }
  //height: 52px;
  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const StyledTextArea = styled.textarea`
  padding: 9px 15px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &:disabled {
    background-color: ${(props) => darken(0.03, props.theme.colors.bright)};
  }
  //height: 52px;
  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const StyledInputFile = styled(StyledInput)`
  border-width: 2px;
  border-style: dashed;
  height: 60px;
  margin: auto;
  text-align: center;
  display: none;
  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.bright};
    background-color: ${(props) => props.theme.colors.secondary};
    border-width: 0px;
    border-radius: 4px;
    text-align: center;
    margin: auto;
  }
`;

export const StyledInputMask = styled(InputMask)`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const StyledInputCurrency = styled(IntlCurrencyInput)`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;
export const StyledInputDatePicker = styled(DatePicker).attrs((props) => ({
  dateFormat: 'dd/MM/yyyy',
  todayButton: 'Hoje',
  selectsRange: props.range ? props.range : false,
}))`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;

  ${(props) => {
    if (props.compact) {
      return `
      padding: 4px 15px;
      `;
    }

    return '';
  }};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &:disabled {
    background-color: ${(props) => darken(0.03, props.theme.colors.bright)};
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const StyledInputDateTimePicker = styled(DatePicker).attrs((props) => ({
  dateFormat: 'dd/MM/yyyy HH:mm',
  todayButton: 'Hoje',
  showTimeSelect: true,
  timeIntervals: 5,
  filterTime: props.filterTime ? props.filterTime : null,
  minTime: props.minTime ? props.minTime : null,
  maxTime: props.maxTime ? props.maxTime : null,
  minDate: props.minDate ? props.minDate : null,
  autoComplete: 'off',
}))`
  padding: ${(props) => (props.inputType === 'file' ? '5px 8px' : '10px 15px')};
  width: 100%;
  max-width: 150px;
  background-color: ${(props) => props.theme.colors.bright};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  max-height: 38px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.shade};
  //box-shadow: 2px 2px 15px ${(props) => props.theme.colors.shade};
  box-shadow: ${({ theme }) => {
    if (theme.name === 'dark') return '';

    return '';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  &::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &:hover {
    //box-shadow: 0px 0px 4px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus:hover {
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:focus {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }
  &:active {
    //box-shadow: 0px 0px 8px ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::-webkit-file-upload-button {
    padding: 8px;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-width: 0px;
    border-radius: 4px;
  }
`;

export const Error = styled.div`
  color: ${(props) => props.theme.colors.error};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '18px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 15px;
  padding-left: 0px;
  font-weight: 400;
  font-size: 12px;
`;

export const StyledInputCheckBox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  width: 25px;
  height: 25px;
  border-width: 1px;
  &:checked {
    background-color: ${(props) => props.theme.colors.accent};
  }
  ${(props) => {
    if (props.compact) {
      return `
        width: 20px;
        height: 20px;
      `;
    }

    return '';
  }};
`;
