import React from 'react';

import { Container, Text } from './styles';

function Label({ text, horizontal, ...props }) {
  return (
    <Container horizontal={horizontal}>
      <Text {...props}>{text}</Text>
    </Container>
  );
}

export default Label;
