import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { showModal } from '../../../store/modules/modal/actions';
import history from '../../../history';
import api from '../../../services/api';
import { updateReleasedRequestDelete } from '../../../store/modules/updatereleased/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  PaginationComponent,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions, Etiqueta } from './styles';

function ListUpdate() {
  const [updatesReleaseds, setupdatesReleaseds] = useState([]);
  const [dataOriSearch, setDataOriSearch] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.updateReleased.loading);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    const getupdatesReleases = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('updatesreleaseds');
        if (response.data) {
          setupdatesReleaseds(response.data);
          setDataOriSearch(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getupdatesReleases();
  }, [loading]);

  async function changePage() {
    try {
      setLoadingTable(true);
      const response = await api.get('updatesreleaseds');
      if (response.data) {
        setupdatesReleaseds(response.data);
        setDataOriSearch(response.data);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }
  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(updateReleasedRequestDelete(id));
      },
      loading,
    });
  }

  async function handleDetails(id) {
    dispath(showModal('LIST_LOGRELEASEDS', { update_releaseds_id: id }));
  }
  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setupdatesReleaseds(resultadoFiltrado);
  }
  return (
    <ContainerList>
      <ModalConfirm />
      <Table
        title="Atualizações Liberadas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Servidor</TableCellHeader>
          <TableCellHeader>Database</TableCellHeader>
          <TableCellHeader>Software</TableCellHeader>
          <TableCellHeader>Versão</TableCellHeader>
          <TableCellHeader>Dt. Liberação</TableCellHeader>
          <TableCellHeader>Dt. Agendamento</TableCellHeader>
          <TableCellHeader>Situacao</TableCellHeader>
          <TableCellHeader>Dt. Execução</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {updatesReleaseds.map((update) => (
            <TableRow key={update.id}>
              <TableCell>{update.id}</TableCell>
              <TableCell>{update.serverDatabase.server.label}</TableCell>
              <TableCell>{update.serverDatabase.database}</TableCell>
              <TableCell>{update.update.software.software_name}</TableCell>
              <TableCell>{update.update.version}</TableCell>
              <TableCell>
                {moment(update.date_release).format('DD/MM/YYYY HH:mm z')}
              </TableCell>
              <TableCell>
                {moment(update.date_scheduled).format('DD/MM/YYYY HH:mm z')}
              </TableCell>
              <TableCell>
                <Etiqueta situacao={update.stateLabel}>
                  {update.stateLabel}
                </Etiqueta>
              </TableCell>
              <TableCell>
                {update.date_updated &&
                  moment(update.date_updated).format('DD/MM/YYYY HH:mm z')}
              </TableCell>

              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    style={{ display: 'none' }}
                    onClick={() => {
                      history.push(`update-released-edit/${update.id}`);
                    }}
                  >
                    <FaEdit size={15} color="#c3c3c3" />
                  </ButtonIcon>
                  <ButtonIcon onClick={() => handleDelete(update.id)}>
                    <FaTrashAlt size={15} color="#c3c3c3" />
                  </ButtonIcon>
                  <ButtonIcon onClick={() => handleDetails(update.id)}>
                    <FaEllipsisH size={15} color="#c3c3c3" />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {/* <PaginationComponent
        itemsPerPage={10}
        totalItems={100}
        paginate={changePage}
      /> */}
    </ContainerList>
  );
}

export default ListUpdate;
