import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { FiEye, FiDownload, FiFile, FiPlus } from 'react-icons/fi';

import 'react-tooltip/dist/react-tooltip.css';

import { FaUser, FaUserTie } from 'react-icons/fa';
import { ticketDiscussionRequestCreate } from '../../../store/modules/ticketdiscussion/actions';
import history from '../../../history';
import api from '../../../services/api';
import MyEditor from '../../../components/ReactQuill/MyReactQuill';

import StarRating from '../../../components/StartRating';
import Input, { InputNoForm, InputFile } from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard, {
  ContainerCardDiscussion,
  ContainerCardArchives,
  ContainerCardArchivesExample,
  ContainerCardNote,
  ContainerCardAction,
  ContainerCardActionAdm,
  ContainerCardReview,
} from '../../../components/ContainerCard';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';

import {
  ContainerButtons,
  ArchiveContent,
  LabelArchive,
  TitleDivider,
  Divider,
  CardFile,
  ContainerInputFile,
  Scroll,
  DivInput,
  ContainerDetTicketsUp,
} from './styles';

moment.locale('pt-br');

const TicketDiscussionSchema = Yup.object().shape({
  message: Yup.string().required('A Mensagem é obrigatória'),
  ticket_id: Yup.number().required('O ID do Ticket é obrigatório'),
  support_response: Yup.string().required(
    'O tipo support_response é obrigatória'
  ),
});

function Create() {
  const dispath = useDispatch();
  const [clearEditor, setClearEditor] = useState(false);

  const loading = useSelector((state) => state.ticketDiscussion.loading);
  const [tickets, setTickets] = useState([]);
  const [ticketReviews, setTicketReviews] = useState([]);
  const [ticketfiles, setTicketFiles] = useState([]);
  const { id } = useParams();

  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [reloadScreen, setReloadScreen] = useState(true);
  const listRef = useRef();
  const [file, setFile] = useState([]);
  const fileInputRef = useRef(null);

  function handleDivClick() {
    fileInputRef.current.click();
  }

  async function handleFileChange(event) {
    const { files } = event.target;
    if (!files.length) return;

    const Arquive = files[0];
    if (Arquive.size > 1073741824) {
      toast.warn('Selecione um arquivo de até 1GB.');
      return;
    }

    const data = new FormData();
    data.append('file', Arquive);

    try {
      const responseFile = await api.post('filedocuments', data);

      await api.post('ticketfiles', {
        ticket_id: id,
        file_id: responseFile.data.id,
      });

      toast.success('Arquivo Adicionado com Sucesso');
      setReloadScreen((prevState) => !prevState);
    } catch (error) {
      // Handle errors here, such as displaying a notification
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fileInputRef.current.click();
    }
  };

  async function ReturnTicket() {
    modalConfirm({
      title: 'Reabrir Conversa',
      message: 'Deseja realmente voltar ao atendimento?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'ARS',
          status: 'O',
        });
        // history.push('/ticket-manager');
        window.location.reload();
      },
      loading,
    });
  }

  async function UserFinalizeTicket() {
    modalConfirm({
      title: 'Finalização do Ticket',
      message: 'Deseja realmente finalizar este ticket?',
      onConfirm: async () => {
        await api.put(`/tickets/${id}`, {
          situation: 'AAT',
          status: 'F',
        });
        // history.push('/ticket-manager');
        toast.success('Ticket Finalizado com Sucesso');
        window.location.reload();
      },
      loading,
    });
  }

  async function FinalizaAvaliation(serviceRating, resolutionRating, comment) {
    try {
      let userId;
      const persistedData = localStorage.getItem('persist:sisfoco');

      if (persistedData) {
        const parsedData = JSON.parse(persistedData);

        if (parsedData.user) {
          const userData = JSON.parse(parsedData.user);
          userId = userData.user.id;
        }
      }

      if (!userId) {
        throw new Error('User ID não encontrado');
      }

      // Atualiza a situação e o status do ticket
      await api.put(`/tickets/${id}`, {
        situation: 'RCS',
        status: 'F',
      });

      // Salva Avaliações do usuário
      await api.post(`/ticketReviews/`, {
        servicenote: serviceRating,
        resolutionnote: resolutionRating,
        description: comment,
        user_id: userId,
        ticket_id: id, // Certifique-se de ter o ID do ticket disponível
      });

      // Redireciona para a página do gerenciador de tickets
      history.push('/ticket-list');

      // Exibe uma mensagem de sucesso
      toast.success('Ticket Finalizado com Sucesso');
    } catch (error) {
      // Lida com erros, exibindo uma mensagem de erro
      toast.error('Erro ao finalizar o ticket. Tente novamente.');
    }
  }
  // Função para rolar a lista para baixo
  const scrollToBottomHandler = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  const onDrop = useCallback(
    (acceptedFile) => {
      setFile([...file, ...acceptedFile]);
    },
    [file]
  );

  // Efeito para rolar para baixo sempre que a lista é atualizada
  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottomHandler();
    }
  }, [scrollToBottom]);

  // Efeito para rolar para o topo quando a tela é atualizada
  useEffect(
    () => {
      setScrollToBottom(false);

      setTimeout(() => {
        setScrollToBottom(true);
      }, 0);
    },
    [
      /* dependências para a atualização da tela */
    ]
  );

  useEffect(() => {
    const getTicket = async () => {
      try {
        const response = await api.get(`tickets/${id}`);
        if (response.data) {
          setTickets(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getTicketReviews = async () => {
      try {
        const response = await api.get(`reviewsByTicketId/${id}`);
        if (response.data) {
          setTicketReviews(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getTicketFile = async () => {
      try {
        const response = await api.get(`filesbyticketid/${id}`);
        if (response.data) {
          setTicketFiles(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getTicket();
    getTicketFile();
    getTicketReviews();
  }, [id, loading, reloadScreen]);

  async function handleSubmit(
    { ticket_id, message, support_response },
    { setFieldValue }
  ) {
    dispath(
      ticketDiscussionRequestCreate(ticket_id, message, support_response)
    );

    setScrollToBottom(false);

    setTimeout(() => {
      setScrollToBottom(true);
    }, 200);

    setClearEditor(true);
    setFieldValue('message', '');
    setTimeout(() => {
      setClearEditor(false);
    }, 400);
  }

  const getSubtitle = (situation) => {
    switch (situation) {
      case 'AIA':
        return 'Aguardando Inicio do Atendimento';
      case 'AIC':
        return 'Aguardando Interação do Cliente';
      case 'ARS':
        return 'Aguardando Resposta do Suporte';
      case 'EAT':
        return 'Em Análise Técnica';
      case 'AFC':
        return 'Aguardando Finalizacao do Cliente';
      case 'AAT':
        return 'Aguardando Avaliação Ticket';
      case 'RCS':
        return 'Resolvido com Sucesso';
      case 'FSR':
        return 'Fechado sem Resolução';
      default:
        return situation;
    }
  };

  const divStyle = {
    flex: 1,
    maxWidth:
      tickets.situation === 'AFC' || tickets.situation === 'AAT'
        ? '600px'
        : '100%', // Ajuste o tamanho máximo conforme necessário
    margin:
      tickets.situation === 'AFC' || tickets.situation === 'AAT'
        ? '0 auto'
        : '0', // Centraliza a div se a situação for 'AFC' ou 'AAT'
    marginTop:
      tickets.situation === 'AFC' || tickets.situation === 'AAT' ? '20px' : '0', // Adiciona um espaçamento acima se a situação for 'AFC' ou 'AAT'
  };

  // Verifica se há dados antes de renderizar
  const hasReviews = ticketReviews.length > 0;
  const review = hasReviews ? ticketReviews[0] : {};

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div id="DivFixo" style={{ width: '29%', paddingRight: 10 }}>
        <div>
          <ContainerCardDiscussion
            title={`Código: #${tickets.id}`}
            subtitle={getSubtitle(tickets.situation)}
          >
            <DivInput>
              <Label text="Cliente - Proprietário do Ticket" />
              <InputNoForm
                placeholder=""
                type="text"
                name="proprietario"
                value={tickets.createdBy && tickets.createdBy.name}
                readOnly
              />
            </DivInput>
            <DivInput>
              <Label text="Ticket Criado" />
              <InputNoForm
                placeholder=""
                type="text"
                name="criado"
                value={moment(tickets.created_at).format('DD/MM/YYYY HH:mm z')}
                readOnly
              />
            </DivInput>
            <DivInput>
              <Label text="Responsável" />
              <InputNoForm
                placeholder=""
                type="text"
                name="responsavel"
                value={tickets.takenBy && tickets.takenBy.name}
                readOnly
              />
            </DivInput>
            <Label text="Ultima Atualização" />
            <InputNoForm
              placeholder=""
              type="text"
              name="criado"
              value={moment(tickets.updated_at).fromNow()}
              readOnly
            />
          </ContainerCardDiscussion>
        </div>
        <div>
          <ContainerCard title="Arquivos">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {ticketfiles.map((files) => (
                <ContainerCardArchives>
                  <ArchiveContent>
                    <div
                      style={{
                        maxWidth: 'calc(100% - 30px)',
                        overflow: 'hidden',
                      }}
                    >
                      <h1
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {files.file.file_name}
                      </h1>
                    </div>
                    <div style={{ marginLeft: 'Auto' }}>
                      <Link
                        to={files.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiEye
                          size="25px"
                          color="#696969"
                          style={{ marginLeft: '5px' }}
                        />
                      </Link>
                    </div>
                  </ArchiveContent>
                </ContainerCardArchives>
              ))}
              <ContainerCardArchivesExample>
                <ArchiveContent>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    aria-label="Remover arquivo"
                    onClick={handleDivClick}
                    onKeyDown={handleKeyPress}
                    role="button"
                    tabIndex="0"
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <FiPlus
                      size="25px"
                      color="#696969"
                      style={{ alignSelf: 'center' }}
                    />
                  </div>
                </ArchiveContent>
              </ContainerCardArchivesExample>
            </div>
          </ContainerCard>
        </div>
      </div>
      <div style={{ width: '100%', maxHeight: '100%', overflowY: 'auto' }}>
        <div style={{ flexDirection: 'column', flex: 1 }}>
          <div
            style={{
              flex: 1,
              marginBottom: '15px',
            }}
          >
            <ContainerCard title={`${tickets.subject}`}>
              <div dangerouslySetInnerHTML={{ __html: tickets.message }} />
            </ContainerCard>
          </div>

          <div style={{ maxHeight: '400px', overflowY: 'auto' }} ref={listRef}>
            {tickets.discussions && tickets.discussions.length > 0 ? (
              <>
                {!tickets.discussions.some(
                  (discussion) => discussion.support_response === 'S'
                ) && (
                  <div
                    style={{
                      flex: 1,
                      marginBottom: '5px',
                    }}
                  >
                    <ContainerCardNote
                      title="Nota"
                      subtitle="Seu ticket foi criado, aguarde até que um de nossos técnicos inicie o atendimento!"
                    />
                  </div>
                )}
                {tickets.discussions &&
                  tickets.discussions.map((discussions) => (
                    <div
                      key={discussions.id}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start', // Para alinhar os itens ao topo da div
                        marginBottom: '10px',
                      }}
                    >
                      {discussions.support_response === 'S' && (
                        <div
                          style={{
                            marginRight: '1px',
                            fontSize: '40px',
                          }}
                        >
                          {/* Adicione aqui o ícone de suporte */}
                          <FaUserTie color="#000C66" />
                        </div>
                      )}

                      <div
                        style={{
                          flex: 1,
                          marginLeft:
                            discussions.support_response === 'S'
                              ? '5px'
                              : '100px',
                          marginRight:
                            discussions.support_response === 'S'
                              ? '100px'
                              : '5px',
                        }}
                      >
                        <ContainerCardDiscussion
                          title={
                            discussions.createdBy && discussions.createdBy.name
                          }
                          subtitle={moment(discussions.created_at).fromNow()}
                          alignRight={discussions.support_response === 'N'}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: discussions.message,
                            }}
                          />
                        </ContainerCardDiscussion>
                      </div>

                      {discussions.support_response === 'N' && (
                        <div style={{ marginLeft: '1px', fontSize: '40px' }}>
                          {/* Adicione aqui o ícone de usuário */}
                          <FaUser color="#696969" />
                        </div>
                      )}
                    </div>
                  ))}
              </>
            ) : (
              <div
                style={{
                  flex: 1,
                  marginBottom: '5px',
                }}
              >
                <ContainerCardNote
                  title="Nota"
                  subtitle="Seu ticket foi criado, aguarde até que um de nossos técnicos inicie o atendimento!"
                />
              </div>
            )}
          </div>

          <div style={divStyle}>
            {(() => {
              if (tickets.situation === 'AFC') {
                // Bloco para a situação 'AFC'
                return (
                  <ContainerCardAction
                    title="Ticket em Finalização"
                    message="O Suporte marcou o ticket como resolvido, deseja finalizar o atendimento?"
                    onBack={ReturnTicket}
                    onFinish={UserFinalizeTicket}
                  />
                );
              }

              if (tickets.situation === 'AAT') {
                // Bloco CHAMAR MODAL DE AVALIAÇÂO
                return (
                  <ContainerCardReview
                    onSubmit={(serviceRating, resolutionRating, comment) => {
                      // Faça algo com as avaliações e o comentário, como enviar para um servidor
                      FinalizaAvaliation(
                        serviceRating,
                        resolutionRating,
                        comment
                      );
                    }}
                  />
                );
              }

              if (tickets.situation !== 'RCS' && tickets.situation !== 'FSR') {
                // Bloco para situações diferentes de 'RCS', 'FSR' e 'AFC'
                return (
                  <ContainerCard title="Mensagem">
                    <Formik
                      onSubmit={handleSubmit}
                      enableReinitialize
                      initialValues={{
                        ticket_id: tickets.id,
                        support_response: 'S',
                        message: '',
                      }}
                      validationSchema={TicketDiscussionSchema}
                    >
                      {(props) => (
                        <StyledForm>
                          <MyEditor
                            id="MyEditor"
                            onChange={(content) =>
                              props.setFieldValue('message', content)
                            }
                            clear={clearEditor}
                          />
                          <ContainerButtons>
                            <Button
                              size="contained"
                              type="submit"
                              text={loading ? 'Aguarde...' : 'Enviar'}
                            />
                          </ContainerButtons>
                        </StyledForm>
                      )}
                    </Formik>
                  </ContainerCard>
                );
              }

              // Bloco para situação 'RCS', 'FSR' e qualquer outra não especificada acima
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ContainerDetTicketsUp
                        style={{
                          width: '100%', // Define a largura como 50% da tela
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ width: '48%', textAlign: 'center' }}>
                            <p
                              style={{
                                fontSize: '1.5rem',
                                margin: '0 0 10px 0',
                              }}
                            >
                              Atendimento
                            </p>
                            <p style={{ fontSize: '4rem', margin: 0 }}>
                              {hasReviews
                                ? Number(review.servicenote).toFixed(2)
                                : 'N/A'}
                            </p>
                            <StarRating
                              rating={
                                hasReviews ? Number(review.servicenote) : 0
                              }
                              editable={false}
                              size="large"
                            />
                          </div>
                          <div style={{ width: '48%', textAlign: 'center' }}>
                            <p
                              style={{
                                fontSize: '1.5rem',
                                margin: '0 0 10px 0',
                              }}
                            >
                              Satisfação
                            </p>
                            <p style={{ fontSize: '4rem', margin: 0 }}>
                              {hasReviews
                                ? Number(review.resolutionnote).toFixed(2)
                                : 'N/A'}
                            </p>
                            <StarRating
                              rating={
                                hasReviews ? Number(review.resolutionnote) : 0
                              }
                              editable={false}
                              size="large"
                            />
                          </div>
                        </div>
                      </ContainerDetTicketsUp>
                    </div>

                    <button
                      key={review.id}
                      type="button"
                      style={{
                        display: 'flex',
                        backgroundColor: '#fff',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        alignItems: 'flex-start', // Alinhamento vertical dos itens
                        minHeight: '80px', // Altura mínima para os comentários
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        outline: 'none',
                        width: '95%', // Define a largura do botão para combinar com o container acima
                        marginTop: '20px', // Espaço entre o container e o botão
                      }}
                      onClick={() => {}}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = 'scale(1.05)'; // Aumenta o tamanho ao passar o mouse
                        e.currentTarget.style.boxShadow =
                          '0 4px 8px rgba(0, 0, 0, 0.2)'; // Aumenta a sombra
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = 'scale(1)'; // Volta ao tamanho original
                        e.currentTarget.style.boxShadow =
                          '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                      }}
                      onFocus={(e) => {
                        e.currentTarget.style.transform = 'scale(1.05)'; // Efeito semelhante ao passar o mouse
                        e.currentTarget.style.boxShadow =
                          '0 4px 8px rgba(0, 0, 0, 0.2)'; // Sombra semelhante ao passar o mouse
                      }}
                      onBlur={(e) => {
                        e.currentTarget.style.transform = 'scale(1)'; // Remove o efeito quando o foco é perdido
                        e.currentTarget.style.boxShadow =
                          '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.currentTarget.click(); // Simula o clique com a tecla Enter ou Espaço
                        }
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            borderBottom: '1px solid #ddd',
                            marginBottom: '10px',
                          }}
                        />
                        <div
                          style={{
                            fontSize: '1.2rem',
                            color: '#333',
                            fontStyle: 'italic',
                            padding: '0 10px',
                            maxWidth: '100%',
                            wordWrap: 'break-word',
                            textAlign: 'justify', // Justifica o texto
                            whiteSpace: 'pre-wrap', // Mantém as quebras de linha e quebra o texto conforme o tamanho do contêiner
                            lineHeight: '1.2em', // Altura da linha
                            overflow: 'visible', // Garante que o texto não seja ocultado
                          }}
                        >
                          &quot;
                          {review.description || 'Usuário não comentou!'}
                          &quot;
                        </div>
                      </div>
                    </button>
                  </div>
                  <div style={{ flex: 1, marginBottom: '5px' }}>
                    <ContainerCardNote
                      title="Nota"
                      subtitle="Este ticket já foi fechado."
                    />
                  </div>
                </div>
              );
            })()}
          </div>
        </div>
      </div>
      <ModalConfirm />
    </div>
  );
}

export default Create;
