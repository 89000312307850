import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaTrashAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus,
} from 'react-icons/fa';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { MdFileDownload, MdFileDownloadOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import StyledForm from '../../../components/Form/StyledForm';
import history from '../../../history';
import api from '../../../services/api';
import ContainerCard from '../../../components/ContainerCard';
import Button, { SearchButton } from '../../../components/Button';
import { recurrentExportRequestDelete } from '../../../store/modules/recurrentexport/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';
import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';
import Label from '../../../components/Label';
import { InputNoForm } from '../../../components/Input';
import { SelectReactNoForm } from '../../../components/SelectReaact';

const HistoricSchema = Yup.object().shape({});

function SearchDfe() {
  const [nfes, setNfes] = useState([]);
  const [nfesOri, setNfesOri] = useState([]);
  const [business, setBusiness] = useState([]);
  const [businessQuery, setBusinessQuery] = useState([]);
  const [chaveAcesso, setChaveAcesso] = useState('');

  const dispath = useDispatch();
  const loading = useSelector((state) => state.recurrentexport.loading);
  const [loadingTable, setLoadingTable] = useState(false);
  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getBusiness();
  }, [loading]);

  async function handleDowloadxml(chave) {
    const response = await api.get(`buscarDfeXML/${chave}`);

    // Obtendo o conteúdo XML da resposta da API
    const xmlContent = response.data.xml;

    // Criando um objeto Blob com o conteúdo XML
    const blob = new Blob([xmlContent], { type: 'application/xml' });

    // Criando uma URL para o Blob
    const url = URL.createObjectURL(blob);

    // Criando um link para download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${chave}-nfe.xml`;
    document.body.appendChild(a);
    a.click();

    // Limpando o URL do Blob
    URL.revokeObjectURL(url);
    a.remove();
    return null;
  }
  async function handleSubmit() {
    if (businessQuery.length === 0 && chaveAcesso === '') {
      toast.warn(
        'Selecione uma empresa ou digite uma chave de acesso para pesquisar.'
      );
      return;
    }
    try {
      const response = await api.get(
        `/nfestoragesearch?business_id=${
          businessQuery.length === 0 ? 0 : businessQuery
        }&chNFe=${chaveAcesso}`
      );
      if (response.data) {
        setNfes(response.data);
        setNfesOri(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = nfesOri.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setNfes(resultadoFiltrado);
  }

  return (
    <ContainerList>
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{}}>
        {(props) => (
          <StyledForm autocomplete="off">
            <ContainerCard title="Pesquisa - Filtros">
              <div style={{ display: 'flex', width: '100%' }}>
                <div id="0" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Nome da Empresa" />
                  <SelectReactNoForm
                    value={(businessQuery, business.company_name)}
                    placeholder="Selecione uma Empresa"
                    onChange={(e) => {
                      if (e) {
                        setBusinessQuery(e.value);
                      } else {
                        setBusinessQuery(null);
                        // setNfes([]);
                      }
                    }}
                    options={business.map((busines) => ({
                      label: busines.company_name,
                      value: busines.id,
                    }))}
                    name="idBusiness"
                  />
                </div>
                <div id="2" style={{ flex: '1', marginRight: '10px' }}>
                  <Label text="Chave de acesso específica" />
                  <InputNoForm
                    name="chaveacesso"
                    onChange={(e) => setChaveAcesso(e.target.value)}
                  />
                </div>
                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Pesquisar'}
                />
              </div>
            </ContainerCard>
          </StyledForm>
        )}
      </Formik>

      <Table
        title="Notas encontradas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Emitente</TableCellHeader>
          <TableCellHeader>Valor</TableCellHeader>
          <TableCellHeader>Dt. Emissão</TableCellHeader>
          <TableCellHeader>Situação</TableCellHeader>
          <TableCellHeader>Chave de Acesso</TableCellHeader>
          <TableCellHeader>Ações</TableCellHeader>
        </TableHeader>
        <tbody>
          {nfes.map((nfe, index) => (
            <TableRow key={nfe.id}>
              <TableCell>{nfe.id}</TableCell>
              <TableCell>{nfe.xNome}</TableCell>
              <TableCell>{nfe.vNF}</TableCell>
              <TableCell>
                {moment(nfe.dhEmi).format('DD/MM/YYYY HH:mm z')}
              </TableCell>
              <TableCell>
                {(() => {
                  // eslint-disable-next-line default-case
                  switch (nfe.cSitNFe) {
                    case '1':
                      return 'Normal';
                    case '2':
                      return 'Denegada';
                    case '3':
                      return 'Cancelada';
                    default:
                      return '';
                  }
                })()}
              </TableCell>
              <TableCell>{nfe.chNFe}</TableCell>
              <TableCell>
                <ContainerActions>
                  <ButtonIcon
                    data-tooltip-id="Editar"
                    onClick={() => {
                      handleDowloadxml(nfe.chNFe);
                    }}
                  >
                    <MdFileDownload size={15} color="#c3c3c3" />
                    <ReactTooltip />
                  </ButtonIcon>
                </ContainerActions>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default SearchDfe;
