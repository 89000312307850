import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { toast } from 'react-toastify';

import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { businessModuleRequestCreate } from '../../../store/modules/businessmodule/actions';
import history from '../../../history';
import api from '../../../services/api';

import { InputCheckBoxNoForm } from '../../../components/Input';

import { ContainerInputs, ContainerActions, ContainerButtons } from './styles';

import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';

import { SelectReactNoForm } from '../../../components/SelectReaact';

import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';

import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';

const LicenseSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
});

function Create() {
  const [licenses, setLicenses] = useState([]);

  const [groupQuery, setGroupQuery] = useState([]);
  const [groups, setGroups] = useState([]);

  const [softwareQuery, setSoftwareQuery] = useState([]);
  const [softwares, setSoftwares] = useState([]);

  const [businessQuery, setBusinessQuery] = useState([]);
  const [business, setBusiness] = useState([]);

  const [table, setTable] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.license.loading);

  async function handleSubmit() {
    const tableSelected = table.filter((tableSe) => tableSe.sel === true);

    // console.log(tableSelected.length)

    if (tableSelected.length === 0) {
      toast.info('Não foi selecionado nenhuma empresa!');
    } else {
      for (let i = 0; i < tableSelected.length; i += 1) {
        let moduleSel = [];
        moduleSel = tableSelected[i].software.softModules.filter(
          (module) => module.sel === true
        );

        tableSelected[i].moduleSelected = moduleSel;
      }

      dispath(businessModuleRequestCreate(tableSelected));
    }
  }

  function handleHideSub(index) {
    const aux = table;

    aux[index].hideSub = !aux[index].hideSub;

    setTable([...aux]);
  }

  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getBusiness();
    getGroup();
    getSoftware();
  }, []);

  async function searchCompany() {
    try {
      let uri = '';
      if (businessQuery > 0) {
        uri += `&business_id=${businessQuery}`;
      }
      if (groupQuery > 0) {
        uri += `&group_id=${groupQuery}`;
      }
      if (softwareQuery > 0) {
        uri += `&software_id=${softwareQuery}`;
      }

      const response = await api.get(`searchlicense/?query=1${uri}`);

      if (response.data) {
        response.data.data.forEach((element) => {
          element.hideSub = true;
        });

        setLicenses(response.data.data);

        const tableData = response.data.data;

        for (let i = 0; i < tableData.length; i += 1) {
          if (tableData[i].software) {
            for (
              let ii = 0;
              ii < tableData[i].software.softModules.length;
              ii += 1
            ) {
              const haveModule = tableData[i].businessModule.filter(
                (module) =>
                  module.module_id === tableData[i].software.softModules[ii].id
              );
              tableData[i].software.softModules[ii].sel = haveModule.length > 0;
            }
          }
        }

        setTable(tableData);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <ContainerCard title="Gerenciar Licença da Empresa">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={
          {
            // description: 'Teste',
          }
        }
        // validationSchema={LicenseSchema}
      >
        {(props) => (
          <StyledForm autocomplete="off">
            <ContainerInputs>
              <span>
                <Label text="Nome da Empresa" />
                <SelectReactNoForm
                  value={(businessQuery, business.company_name)}
                  placeholder="Selecione uma Empresa"
                  onChange={(e) => {
                    if (e) {
                      setBusinessQuery(e.value);
                    } else {
                      setBusinessQuery(null);
                    }
                  }}
                  options={business.map((busines) => ({
                    label: busines.company_name,
                    value: busines.id,
                  }))}
                  name="idBusiness"
                />
              </span>

              <span>
                <Label text="Software" />
                <SelectReactNoForm
                  value={(softwareQuery, softwares.software_name)}
                  placeholder="Selecione um Produto"
                  onChange={(e) => {
                    if (e) {
                      setSoftwareQuery(e.value);
                    } else {
                      setSoftwareQuery(null);
                    }
                  }}
                  options={softwares.map((software) => ({
                    label: software.software_name,
                    value: software.id,
                  }))}
                  name="idSoftware"
                />
              </span>
            </ContainerInputs>

            <Label text="Grupo da Empresa" />
            <SelectReactNoForm
              value={(groupQuery, groups.description)}
              placeholder="Selecione um Grupo"
              onChange={(e) => {
                if (e) {
                  setGroupQuery(e.value);
                } else {
                  setGroupQuery(null);
                }
              }}
              options={groups.map((group) => ({
                label: group.description,
                value: group.id,
              }))}
              name="idGroup"
            />

            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Pesquisar"
                type="button"
                onClick={() => {
                  searchCompany();
                }}
              />
            </ContainerButtons>

            <Table title="Empresas cadastradas">
              <TableHeader>
                <TableCellHeader>#</TableCellHeader>
                <TableCellHeader>Sel</TableCellHeader>
                <TableCellHeader>Fantasia</TableCellHeader>
                <TableCellHeader>Razão social</TableCellHeader>
                <TableCellHeader>Cidade</TableCellHeader>
                <TableCellHeader>Grupo</TableCellHeader>
                <TableCellHeader>Software</TableCellHeader>
                <TableCellHeader>Módulos</TableCellHeader>
              </TableHeader>
              <tbody>
                {table.map((company, index) => {
                  table[index].business_id = company.id;
                  /*
                  table[index].due === ''
                    ? (table[index].dueOld = moment(company.license.due).format(
                        'YYYY-MM-DD'
                      ))
                    : // eslint-disable-next-line no-self-assign
                      (table[index].due = table[index].due);
                  */
                  return (
                    <>
                      <TableRow key={company.id}>
                        <TableCell>{company.id}</TableCell>

                        <TableCell>
                          <InputCheckBoxNoForm
                            noError
                            placeholder=""
                            type="checkbox"
                            name="selected"
                            checked={table[index].sel}
                            onChange={(e) => {
                              let auxTable = [];
                              auxTable = [...table];

                              auxTable[index].sel = !auxTable[index].sel;
                              setTable(auxTable);
                            }}
                          />
                        </TableCell>

                        <TableCell>{company.fantasy_name}</TableCell>
                        <TableCell>{company.company_name}</TableCell>

                        <TableCell>
                          {`${company.city.name}-${company.city.uf}`}
                        </TableCell>
                        <TableCell>
                          {`${company.group ? company.group.description : ''}`}
                        </TableCell>

                        <TableCell>
                          {/* {company.software.software_name} */}
                          {`${
                            company.software
                              ? company.software.software_name
                              : ''
                          }`}
                        </TableCell>

                        <TableCell>
                          <ContainerActions>
                            <ButtonIcon
                              type="Button"
                              data-tooltip-id={
                                company.hideSub
                                  ? 'Mostrar Módulos'
                                  : 'Ocultar Módulos'
                              }
                              onClick={() => {
                                handleHideSub(index);
                              }}
                            >
                              {company.hideSub ? (
                                <FaAngleDown size={18} color="#c3c3c3" />
                              ) : (
                                <FaAngleUp size={18} color="#c3c3c3" />
                              )}
                            </ButtonIcon>
                          </ContainerActions>
                        </TableCell>
                      </TableRow>
                      {company.software.softModules &&
                      company.software.softModules.length > 0 ? (
                        <TableRow subtable hide={company.hideSub}>
                          <TableCell />
                          <TableCell colSpan="8">
                            <SubTable title="Módulos">
                              <TableHeader>
                                <TableCellHeader>#</TableCellHeader>
                                <TableCellHeader>Sel</TableCellHeader>
                                <TableCellHeader>Descrição</TableCellHeader>
                                <TableCellHeader>Detalhes</TableCellHeader>
                              </TableHeader>
                              <tbody>
                                {company.software.softModules.length > 0 &&
                                  company.software.softModules.map(
                                    (mod, indexModel) => (
                                      <TableRow>
                                        <TableCell>{mod.id}</TableCell>

                                        <TableCell>
                                          <InputCheckBoxNoForm
                                            noError
                                            placeholder=""
                                            type="checkbox"
                                            name="selected"
                                            checked={
                                              table[index].software.softModules[
                                                indexModel
                                              ].sel
                                            }
                                            onChange={(e) => {
                                              let auxTable = [];
                                              auxTable = [...table];

                                              auxTable[
                                                index
                                              ].software.softModules[
                                                indexModel
                                              ].sel =
                                                !auxTable[index].software
                                                  .softModules[indexModel].sel;
                                              setTable(auxTable);
                                            }}
                                          />
                                        </TableCell>

                                        <TableCell>{mod.description}</TableCell>
                                        <TableCell>{mod.details}</TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </tbody>
                            </SubTable>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            </Table>

            <ContainerButtons>
              <Button
                size="contained"
                variant="outline"
                text="Cancelar"
                type="button"
                onClick={() => history.push('/license-list')}
              />
              <Button
                size="contained"
                type="submit"
                text={loading ? 'Aguarde...' : 'Salvar'}
              />
            </ContainerButtons>
          </StyledForm>
        )}
      </Formik>
    </ContainerCard>
  );
}

export default Create;
