import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { serverDBRequestCreate } from '../../../../store/modules/serverdatabase/actions';
import { hideModal } from '../../../../store/modules/modal/actions';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import StyledForm from '../../../../components/Form';
import Label from '../../../../components/Label';
import ContainerCard from '../../../../components/ContainerCard';
import api from '../../../../services/api';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../../components/Table';

import { ContainerButtons } from './styles';

function Create({ update_releaseds_id }) {
  const dispath = useDispatch();
  const [logs, setLogs] = useState([]);
  const loading = useSelector((state) => state.serverdatabase.loading);

  function handleReset() {
    dispath(hideModal());
  }

  useEffect(() => {
    const getLogs = async () => {
      try {
        const response = await api.get(
          `/logupdate-released/${update_releaseds_id}`
        );
        if (response.data) {
          setLogs(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getLogs();
  }, [loading]);

  return (
    <ContainerCard title="Logs da Atualização" removeShadow>
      <Table title="Atualizações Liberadas">
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Data e Hora</TableCellHeader>
          <TableCellHeader>Ação</TableCellHeader>
          <TableCellHeader>Detalhe</TableCellHeader>
        </TableHeader>
        <tbody>
          {logs.map((lg) => (
            <TableRow key={lg.id}>
              <TableCell>{lg.action}</TableCell>
              <TableCell>{lg.details}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <ContainerButtons>
        <Button
          size="contained"
          variant="outline"
          text="Fechar"
          type="button"
          onClick={handleReset()}
        />
        <Button
          size="contained"
          type="button"
          text={loading ? 'Fechar' : 'Fechar'}
        />
      </ContainerButtons>
    </ContainerCard>
  );
}

export default Create;
