import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { computerRequestCreate } from '../../../store/modules/computer/actions';
import history from '../../../history';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import api from '../../../services/api';

import Select from '../../../components/Select';
import { ContainerButtons } from './styles';
import { SelectReactNoForm } from '../../../components/SelectReaact';

const TicketCategorySchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obrigatória')
    .min(3, 'Descrição muito curta'),
});

function Create() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ticketCategory.loading);
  const [businessQuery, setBusinessQuery] = useState([]);
  const [business, setBusiness] = useState([]);
  const [viewConnection, setViewConnection] = useState([]);

  useEffect(() => {
    const getBusiness = async () => {
      try {
        const persistedData = localStorage.getItem('persist:sisfoco');

        const parsedData = JSON.parse(persistedData);

        const userData = JSON.parse(parsedData.user);

        const userId = userData.user.id;

        const response = await api.get(`userbusinessbyuserid/${userId}`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getVieweConnection = async () => {
      try {
        const response = await api.get(`viewerConnection`);
        if (response.data) {
          setViewConnection(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getVieweConnection();
    getBusiness();
  }, []);

  async function handleSubmit({
    description,
    business_id,
    viewer_connection_id,
    situation,
  }) {
    dispatch(
      computerRequestCreate(
        description,
        business_id,
        viewer_connection_id,
        situation
      )
    );
  }

  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Computador">
      {' '}
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize
        initialValues={{
          description: '',
          business_id: '',
          viewer_connection_id: '',
          situation: 'ATIVO',
        }}
        validationSchema={TicketCategorySchema}
      >
        <StyledForm>
          <Label text="Empresa" />
          <Field name="business_id" component={Select} type="text">
            <option value="" disabled>
              Selecione uma Empresa
            </option>
            {business.map((Businesses) => (
              <option key={Businesses.id} value={Businesses.id}>
                {Businesses.code} - {Businesses.fantasy_name}
              </option>
            ))}
          </Field>
          <Label text="Descrição do Computador" />
          <Field
            placeholder=""
            type="text"
            name="description"
            component={Input}
          />
          <Label text="Conexão" />
          <Field name="viewer_connection_id" component={Select} type="text">
            <option value="" disabled>
              Selecione uma Conexão
            </option>
            {viewConnection.map((ViewConnection) => (
              <option key={ViewConnection.id} value={ViewConnection.id}>
                {ViewConnection.description}
              </option>
            ))}
          </Field>
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/ticket-category-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
