import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { signInSuccess, signFailure, signOutSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'sessions', {
      email,
      password,
      // type: 'web',
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token.token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  } catch (error) {
    yield put(signFailure());
    toast.error('Usuário ou senha não conferem');
    console.log(error);
  }
}

export function* signUp({ payload }) {
  try {
    const { username, email, password } = payload;

    yield call(api.post, 'users', {
      email,
      password,
      username,
    });

    history.push('/');
  } catch (error) {
    yield put(signFailure());
    toast.error('Falha ao cadastrar usuário, verifique os dados');
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) api.defaults.headers.Authorization = `Bearer ${token}`;
}

export function* signOut() {
  try {
    yield put(signOutSuccess());
    history.push('/');
    toast.error('Falha ao validar autenticação do usuário');
  } catch (error) {
    yield put(signFailure());
    toast.error('Falha ao validar autenticação do usuário');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('AUTH_SIGN_IN_REQUEST', signIn),
  takeLatest('AUTH_SIGN_UP_REQUEST', signUp),
  takeLatest('AUTH_SIGN_OUT', signOut),
]);
