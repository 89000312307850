import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaWifi,
  FaTrashAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus,
} from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { showModal } from '../../../store/modules/modal/actions';
import api from '../../../services/api';
import online from '../../../assets/images/wifi.png';
import ofline from '../../../assets/images/no-wifi.png';
import { deleteServerRequest } from '../../../store/modules/server/actions';
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
  SubTable,
} from '../../../components/Table';

import ModalConfirm, {
  modalConfirm,
} from '../../../components/ModalConfirm/ModalConfirm';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import ContainerList from '../../../components/ContainerList';
import { ContainerButtons } from '../Create/styles';
import { ContainerActions } from './styles';
import { serverDBRequestDelete } from '../../../store/modules/serverdatabase/actions';

function ListServer() {
  const [servers, setServers] = useState([]);
  const [dataOriSearch, setDataOriSearch] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.server.loading);
  const loadingDb = useSelector((state) => state.serverdatabase.loading);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    const getServers = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get('servers');
        if (response.data) {
          response.data.forEach((element) => {
            element.hideSub = true;
          });
          setServers(response.data);
          setDataOriSearch(response.data);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };
    getServers();
  }, [loading, loadingDb]);

  function handleHideSub(index) {
    const aux = servers;

    aux[index].hideSub = !aux[index].hideSub;

    setServers([...aux]);
  }

  async function handleCreateDatabase(id) {
    dispath(showModal('CREATE_SERVERDB', { server_id: id }));
  }
  async function handleUpdateDb(id, server_id) {
    dispath(showModal('UPDATE_SERVERDB', { id, server_id }));
  }

  async function handleDelete(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o item selecionado?',
      onConfirm: () => {
        dispath(deleteServerRequest(id));
      },
      loading,
    });
  }

  async function handleDeleteDb(id) {
    modalConfirm({
      title: 'Confirmar exclusão',
      message: 'Deseja realmente excluir o módulo selecionado?',
      onConfirm: () => {
        console.log('Aqui vai passar o id do modulo');
        console.log(id);
        dispath(serverDBRequestDelete(id));
      },
      loading,
    });
  }

  function filtrarArrayPorCampo(termoPesquisa) {
    // Converte o termo de pesquisa para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas

    const termoMinusculo = termoPesquisa.toLowerCase();

    // Filtra o array
    const resultadoFiltrado = dataOriSearch.filter((objeto) =>
      // Verifica se pelo menos um campo do objeto contém o termo de pesquisa
      Object.values(objeto).some((valor) => {
        // Converte o valor para minúsculas para tornar a comparação insensível a maiúsculas e minúsculas
        const valorMinusculo = String(valor).toLowerCase();

        // Verifica se o valor contém o termo de pesquisa
        return valorMinusculo.includes(termoMinusculo);
      })
    );

    return setServers(resultadoFiltrado);
  }

  return (
    <ContainerList>
      <ContainerButtons>
        <Button
          size="contained"
          text="Novo Cadastro"
          onClick={() => history.push('/server-create')}
        />
      </ContainerButtons>
      <ModalConfirm />
      <Table
        title="Servers cadastradas"
        loading={loadingTable}
        showSearch
        onChangeFilter={filtrarArrayPorCampo}
      >
        <TableHeader>
          <TableCellHeader>#</TableCellHeader>
          <TableCellHeader>Descrição</TableCellHeader>
          <TableCellHeader>Nome Pc</TableCellHeader>
          <TableCellHeader>Versão</TableCellHeader>
          <TableCellHeader>Cód. Integração</TableCellHeader>
          <TableCellHeader>On.</TableCellHeader>
          <TableCellHeader>Ação</TableCellHeader>
        </TableHeader>
        <tbody>
          {servers.map((server, index) => (
            <>
              <TableRow key={server.id}>
                <TableCell>{server.id}</TableCell>
                <TableCell>{server.label}</TableCell>
                <TableCell>{server.name_pc}</TableCell>
                <TableCell>{server.version_app}</TableCell>
                <TableCell>{server.serverIdBase64}</TableCell>
                <TableCell>
                  {server.online ? (
                    <img style={{ maxWidth: '20px' }} src={online} alt="wifi" />
                  ) : (
                    <img
                      style={{ maxWidth: '20px' }}
                      src={ofline}
                      alt="nowifi"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <ContainerActions>
                    <ButtonIcon
                      data-tooltip-id="Editar"
                      onClick={() => {
                        history.push(`server-edit/${server.id}`);
                      }}
                    >
                      <FaEdit size={15} color="#c3c3c3" />
                      <ReactTooltip />
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id="Excluir"
                      onClick={() => handleDelete(server.id)}
                    >
                      <FaTrashAlt size={15} color="#c3c3c3" />
                      <ReactTooltip />
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id={
                        server.hideSub ? 'Mostrar Módulos' : 'Ocultar Módulos'
                      }
                      onClick={() => {
                        handleHideSub(index);
                        // setShowSub(!showSub);
                      }}
                    >
                      {server.hideSub ? (
                        <FaAngleDown size={18} color="#c3c3c3" />
                      ) : (
                        <FaAngleUp size={18} color="#c3c3c3" />
                      )}
                    </ButtonIcon>
                    <ButtonIcon
                      data-tooltip-id="Novo Módulo"
                      onClick={() => {
                        handleCreateDatabase(server.id);
                      }}
                    >
                      <FaPlus size={15} color="#c3c3c3" />
                    </ButtonIcon>
                  </ContainerActions>
                </TableCell>
              </TableRow>
              {server.databases && server.databases.length > 0 ? (
                <TableRow subtable hide={server.hideSub}>
                  <TableCell />
                  <TableCell colSpan="3">
                    <SubTable title="Database">
                      <TableHeader>
                        <TableCellHeader>#</TableCellHeader>
                        <TableCellHeader>Database</TableCellHeader>
                        <TableCellHeader>Cód. Integração</TableCellHeader>
                        <TableCellHeader>Ações</TableCellHeader>
                      </TableHeader>
                      <tbody>
                        {server.databases.length > 0 &&
                          server.databases.map((db) => (
                            <TableRow>
                              <TableCell>{db.id}</TableCell>
                              <TableCell>{db.database}</TableCell>
                              <TableCell>{db.idServerIdBase64}</TableCell>
                              <TableCell>
                                <ContainerActions>
                                  <ButtonIcon
                                    data-tooltip-id="Editar"
                                    onClick={() => {
                                      handleUpdateDb(db.id, server.id);
                                    }}
                                  >
                                    <FaEdit size={15} color="#c3c3c3" />
                                  </ButtonIcon>
                                  <ButtonIcon
                                    data-tooltip-id="Excluir"
                                    onClick={() => handleDeleteDb(db.id)}
                                  >
                                    <FaTrashAlt size={15} color="#c3c3c3" />
                                  </ButtonIcon>
                                </ContainerActions>
                              </TableCell>
                            </TableRow>
                          ))}
                      </tbody>
                    </SubTable>
                  </TableCell>
                </TableRow>
              ) : null}
            </>
          ))}
        </tbody>
      </Table>
    </ContainerList>
  );
}

export default ListServer;
