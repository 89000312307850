import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FaUserCircle, FaTrashAlt } from 'react-icons/fa';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { groupRequestCreate } from '../../../store/modules/group/actions';
import history from '../../../history';
import api from '../../../services/api';

import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import Select from '../../../components/Select';
import Button, { SearchButton } from '../../../components/Button';

import Input, {
  InputNoForm,
  InputDatePickerNoForm,
  InputCheckBoxNoForm,
} from '../../../components/Input';

import StarRating from '../../../components/StartRating';

import {
  TicketInfoContainer,
  TicketInfo,
  TicketTitle,
  TicketValue,
  ContainerTickets,
  ContainerPieChart,
  ContainerDetTickets,
  ContainerDetTicketsUp,
  ContainerDetTicketsDown,
} from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const GroupSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
  // software_id: Yup.number().required('O Software é obrigatório'),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.group.loading);
  const [softwares, setSoftwares] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selCategory, setCategory] = useState([]);
  const [selBusiness, setBusiness] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [ticketTotal, setTotalTickets] = useState(0);
  const [ticketClosed, setClosedTickets] = useState(0);
  const [ticketOpen, setOpenTickets] = useState(0);
  const [ticketPending, setPendingTickets] = useState(0);
  const [qtdServiceNote, setQtdServiceNote] = useState(0);
  const [qtdResolutionNote, setQtdResolutionNote] = useState(0);
  const [serviceNote, setServiceNote] = useState(0);
  const [resolutionNote, setResolutionNote] = useState(0);
  const [selectedRatingFilter, setSelectedRatingFilter] = useState(0);
  const [ratingFilterType, setRatingFilterType] = useState('servicenote'); // Default to 'servicenote'
  const [pieChartData, setPieChartData] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [ticketsAllFilter, setTicketsAllFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 5;

  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getCategory = async () => {
      try {
        const response = await api.get(`ticketcategories`);
        if (response.data) {
          setCategory(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getUsers = async () => {
      try {
        const response = await api.get(`usersjustidname`);
        if (response.data) {
          setUsers(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroups = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getSoftware();
    getCategory();
    getBusiness();
    getUsers();
    getGroups();
  }, []);

  const getTicketStatisticsCategory = async ({
    period,
    category,
    userCreated,
    userTakeson,
    business,
    group,
  }) => {
    try {
      const response = await api.get('ticketstatisticsbycategory', {
        params: {
          period,
          category,
          userCreated,
          userTakeson,
          business,
          group,
        },
      });

      if (response.data && response.data.length > 0) {
        const labels = response.data.map((item) => item.description);
        const data = response.data.map((item) => parseInt(item.count, 10));

        const dataP = {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED'],
              hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#E7E9ED',
              ],
            },
          ],
        };

        setPieChartData(dataP);
      } else {
        setPieChartData(null);
      }

      // Retorno padrão caso não tenha dados
      return null;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }

      // Retorno padrão em caso de erro
      return null;
    }
  };

  const getTicketStatistics = async ({
    period,
    category,
    userCreated,
    userTakeson,
    business,
    group,
  }) => {
    try {
      const response = await api.get('ticketstatistics', {
        params: {
          period,
          category,
          userCreated,
          userTakeson,
          business,
          group,
        },
      });
      if (response.data) {
        setTotalTickets(response.data.totalTickets);
        setClosedTickets(response.data.closedTickets);
        setOpenTickets(response.data.openTickets);
        setPendingTickets(response.data.pendingTickets);
      }

      const response2 = await api.get('ticketReviewAverages', {
        params: {
          period,
          category,
          userCreated,
          userTakeson,
          business,
          group,
        },
      });
      if (response2.data) {
        setServiceNote(response2.data.avg_servicenote);
        setResolutionNote(response2.data.avg_resolutionnote);
        setQtdServiceNote(response2.data.count_servicenote);
        setQtdResolutionNote(response2.data.count_resolutionnote);
      }

      const response3 = await api.get('ticketReviewsByFilters', {
        params: {
          period,
          category,
          userCreated,
          userTakeson,
          business,
          group,
        },
      });
      if (response3.data) {
        setReviews(response3.data);
      }

      const params = {
        period,
        category,
        userCreated,
        userTakeson,
        business,
        group,
      };

      // Adiciona o filtro de avaliação apenas se o valor for maior que 0
      if (selectedRatingFilter > 0) {
        params[ratingFilterType] = selectedRatingFilter;
      }

      // Faz a requisição com os parâmetros filtrados
      const response4 = await api.get('AllTicketsByFilters', { params });

      if (response4.data) {
        setTicketsAllFilter(response4.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    // Chama a função com filtros vazios ou valores padrão quando o componente é montado
    getTicketStatistics({
      period: '', // Ajuste conforme necessário
      category: '',
      userCreated: '',
      userTakeson: '',
      business: '',
      group: '',
    });

    getTicketStatisticsCategory({
      period: '', // Ajuste conforme necessário
      category: '',
      userCreated: '',
      userTakeson: '',
      business: '',
      group: '',
    });
  }, []);

  const hanbleButtonCommentClick = (id) => {
    // Navegar para a outra página ou realizar outras ações ao clicar na linha
    history.push(`/ticket-detresponse/${id}`);
  };

  async function handleFilter() {
    console.log('Colocar aqui: ', selectedRatingFilter);
  }

  async function handleSubmit({
    category_id,
    userCreate_id,
    userAssume_id,
    business_id,
    group_id,
  }) {
    // Chama a função para obter as estatísticas dos tickets
    await getTicketStatistics({
      period: dateRange, // Pode ser necessário ajustar se o dateRange estiver em um formato diferente
      category: category_id,
      userCreated: userCreate_id,
      userTakeson: userAssume_id,
      business: business_id,
      group: group_id,
    });

    await getTicketStatisticsCategory({
      period: dateRange, // Pode ser necessário ajustar se o dateRange estiver em um formato diferente
      category: category_id,
      userCreated: userCreate_id,
      userTakeson: userAssume_id,
      business: business_id,
      group: group_id,
    });
  }

  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label({ dataset, dataIndex, label }) {
            const dataArr = dataset.data;
            const value = dataArr[dataIndex];
            const sum = dataArr.reduce((acc, val) => acc + val, 0);
            const percentage = `${((value * 100) / sum).toFixed(2)}%`;
            return `${label}: ${percentage}`;
          },
        },
      },
      legend: {
        position: 'top', // Posiciona a legenda no lado direito
        labels: {
          color: '#333',
          font: {
            size: 14,
            family: 'Arial',
          },
          padding: 20,
        },
      },
      datalabels: {
        color: '#fff',
        display: true,
        anchor: 'center',
        align: 'center',
        formatter: (value) => `${value}`, // Exibe o valor dentro da fatia
        font: {
          size: 24,
          weight: 'bold',
        },
      },
    },
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    elements: {
      arc: {
        hoverOffset: 30, // Aumenta o levantamento do segmento ao passar o mouse
      },
    },
  };

  const getSubtitle = (situation) => {
    switch (situation) {
      case 'AIA':
        return 'Aguardando Inicio do Atendimento';
      case 'AIC':
        return 'Aguardando Interação do Cliente';
      case 'ARS':
        return 'Aguardando Resposta do Suporte';
      case 'EAT':
        return 'Em Análise Técnica';
      case 'AFC':
        return 'Aguardando Finalizacao do Cliente';
      case 'AAT':
        return 'Aguardando Avaliação Ticket';
      case 'RCS':
        return 'Resolvido com Sucesso';
      case 'FSR':
        return 'Fechado sem Resolução';
      default:
        return situation;
    }
  };

  // Calcula o índice inicial e final dos tickets a serem exibidos
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = ticketsAllFilter.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Número total de páginas
  const totalPages = Math.ceil(ticketsAllFilter.length / ticketsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleRatingChange = (newRating) => {
    setSelectedRatingFilter(newRating);
  };

  const handleClearFilter = () => {
    setSelectedRatingFilter(0); // Limpa o filtro
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      onReset={handleReset}
      enableReinitialize
      initialValues={{
        category_id: '',
        userCreate_id: '',
        userAssume_id: '',
        business_id: '',
        group_id: '',
      }}
      // validationSchema={GroupSchema}
    >
      {(props) => (
        <StyledForm>
          <ContainerCard title="Monitoramento - Tickets">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row', // Coloca todos os filtros na mesma linha
                width: '100%',
                gap: '10px', // Espaçamento entre os filtros
              }}
            >
              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Período" />
                <InputDatePickerNoForm
                  placeholder=""
                  type="date"
                  range
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(e) => {
                    setDateRange(e);
                  }}
                />
              </div>

              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Categoria" />
                <Field name="category_id" component={Select} type="text">
                  <option key="ALL" value="">
                    Todas
                  </option>
                  {selCategory.map((Category) => (
                    <option key={Category.id} value={Category.id}>
                      {Category.description}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Usuário - Criou" />
                <Field name="userCreate_id" component={Select} type="text">
                  <option key="ALL" value="">
                    Todos
                  </option>
                  {users.map((User) => (
                    <option key={User.id} value={User.id}>
                      {User.id} - {User.name}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Usuário - Assumiu" />
                <Field name="userAssume_id" component={Select} type="text">
                  <option key="ALL" value="">
                    Todos
                  </option>
                  {users.map((User) => (
                    <option key={User.id} value={User.id}>
                      {User.id} - {User.name}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Empresa" />
                <Field name="business_id" component={Select} type="text">
                  <option key="ALL" value="">
                    Todas
                  </option>
                  {selBusiness.map((businesses) => (
                    <option key={businesses.id} value={businesses.id}>
                      {businesses.code} - {businesses.fantasy_name}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ flex: '1' }}>
                {' '}
                {/* Ajuste o valor de 'flex' para mudar o tamanho */}
                <Label text="Grupo de Empresa" />
                <Field name="group_id" component={Select} type="text">
                  <option key="ALL" value="">
                    Todas
                  </option>
                  {groups.map((Group) => (
                    <option key={Group.id} value={Group.id}>
                      {Group.id} - {Group.description}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ flex: '0.2', marginTop: '7px' }}>
                <SearchButton
                  size="contained"
                  type="submit"
                  text={loading ? 'Aguarde...' : 'Salvar'}
                />
              </div>
            </div>
          </ContainerCard>

          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              marginTop: '5px',
            }}
          >
            <ContainerTickets>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                  padding: '1px',
                  borderBottom: '2px solid #ddd',
                }}
              >
                <h2
                  style={{
                    fontSize: '20px', // Tamanho da fonte
                    fontWeight: '800', // Peso da fonte
                    color: '#333', // Cor da fonte
                    letterSpacing: '1px', // Espaçamento entre letras
                    textTransform: 'uppercase', // Transformar texto para maiúsculas
                  }}
                >
                  Tickets
                </h2>
              </div>
              <TicketInfoContainer>
                <TicketInfo className="total">
                  <TicketTitle>Total</TicketTitle>
                  <TicketValue>{ticketTotal}</TicketValue>
                </TicketInfo>
                <TicketInfo className="closed">
                  <TicketTitle>Finalizados</TicketTitle>
                  <TicketValue>{ticketClosed}</TicketValue>
                </TicketInfo>
                <TicketInfo className="open">
                  <TicketTitle>Em Atendimento</TicketTitle>
                  <TicketValue>{ticketOpen}</TicketValue>
                </TicketInfo>
                <TicketInfo className="pending">
                  <TicketTitle>Pendentes</TicketTitle>
                  <TicketValue>{ticketPending}</TicketValue>
                </TicketInfo>
              </TicketInfoContainer>
            </ContainerTickets>
            <div style={{ flex: 1, display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '25%',
                }}
              >
                {/* Gráfico de pizza */}
                <ContainerPieChart>
                  <div
                    id="pie-chart-container"
                    style={{ width: '100%', height: '500px' }}
                  >
                    {/* Aqui você integrará o gráfico de pizza, este é apenas um placeholder */}
                    {pieChartData ? (
                      <Pie data={pieChartData} options={options} />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%', // Preencher toda a altura disponível
                          fontSize: '1.5rem',
                          color: '#555',
                          fontWeight: 'bold',
                        }}
                      >
                        Não há Dados
                      </div>
                    )}
                  </div>
                </ContainerPieChart>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '28%',
                }}
              >
                <ContainerDetTicketsUp>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '48%', textAlign: 'center' }}>
                      <p style={{ fontSize: '1.5rem', margin: '0 0 10px 0' }}>
                        Atendimento
                      </p>
                      <p style={{ fontSize: '4rem', margin: 0 }}>
                        {Number(serviceNote).toFixed(2)}
                      </p>
                      <StarRating
                        rating={Number(serviceNote)}
                        editable={false}
                        size="large"
                      />
                      {/* Quantidade de avaliações */}
                      <p style={{ fontSize: '1.2rem', marginTop: '10px' }}>
                        {Number(qtdServiceNote) === 1
                          ? '1 Avaliação'
                          : `${qtdServiceNote} Avaliações`}
                      </p>
                    </div>
                    <div style={{ width: '48%', textAlign: 'center' }}>
                      <p style={{ fontSize: '1.5rem', margin: '0 0 10px 0' }}>
                        Satisfação
                      </p>
                      <p style={{ fontSize: '4rem', margin: 0 }}>
                        {Number(resolutionNote).toFixed(2)}
                      </p>
                      <StarRating
                        rating={Number(resolutionNote)}
                        editable={false}
                        size="large"
                      />
                      {/* Quantidade de avaliações */}
                      <p style={{ fontSize: '1.2rem', marginTop: '10px' }}>
                        {Number(qtdResolutionNote) === 1
                          ? '1 Avaliação'
                          : `${qtdResolutionNote} Avaliações`}
                      </p>
                    </div>
                  </div>
                </ContainerDetTicketsUp>
                <ContainerDetTicketsDown>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px', // Espaçamento entre os comentários
                    }}
                  >
                    {reviews.length > 0 ? (
                      reviews.map((review) => (
                        <button
                          key={review.id}
                          type="button"
                          style={{
                            display: 'flex',
                            backgroundColor: '#ebf4f8',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            alignItems: 'flex-start', // Alinhamento vertical dos itens
                            minHeight: '80px', // Altura mínima para os comentários
                            border: 'none',
                            cursor: 'pointer',
                            textAlign: 'left',
                            transition:
                              'transform 0.3s ease, box-shadow 0.3s ease',
                            outline: 'none',
                          }}
                          onClick={() => {
                            hanbleButtonCommentClick(review.ticket_id);
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)'; // Aumenta o tamanho ao passar o mouse
                            e.currentTarget.style.boxShadow =
                              '0 4px 8px rgba(0, 0, 0, 0.2)'; // Aumenta a sombra
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = 'scale(1)'; // Volta ao tamanho original
                            e.currentTarget.style.boxShadow =
                              '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                          }}
                          onFocus={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)'; // Efeito semelhante ao passar o mouse
                            e.currentTarget.style.boxShadow =
                              '0 4px 8px rgba(0, 0, 0, 0.2)'; // Sombra semelhante ao passar o mouse
                          }}
                          onBlur={(e) => {
                            e.currentTarget.style.transform = 'scale(1)'; // Remove o efeito quando o foco é perdido
                            e.currentTarget.style.boxShadow =
                              '0 2px 4px rgba(0, 0, 0, 0.1)'; // Sombra original
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.currentTarget.click(); // Simula o clique com a tecla Enter ou Espaço
                            }
                          }}
                        >
                          <div
                            style={{
                              width: '15%', // Tamanho fixo para ícone e nome do usuário
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center', // Centralizar ícone e nome
                            }}
                          >
                            <FaUserCircle size={30} color="#555" />{' '}
                            {/* Ícone de usuário */}
                            <div
                              style={{
                                marginTop: '4px',
                                fontSize: '0.8rem',
                                color: '#333',
                                whiteSpace: 'nowrap', // Evitar quebra de linha no nome
                                overflow: 'hidden', // Esconder texto que ultrapassa o limite
                                maxWidth: '100%', // Garantir que o texto respeite a largura do contêiner
                              }}
                            >
                              {(() => {
                                const [firstName, secondName] =
                                  review.user_name.split(' ');
                                return (
                                  <>
                                    {firstName}
                                    <br />
                                    {secondName?.length > 0
                                      ? `${secondName}`
                                      : ''}
                                  </>
                                );
                              })()}
                            </div>
                          </div>

                          <div
                            style={{
                              flex: 1,
                              marginRight: '20px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '1px solid #ddd',
                                marginBottom: '10px',
                              }}
                            />
                            <div
                              style={{
                                fontSize: '0.9rem',
                                color: '#333',
                                fontStyle: 'italic',
                                padding: '0 10px',
                                maxWidth: '100%',
                                wordWrap: 'break-word',
                                display: '-webkit-box',
                                WebkitLineClamp: 2, // Limita a 2 linhas
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'normal', // Permite a quebra de linha
                                lineHeight: '1.2em', // Altura da linha
                                maxHeight: '2.4em', // Altura total permitida (2 linhas)
                              }}
                            >
                              &quot;
                              {review.description || 'Usuário não comentou!'}
                              &quot;
                            </div>
                          </div>
                          <div
                            style={{
                              width: '15%',
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ marginBottom: '5px' }}>
                              <StarRating
                                rating={Number(review.servicenote)}
                                editable={false}
                                size="small"
                              />
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                              <StarRating
                                rating={Number(review.resolutionnote)}
                                editable={false}
                                size="small"
                              />
                            </div>
                            <div style={{ fontSize: '0.7rem', color: '#333' }}>
                              Ticket ID: {review.ticket_id}
                            </div>
                          </div>
                        </button>
                      ))
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%', // Preencher toda a altura disponível
                          fontSize: '1.5rem',
                          color: '#555',
                          fontWeight: 'bold',
                        }}
                      >
                        Não há Avaliações
                      </div>
                    )}
                  </div>
                </ContainerDetTicketsDown>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                }}
              >
                <ContainerDetTickets>
                  <div style={{ height: '100%', width: '100%' }}>
                    {/* Div para filtros */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: '10px',
                          display: 'flex',
                          flexDirection: 'column', // Organiza os elementos em coluna
                          alignItems: 'flex-start', // Alinha o conteúdo ao início (à esquerda)
                        }}
                      >
                        <Label text="Filtro de Avaliação" />
                        <Field
                          name="status"
                          component={Select}
                          type="text"
                          onChange={(e) => setRatingFilterType(e.target.value)}
                        >
                          <option key="SERVICENOTE" value="servicenote">
                            Atendimento
                          </option>
                          <option key="RESOLUTIONNOTE" value="resolutionnote">
                            Satisfação
                          </option>
                        </Field>
                      </div>
                      {/* Div para o filtro de avaliação */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: '10px',
                          display: 'flex',
                          alignItems: 'center', // Alinha verticalmente os itens no centro
                          gap: '10px', // Espaçamento entre o StarRating e o botão
                        }}
                      >
                        <StarRating
                          rating={Number(selectedRatingFilter)}
                          editable
                          size="large"
                          allowHalfStars={false} // Somente números inteiros
                          onRatingChange={handleRatingChange}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                          }}
                        >
                          <button
                            type="button"
                            onClick={handleClearFilter}
                            aria-label="Limpar filtro de avaliação"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              background: 'none',
                              border: 'none',
                              cursor: 'pointer',
                              padding: '0',
                            }}
                          >
                            <FaTrashAlt
                              style={{
                                marginBottom: '10px',
                                fontSize: '15px',
                                color: '#888',
                              }}
                            />
                          </button>
                        </div>
                      </div>

                      {/* Botão de filtro */}
                      <div style={{ flex: '0 0 15%', marginLeft: '10px' }}>
                        <SearchButton
                          size="contained"
                          type="submit"
                          text={loading ? 'Aguarde...' : 'Filtrar'}
                        />
                      </div>
                    </div>

                    {/* Div para listar os tickets */}
                    <div
                      style={{
                        height: 'calc(100% - 50px)',
                        overflowY: 'auto',
                        width: '100%',
                      }}
                    >
                      {currentTickets.length > 0 ? (
                        currentTickets.map((ticket) => (
                          <button
                            key={ticket.id}
                            type="button"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: '#ebf4f8',
                              padding: '8px',
                              borderRadius: '8px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                              border: 'none',
                              cursor: 'pointer',
                              textAlign: 'left',
                              marginBottom: '10px',
                              transition:
                                'transform 0.3s ease, box-shadow 0.3s ease',
                              outline: 'none',
                              alignSelf: 'center',
                              margin: '10px auto',
                              width: '95%',
                              maxWidth: '99%',
                            }}
                            onClick={() => {
                              hanbleButtonCommentClick(ticket.id);
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = 'scale(1.05)';
                              e.currentTarget.style.boxShadow =
                                '0 4px 8px rgba(0, 0, 0, 0.2)';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = 'scale(1)';
                              e.currentTarget.style.boxShadow =
                                '0 2px 4px rgba(0, 0, 0, 0.1)';
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.transform = 'scale(1.05)';
                              e.currentTarget.style.boxShadow =
                                '0 4px 8px rgba(0, 0, 0, 0.2)';
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.transform = 'scale(1)';
                              e.currentTarget.style.boxShadow =
                                '0 2px 4px rgba(0, 0, 0, 0.1)';
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.currentTarget.click();
                              }
                            }}
                          >
                            {/* Primeira linha com os rótulos */}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  flex: '1',
                                  padding: '0 8px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {ticket.user_name}
                              </div>
                              <div style={{ flex: '2', padding: '0 8px' }}>
                                <strong>Situação:</strong>
                              </div>
                              <div style={{ flex: '1', padding: '0 8px' }}>
                                <strong>Data de Criação:</strong>
                              </div>
                              <div
                                style={{
                                  flex: '1',
                                  padding: '0 8px',
                                  textAlign: 'right',
                                }}
                              >
                                <strong>Serviço:</strong>
                              </div>
                              <div
                                style={{
                                  flex: '1',
                                  padding: '0 8px',
                                  textAlign: 'right',
                                }}
                              >
                                <strong>Resolução:</strong>
                              </div>
                            </div>

                            {/* Segunda linha com os valores */}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: '5px',
                                fontSize: '12px',
                              }}
                            >
                              <div style={{ flex: '1', padding: '0 8px' }}>
                                #{ticket.id}
                              </div>
                              <div
                                style={{
                                  flex: '2',
                                  padding: '0 8px',
                                  fontSize: '12px',
                                }}
                              >
                                {getSubtitle(ticket && ticket.situation)}
                              </div>
                              <div style={{ flex: '1', padding: '0 8px' }}>
                                {new Date(
                                  ticket.created_at
                                ).toLocaleDateString()}
                              </div>
                              <div
                                style={{
                                  flex: '1',
                                  padding: '0 8px',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {ticket.servicenote != null ? (
                                  <StarRating
                                    rating={Number(ticket.servicenote)}
                                    editable={false}
                                    size="small"
                                  />
                                ) : (
                                  <span
                                    style={{ fontSize: '12px', color: '#888' }}
                                  >
                                    Sem Avaliação
                                  </span>
                                )}
                              </div>
                              <div
                                style={{
                                  flex: '1',
                                  padding: '0 8px',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {ticket.resolutionnote != null ? (
                                  <StarRating
                                    rating={Number(ticket.resolutionnote)}
                                    editable={false}
                                    size="small"
                                  />
                                ) : (
                                  <span
                                    style={{ fontSize: '12px', color: '#888' }}
                                  >
                                    Sem Avaliação
                                  </span>
                                )}
                              </div>
                            </div>
                          </button>
                        ))
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '80%', // Preencher toda a altura disponível
                            fontSize: '1.5rem',
                            color: '#555',
                            fontWeight: 'bold',
                          }}
                        >
                          Não há tickets disponíveis
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '10px',
                        }}
                      >
                        {pageNumbers.map((number) => (
                          <button
                            key={number}
                            type="button"
                            onClick={() => paginate(number)}
                            style={{
                              margin: '0 5px',
                              padding: '5px 10px',

                              border: '1px solid #ddd',
                              borderRadius: '5px',
                              backgroundColor:
                                number === currentPage ? '#007bff' : '#fff',
                              color:
                                number === currentPage ? '#fff' : '#007bff',
                              cursor: 'pointer',
                            }}
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Controles de navegação */}
                  </div>
                </ContainerDetTickets>

                {/* Espaço para outras informações */}
              </div>
            </div>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
}

export default Create;
