import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import MarkdownEditor from '@uiw/react-markdown-editor';
import ContainerCard from '../../../components/ContainerCard';
import { ContainerButtons } from './styles';
import Button from '../../../components/Button';
import Input, { InputNoForm } from '../../../components/Input';
import Label from '../../../components/Label';

function Markdown() {
  const [value, setValue] = useState('**Hello world!!!**');
  const [fileName, setFileName] = useState(''); // Estado para o nome do arquivo

  const handlePrintMarkdown = () => {
    // Verifique se há conteúdo Markdown antes de salvar o arquivo
    if (value.trim() !== '') {
      if (fileName.trim() !== '') {
        // Crie um objeto Blob com o conteúdo Markdown
        const blob = new Blob([value], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);

        // Crie um link de download e simule o clique nele para iniciar o download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.md`; // Use o nome do arquivo especificado
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Limpe o objeto URL e remova o elemento de link
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Limpe o campo do nome do arquivo
        setFileName('');
      } else {
        // Caso o campo do nome do arquivo esteja vazio, exiba uma mensagem de aviso
        alert('Digite um nome para o arquivo antes de salvar.');
      }
    } else {
      // Caso não haja conteúdo Markdown, exiba uma mensagem de aviso
      alert('Nenhum conteúdo Markdown para salvar.');
    }
  };

  return (
    <ContainerCard title="Editor de Markdown">
      <Label text="Nome do Arquivo:" />
      <InputNoForm
        placeholder="Nome do arquivo"
        type="text"
        name="password"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <div style={{ flex: 1, marginTop: '10px' }}>
        <MarkdownEditor
          value={value}
          onChange={setValue}
          style={{ maxHeight: 'calc(100vh - 350px)', minHeight: '400px' }}
          onPreviewMode={() => true}
        />
        <ContainerButtons>
          <Button
            size="contained"
            variant="outline"
            text="Limpar"
            type="button"
            onClick={() => setValue('')}
          />
          <Button
            size="contained"
            variant="outline"
            text="Salvar Markdown"
            type="button"
            onClick={handlePrintMarkdown}
          />
        </ContainerButtons>
      </div>
    </ContainerCard>
  );
}

export default Markdown;
