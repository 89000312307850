export function companyRequestCreate(data) {
  return {
    type: 'COMPANY_REQUEST_CREATE',
    payload: data,
  };
}

export function companyRequestUpdate(id, data) {
  return {
    type: 'COMPANY_REQUEST_UPDATE',
    payload: { id, data },
  };
}

export function companyRequestDelete(id) {
  return {
    type: 'COMPANY_REQUEST_DELETE',
    payload: { id },
  };
}

export function companyFailure() {
  return {
    type: 'COMPANY_FAILURE',
  };
}

export function companySuccess() {
  return {
    type: 'COMPANY_SUCCESS',
  };
}
