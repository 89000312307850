import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import Button from '../../../components/Button';

export const ContainerButtons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TitleDivider = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '20px')};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
`;

export const Divider = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerInputFile = styled.div`
  width: 100%;
`;

export const CardFile = styled.div`
  width: 100%;
  height: 65px;
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 1px solid ${({ theme }) => theme.colors.shade};
  span {
    width: 65px;
    height: 100%;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  svg {
    color: ${({ theme }) => theme.colors.secondary};
    margin-left: 10px;
    min-width: 25px;
  }
  div {
    margin-left: 5px;
    width: auto;
    height: 65px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 14px;
      font-weight: 600;
    }
    h3 {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

export const ButtonNoMargin = styled(Button)`
  margin: 0px;
`;

export const CardModal = styled.div`
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: hidden;
  position: fixed;
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  transition: all 0.2s;
`;

export const Scroll = styled(PerfectScrollBar)`
  //height: calc(100% - 56px);
  height: calc(100vh - 86px);
  background-color: ${(props) => props.theme.colors.tint};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding: 15px;
  padding-top: 0px;
`;
