export function softwareRequestCreate(software_name) {
  return {
    type: 'SOFTWARE_REQUEST_CREATE',
    payload: { software_name },
  };
}

export function softwareRequestUpdate(id, software_name) {
  return {
    type: 'SOFTWARE_REQUEST_UPDATE',
    payload: { software_name, id },
  };
}

export function softwareRequestDelete(id) {
  return {
    type: 'SOFTWARE_REQUEST_DELETE',
    payload: { id },
  };
}

export function softwareFailure() {
  return {
    type: 'SOFTWARE_FAILURE',
  };
}

export function softwareSuccess() {
  return {
    type: 'SOFTWARE_SUCCESS',
  };
}
