import styled from 'styled-components';
import { darken } from 'polished';
import { FaSearch, FaUser } from 'react-icons/fa';

export const StyledButton = styled.button`
  width: ${({ size }) => (size === 'contained' ? '140px' : '100%')};
  width: auto;
  padding: 9px 15px;
  border-radius: 4px;

  box-shadow: ${({ theme, variant }) => {
    if (variant === 'text') {
      return 'none';
    }

    return '';

    // if (theme.name === 'dark') return 'none';
    // return `2px 2px 15px ${theme.colors.shade}`;
  }};

  background-color: ${({ color, theme, variant }) => {
    if (variant === 'outline' || variant === 'text') {
      return 'transparent';
    }
    if (color === 'primary') return theme.colors.primary;
    if (color === 'secondary') return theme.colors.secondary;
    return theme.colors.accent;
  }};
  margin: 10px 0 8px 0;
  margin-left: ${({ size }) => (size === 'contained' ? '10px' : 'none')};
  border: ${({ theme, variant }) => {
    if (variant === 'outline') {
      return `solid 1px ${theme.colors.accent}`;
    }
    return 'solid 1px transparent';
  }};
  transition: all 0.2s;

  &:hover {
    //transform: translateY(-3px);
    background-color: ${({ color, theme, variant }) => {
      if (variant === 'outline' || variant === 'text') {
        return darken(0.05, theme.colors.tint);
      }
      if (color === 'secondary') return darken(0.05, theme.colors.secondary);
      if (color === 'primary') return darken(0.05, theme.colors.primary);
      return darken(0.05, theme.colors.accent);
    }};
  }
  &:focus {
    background-color: ${({ color, theme, variant }) => {
      if (variant === 'outline' || variant === 'text') {
        return darken(0.05, theme.colors.tint);
      }
      if (color === 'secondary') return darken(0.09, theme.colors.secondary);
      if (color === 'primary') return darken(0.09, theme.colors.primary);
      return darken(0.09, theme.colors.accent);
    }};

    box-shadow: ${({ theme, variant }) => {
      if (variant === 'text') {
        return 'none';
      }
      if (theme.name === 'dark') return 'none';
      return `0px 0px 5px ${darken(0.09, theme.colors.secondary)}`;
    }};
  }
  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.shade};
  }
`;

export const ImageButton = styled(StyledButton)`
  width: 50px; // Largura reduzida para se adequar à imagem
  height: 50px; // Altura para manter o botão quadrado
  padding: 0; // Remover padding para evitar espaço extra
  overflow: hidden; // Esconder qualquer conteúdo que exceda o tamanho do botão
  border-radius: 50%; // Torná-lo circular se desejado
  display: flex; // Usar flexbox para alinhar a imagem
  justify-content: center; // Centralizar a imagem horizontalmente
  align-items: center; // Centralizar a imagem verticalmente
  background: white;
  marginright: 10px;

  &:hover {
    background-color: #3c4766;
  }

  &:focus {
    background-color: #2a3042;
    box-shadow: ${({ theme, variant }) => {
      if (variant === 'text') {
        return 'none';
      }
      if (theme.name === 'dark') return 'none';
      return `0px 0px 5px ${darken(0.09, theme.colors.secondary)}`;
    }};
  }

  img {
    width: 90%; // Ajustar imagem para preencher o botão
    height: auto; // Manter a proporção da imagem
  }
`;

export const ModalButton = styled(StyledButton)`
  width: 140px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px 15px;
  border-radius: 4px;
  background-color: white; // Fundo branco
  transition: all 0.2s;

  &:hover {
    background-color: #f2f2f2; // Um pouco mais escuro que branco no hover
    border-color: darken(0.1, #3c4766); // Escurecer a borda um pouco no hover
  }

  &:focus {
    background-color: #f2f2f2; // Manter o fundo um pouco mais escuro no foco
    border-color: darken(0.1, #3c4766); // Escurecer a borda um pouco no foco
    box-shadow: 0px 0px 5px darken(0.1, #3c4766); // Sombra no foco
  }

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #e6e6e6; // Fundo para estado desabilitado
    border-color: #cccccc; // Borda para estado desabilitado
  }
`;

export const SearchIcon = styled(FaSearch)`
  margin-right: 10px;
  size: 25px;
  color: white;
`;

export const SearchButtonText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: white;
`;

export const StyledSearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ size }) => (size === 'contained' ? '140px' : '100%')};

  padding: 9px 15px;
  border-radius: 4px;
  background-color: #2a3042;
  margin: 20px 0 20px 0;
  margin-left: ${({ size }) => (size === 'contained' ? '10px' : 'none')};
  border: ${({ theme, variant }) => {
    if (variant === 'outline') {
      return `solid 1px ${theme.colors.accent}`;
    }
    return 'solid 1px transparent';
  }};
  transition: all 0.2s;

  &:hover {
    background-color: #3c4766;
  }

  &:focus {
    background-color: #2a3042;
    box-shadow: ${({ theme, variant }) => {
      if (variant === 'text') {
        return 'none';
      }
      if (theme.name === 'dark') return 'none';
      return `0px 0px 5px ${darken(0.09, theme.colors.secondary)}`;
    }};
  }

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.shade};
  }
`;

export const StyledButtonToLine = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ size }) => (size === 'contained' ? '140px' : '100%')};

  border-left: 10px;
  padding: 9px 15px;
  border-radius: 4px;
  background-color: #2a3042;
  margin-left: ${({ size }) => (size === 'contained' ? '10px' : 'none')};
  border: ${({ theme, variant }) => {
    if (variant === 'outline') {
      return `solid 1px ${theme.colors.accent}`;
    }
    return 'solid 1px transparent';
  }};
  transition: all 0.2s;

  &:hover {
    background-color: #3c4766;
  }

  &:focus {
    background-color: #2a3042;
    box-shadow: ${({ theme, variant }) => {
      if (variant === 'text') {
        return 'none';
      }
      if (theme.name === 'dark') return 'none';
      return `0px 0px 5px ${darken(0.09, theme.colors.secondary)}`;
    }};
  }

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.shade};
  }
`;

export const Text = styled.div`
  color: ${({ theme, variant }) => {
    if (variant === 'outline') return theme.colors.accent;

    if (variant === 'text') return theme.colors.accent;

    if (!variant && theme.name === 'dark') return theme.colors.primary;
    return theme.colors.bright;
  }};

  font-size: 14px;

  font-weight: 500;
`;

export const ModalButtonText = styled.div`
  color: black;

  font-size: 15px;

  font-weight: 500;
`;
