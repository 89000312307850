export function groupRequestCreate(description) {
  return {
    type: 'GROUP_REQUEST_CREATE',
    payload: { description },
  };
}

export function groupRequestUpdate(id, description) {
  return {
    type: 'GROUP_REQUEST_UPDATE',
    payload: { description, id },
  };
}

export function groupRequestDelete(id) {
  return {
    type: 'GROUP_REQUEST_DELETE',
    payload: { id },
  };
}

export function groupFailure() {
  return {
    type: 'GROUP_FAILURE',
  };
}

export function groupSuccess() {
  return {
    type: 'GROUP_SUCCESS',
  };
}
