import styled from 'styled-components';
import { darken } from 'polished';

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
`;

const coresPorSituacao = {
  AIC: '#e9ac4c', // Amarelo suave
  AIS: '#6ae2eb', // Vermelho suave
  RES: '#6AEB6C', // Verde suave
  Baixado: '#06C4AE',
  Baixando: '#07A1F0',
};

const obterCorPorSituacao = (situacao) => coresPorSituacao[situacao] || 'gray';

export const Etiqueta = styled.div`
  background-color: ${(props) => obterCorPorSituacao(props.situacao)};
  border-radius: 5px;
  padding: 5px; /* Espaçamento interno para o texto */
  color: white; /* Cor do texto */
  display: flex; /* Use flexbox para centralizar o conteúdo */
  justify-content: center; /* Centralize horizontalmente */
  align-items: center; /* Centralize verticalmente */
  &:hover {
    background-color: ${(props) =>
      darken(0.05, obterCorPorSituacao(props.situacao))};
    color: black;
  }
`;
