import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Tooltip as ReactTooltip } from 'react-tooltip';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { render } from '@testing-library/react';
import { licenseRequestCreate } from '../../../store/modules/license/actions';
import history from '../../../history';
import api from '../../../services/api';

import Input, {
  InputNoForm,
  InputDatePickerNoForm,
  InputCheckBoxNoForm,
} from '../../../components/Input';
import {
  ContainerInputs,
  ContainerAlignToTheSide,
  InputLittle,
  InputNoMandatory,
  ContainerActions,
  ContainerSpaceTable,
  ContainerAuxTable,
  InputCheckBox,
  ContainerButtons,
} from './styles';
import Button, { SearchButton } from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import ContainerCard from '../../../components/ContainerCard';
import { SelectNoForm } from '../../../components/Select';
import { SelectReactNoForm } from '../../../components/SelectReaact';

import { getCurrentDate } from '../../../utils/functions';

import {
  Table,
  TableHeader,
  TableCellHeader,
  TableCell,
  TableRow,
} from '../../../components/Table';

const LicenseSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obriatória')
    .min(3, 'Descrição muito curta'),
});

function Create() {
  const [licenses, setLicenses] = useState([]);

  const [stateDate, setStateDate] = useState(null);

  const [groupQuery, setGroupQuery] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const [softwareQuery, setSoftwareQuery] = useState([]);
  const [softwares, setSoftwares] = useState([]);

  const [businessQuery, setBusinessQuery] = useState([]);
  const [business, setBusiness] = useState([]);

  const [allBusiness, setAllBusiness] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);

  const [licenseDate, setLicenseDate] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [due, setDue] = useState(null);
  const [checkOnlyCompanies, setCheckOnlyCompanies] = useState(false);

  const [table, setTable] = useState([]);

  const dispath = useDispatch();
  const loading = useSelector((state) => state.license.loading);

  async function handleSubmitLicense() {
    const tableSelected = table.filter((tableSe) => tableSe.sel === true);
    if (tableSelected.length === 0) {
      toast.info('Não foi selecionado nenhuma empresa!');
    } else {
      dispath(licenseRequestCreate(tableSelected));
    }
  }

  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  function selectAll(acao) {
    setAllBusiness(acao);

    let auxTable = [];
    auxTable = [...table];
    for (let index = 0; index < auxTable.length; index += 1) {
      auxTable[index].sel = acao;
    }

    setTable(auxTable);
  }

  function duetAll(date) {
    setDue(date);

    let auxTable = [];
    auxTable = [...table];
    for (let index = 0; index < auxTable.length; index += 1) {
      if (auxTable[index].sel) {
        auxTable[index].due = date;
      }
    }

    setTable(auxTable);
  }

  useEffect(() => {
    let tableData = [];
    tableData = [...table];

    for (let i = 0; i < tableData.length; i += 1) {
      tableData[i].sel = allBusiness;
    }
    setTable(tableData);
  }, [allBusiness]);

  useEffect(() => {
    let tableData = [];
    tableData = [...table];

    for (let i = 0; i < tableData.length; i += 1) {
      tableData[i].due = dateFilter || '';
    }
    setTable(tableData);
  }, [dateFilter]);

  useEffect(() => {
    const getSoftware = async () => {
      try {
        const response = await api.get(`softwares`);
        if (response.data) {
          setSoftwares(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };

    const getGroup = async () => {
      try {
        const response = await api.get(`groups`);
        if (response.data) {
          setGroups(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    const getBusiness = async () => {
      try {
        const response = await api.get(`business`);
        if (response.data) {
          setBusiness(response.data);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    const getLicenseAll = async () => {
      try {
        setLoadingTable(true);
        const response = await api.get(`searchlicense/?query=1`);

        if (response.data) {
          setLicenses(response.data.data);

          const tableData = response.data.data;

          for (let i = 0; i < tableData.length; i += 1) {
            tableData[i].sel = allBusiness;
            tableData[i].due = dateFilter || '';
            if (tableData[i].license) {
              tableData[i].block = tableData[i].license.blocked_license;
            } else {
              tableData[i].block = false;
            }
          }

          setTable(tableData);
        }

        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        if (
          error.response &&
          error.response.data &&
          error.response.status === 400
        ) {
          toast.error(error.response.data[0].message);
        } else {
          toast.error(error.message);
        }
      }
    };

    getBusiness();
    getGroup();
    getSoftware();
    getLicenseAll();
  }, []);

  async function searchCompany() {
    if (dateRange[0] !== null && dateRange[1] === null) {
      toast.warn('Informe o período completo para buscar.');
      return;
    }
    try {
      setLoadingTable(true);
      let uri = '';
      if (businessQuery > 0) {
        uri += `&business_id=${businessQuery}`;
      }
      if (groupQuery > 0) {
        uri += `&group_id=${groupQuery}`;
      }
      if (softwareQuery > 0) {
        uri += `&software_id=${softwareQuery}`;
      }
      if (dateRange[0] !== null) {
        uri += `&dueStart=${moment(dateRange[0]).format('YYYY-MM-DD')}`;
      }
      if (dateRange[1] !== null) {
        uri += `&dueEnd=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
      }

      uri += `&checkOnlyCompanies=${checkOnlyCompanies}`;

      // searchlicense/?query=1&licenseDate=2021-05-20&licenseDate=2021-05-18&checkOnlyCompanies=false
      // searchlicense/?due=2021-05-19&checkOnlyCompanies=2020-05-16&business_id=false
      // ? 'business_id=${businessQuery}` :  groupQuery`
      // business_id=${businessQuery}&group_id=${groupQuery}&licenseDate=${licenseDate}&due=${due}&checkOnlyCompanies=${checkOnlyCompanies}`
      const response = await api.get(`searchlicense/?query=1${uri}`);

      if (response.data) {
        setLicenses(response.data.data);

        const tableData = response.data.data;

        for (let i = 0; i < tableData.length; i += 1) {
          tableData[i].sel = allBusiness;
          tableData[i].due = dateFilter || '';
          if (tableData[i].license) {
            tableData[i].block = tableData[i].license.blocked_license;
          } else {
            tableData[i].block = false;
          }
        }

        setTable(tableData);
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        toast.error(error.response.data[0].message);
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <>
      <ContainerCard title="Pesquisa - Filtros">
        <Formik
          onSubmit={searchCompany}
          onReset={handleReset}
          enableReinitialize
          initialValues={{
            description: 'Teste',
          }}
          validationSchema={LicenseSchema}
        >
          <StyledForm autocomplete="off">
            <ContainerInputs>
              <span>
                <Label text="Nome da Empresa" />
                <SelectReactNoForm
                  value={(businessQuery, business.company_name)}
                  placeholder="Selecione uma Empresa"
                  onChange={(e) => {
                    if (e) {
                      setBusinessQuery(e.value);
                    } else {
                      setBusinessQuery(null);
                    }
                  }}
                  options={business.map((busines) => ({
                    label: busines.company_name,
                    value: busines.id,
                  }))}
                  name="idBusiness"
                />
              </span>

              <span>
                <Label text="Software" />
                <SelectReactNoForm
                  value={(softwareQuery, softwares.software_name)}
                  placeholder="Selecione um Produto"
                  onChange={(e) => {
                    if (e) {
                      setSoftwareQuery(e.value);
                    } else {
                      setSoftwareQuery(null);
                    }
                  }}
                  options={softwares.map((software) => ({
                    label: software.software_name,
                    value: software.id,
                  }))}
                  name="idSoftware"
                />
              </span>
            </ContainerInputs>
            <ContainerInputs>
              <span>
                <Label text="Grupo da Empresa" />
                <SelectReactNoForm
                  value={(groupQuery, groups.description)}
                  placeholder="Selecione um Grupo"
                  onChange={(e) => {
                    if (e) {
                      setGroupQuery(e.value);
                    } else {
                      setGroupQuery(null);
                    }
                  }}
                  options={groups.map((group) => ({
                    label: group.description,
                    value: group.id,
                  }))}
                  name="idGroup"
                />
              </span>

              <span>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Label text="Vencimento da Linceça no Período" />
                    <InputDatePickerNoForm
                      placeholder=""
                      type="date"
                      range
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(e) => {
                        setDateRange(e);
                      }}
                    />
                  </div>
                  <ContainerButtons>
                    <SearchButton
                      size="contained"
                      variant="outline"
                      text="Pesquisar"
                      type="button"
                    />
                  </ContainerButtons>
                </div>
              </span>
            </ContainerInputs>
          </StyledForm>
        </Formik>
      </ContainerCard>
      <ContainerAuxTable>
        <Table noShowBox title="Empresas cadastradas" loading={loadingTable}>
          <TableHeader>
            <TableCellHeader>#</TableCellHeader>
            <TableCellHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InputCheckBoxNoForm
                  noError
                  placeholder=""
                  type="checkbox"
                  name="selected1"
                  compact
                  checked={allBusiness}
                  onChange={(e) => {
                    selectAll(e.target.checked);
                  }}
                />
                <div style={{ marginLeft: '4px' }}>Sel.</div>
              </div>
            </TableCellHeader>
            <TableCellHeader>Fantasia</TableCellHeader>
            <TableCellHeader>Razão social</TableCellHeader>
            <TableCellHeader>Cidade</TableCellHeader>
            <TableCellHeader>Grupo</TableCellHeader>
            <TableCellHeader>Produto</TableCellHeader>
            <TableCellHeader>Venct. Atual</TableCellHeader>
            <TableCellHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '4px' }}>Novo Venct.</div>
                <InputDatePickerNoForm
                  // autocomplete = 'off'
                  compact
                  noError
                  placeholder=""
                  name="dueAll"
                  onChange={(e) => {
                    duetAll(e);
                  }}
                />
              </div>
            </TableCellHeader>
            <TableCellHeader>Bloquear</TableCellHeader>
            <TableCellHeader>Observação</TableCellHeader>
          </TableHeader>
          <tbody>
            {table.map((company, index) => {
              table[index].business_id = company.id;
              /*
                  if (table[index].license) {
                    table[index].due === ''
                      ? (table[index].dueOld = moment(
                          company.license.due
                        ).format('YYYY-MM-DD'))
                      : (table[index].due = '');
                  } */

              return (
                <TableRow key={company.id}>
                  <TableCell>{company.id}</TableCell>

                  <TableCell>
                    <InputCheckBoxNoForm
                      noError
                      placeholder=""
                      type="checkbox"
                      name="selected"
                      checked={table[index].sel}
                      onChange={(e) => {
                        let auxTable = [];
                        auxTable = [...table];

                        auxTable[index].sel = !auxTable[index].sel;
                        setTable(auxTable);
                      }}
                    />
                  </TableCell>

                  <TableCell>{company.fantasy_name}</TableCell>
                  <TableCell>{company.company_name}</TableCell>

                  <TableCell>
                    {`${company.city.name}-${company.city.uf}`}
                  </TableCell>
                  <TableCell>
                    {`${company.group ? company.group.description : ''}`}
                  </TableCell>

                  <TableCell>
                    {/* {company.software.software_name} */}
                    {`${
                      company.software ? company.software.software_name : ''
                    }`}
                  </TableCell>

                  <TableCell>
                    {`${
                      company.license
                        ? moment(company.license.due).format('DD/MM/YYYY')
                        : ''
                    }`}
                  </TableCell>

                  <TableCell>
                    <ContainerSpaceTable>
                      <InputDatePickerNoForm
                        // autocomplete = 'off'
                        noError
                        placeholder=""
                        name={`dues${index}`}
                        disabled={!table[index].sel}
                        selected={table[index].due}
                        onChange={(e) => {
                          let auxTable = [];
                          auxTable = [...table];
                          auxTable[index].due = e;
                          setTable(auxTable);
                        }}
                      />
                    </ContainerSpaceTable>
                  </TableCell>

                  <TableCell>
                    <InputCheckBoxNoForm
                      noError
                      placeholder=""
                      type="checkbox"
                      name="block"
                      disabled={!table[index].sel}
                      checked={table[index].block}
                      onChange={(e) => {
                        let auxTable = [];
                        auxTable = [...table];

                        auxTable[index].block = !auxTable[index].block;
                        setTable(auxTable);
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <InputNoForm
                      noError
                      placeholder=""
                      type="text"
                      name="description"
                      disabled={!table[index].sel}
                      onChange={(e) => {
                        let auxTable = [];
                        auxTable = [...table];

                        auxTable[index].details = e.target.value;
                        setTable(auxTable);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        </Table>

        <ContainerButtons style={{ paddingRight: '20px' }}>
          <Button
            size="contained"
            variant="outline"
            text="Cancelar"
            type="button"
            onClick={() => history.push('/license-list')}
          />
          <Button
            size="contained"
            type="submit"
            onClick={() => handleSubmitLicense()}
            text={loading ? 'Aguarde...' : 'Salvar'}
          />
        </ContainerButtons>
      </ContainerAuxTable>
    </>
  );
}

export default Create;
