import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  width: 100%;
`;

export const Ul = styled.ul`
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

//  drop menu

export const NavbarDropdownContent = styled.div`
  display: flex;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0, 2);
  padding: 12px 16px;
  z-index: 1;
  flex-direction: column;
`;

export const NavbarDropdown = styled.div`
  position: relative;
  display: inline-block;
  display: none;
  &:hover ${NavbarDropdownContent} {
    display: block;
  }
`;
