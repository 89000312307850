import React from 'react';

import { Container, Scroll } from './styles';
import NavItems from '../NavItems';

function SideMenu() {
  return (
    <Container>
      <Scroll>
        <NavItems />
      </Scroll>
    </Container>
  );
}

export default SideMenu;
