import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { ticketDiscussionFailure, ticketDiscussionSuccess } from './actions';
import history from '../../../history';

import api from '../../../services/api';

export function* ticketDiscussionCreate({ payload }) {
  // Inicialize um conjunto para armazenar mensagens já exibidas
  const displayedMessages = new Set();

  try {
    const { message, support_response, ticket_id } = payload;

    yield call(api.post, 'ticketdiscussions', {
      message,
      support_response,
      ticket_id,
    });

    yield put(ticketDiscussionSuccess());

    // window.location.reload();
    toast.success('Mensagem Salva com Sucesso');
  } catch (error) {
    yield put(ticketDiscussionFailure());

    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors && data.errors.length > 0) {
        // Exibir mensagens de erro personalizadas
        data.errors.forEach((err) => {
          // Verificar se a mensagem já foi exibida
          if (!displayedMessages.has(err.message)) {
            toast.error(err.message);

            // Adicionar a mensagem ao conjunto para evitar exibições duplicadas
            displayedMessages.add(err.message);
          }
        });
      } else {
        // Se não houver mensagens personalizadas, exibir uma mensagem padrão
        const defaultMessage =
          'Erro de validação: Mensagem não foi cadastrada corretamente.';

        // Verificar se a mensagem padrão já foi exibida
        if (!displayedMessages.has(defaultMessage)) {
          toast.error(defaultMessage);

          // Adicionar a mensagem ao conjunto
          displayedMessages.add(defaultMessage);
        }
      }
    } else {
      // Se o erro não for 422, exibir uma mensagem genérica
      const genericMessage =
        'Erro ao cadastrar a mensagem. Tente novamente mais tarde.';

      // Verificar se a mensagem genérica já foi exibida
      if (!displayedMessages.has(genericMessage)) {
        toast.error(genericMessage);

        // Adicionar a mensagem ao conjunto
        displayedMessages.add(genericMessage);
      }
    }
  }
}

export function* ticketDiscussionUpdate({ payload }) {
  try {
    const { id, description } = payload;

    yield call(api.put, `ticketdiscussions/${id}`, {
      description,
    });

    yield put(ticketDiscussionSuccess());

    history.push('/ticket-list');
    toast.success('Cadastro alterado');
  } catch (error) {
    yield put(ticketDiscussionFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Cadastro não alterado');
  }
}

export function* ticketDiscussionDelete({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `tickets/${id}`);

    yield put(ticketDiscussionSuccess());

    history.push('/ticket-list');
    toast.success('Registro deletado');
  } catch (error) {
    yield put(ticketDiscussionFailure());
    if (error.response.data && error.response.status === 400) {
      toast.error(error.response.data[0].message);
    } else toast.error('Regitro não deletado');
  }
}

export default all([
  takeLatest('TICKETDISCUSSION_REQUEST_CREATE', ticketDiscussionCreate),
  takeLatest('TICKETDISCUSSION_REQUEST_UPDATE', ticketDiscussionUpdate),
  takeLatest('TICKETDISCUSSION_REQUEST_DELETE', ticketDiscussionDelete),
]);
