import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Wrapper = styled.div`
  height: auto;
  background-color: ${(props) => props.theme.colors.tint};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

// export const ContentChildren = styled.div`
//   //height: calc(100% - 56px); // -565px;
//   height: auto;

//   background-color: ${(props) => props.theme.colors.tint};
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   //flex: 8;
//   width: calc(100% - 200px);
//   padding: 15px;
// `;

export const ContentChildren = styled.div`
  //height: calc(100% - 56px); // -565px;
  height: auto;

  background-color: ${(props) => props.theme.colors.tint};
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  width: calc(100% - 200px); // Subtrai a largura do SideMenu
  padding: 15px;
`;

export const Header = styled.div`
  height: 56px;
  display: flex;
  background-color: ${(props) => props.theme.colors.bright};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //border-bottom: solid 1px ${(props) => props.theme.colors.shade};
  //padding: 5px 10px 5px 10px;
`;

export const ContainerLogo = styled.div`
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;

  width: 200px;
  background-color: ${(props) => props.theme.colors.side};
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: 15px;
  }
  img {
    height: 100%;
    width: auto;
  }
`;

export const ContainerUserInfo = styled.div`
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px 10px 5px 10px;
  &:hover {
    cursor: pointer;
  }

  img {
    height: 80%;
    width: auto;
    margin-right: 10px;
  }
  span {
    h2,
    h3 {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 400;
    }

    h2 {
      font-size: 13px;
    }
    h3 {
      font-size: 10px;
    }
  }
`;

export const SideMenu = styled.div`
  height: calc(100%);
  background-color: ${(props) => props.theme.colors.bright};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: solid 1px ${(props) => props.theme.colors.shade};
  flex: 1;
  min-width: 150px;
  max-width: 350px;
`;

export const Content = styled.div`
  height: auto; //calc(100% - 56px);
  display: flex;

  background-color: blue; //${(props) => props.theme.colors.bright};
  flex-direction: row;
  justify-content: center;
`;

export const Scroll = styled(PerfectScrollBar)`
  //height: calc(100% - 56px);
  height: calc(100vh - 86px);
  background-color: ${(props) => props.theme.colors.tint};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding: 15px;
  padding-top: 0px;
`;

export const PainelInfoCurrentPage = styled.div`
  min-height: 56px;
  width: 100%;

  margin-bottom: 2px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  h2 {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: left;
  }
`;
