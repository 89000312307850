// actions.js
export function ticketRequestCreate(
  subject,
  situation,
  ticket_categories_id,
  message,
  business_id
) {
  return {
    type: 'TICKET_REQUEST_CREATE',
    payload: { subject, situation, ticket_categories_id, message, business_id },
  };
}

export function ticketIdReceived(ticketId) {
  return {
    type: 'TICKET_ID_RECEIVED',
    payload: { ticketId },
  };
}

export function ticketRequestUpdate(id, subject) {
  return {
    type: 'TICKET_REQUEST_UPDATE',
    payload: { subject, id },
  };
}

export function ticketRequestDelete(id) {
  return {
    type: 'TICKET_REQUEST_DELETE',
    payload: { id },
  };
}

export function ticketFailure() {
  return {
    type: 'TICKET_FAILURE',
  };
}

export function ticketSuccess() {
  return {
    type: 'TICKET_SUCCESS',
  };
}
