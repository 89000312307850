import styled from 'styled-components';
import { darken } from 'polished';

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
`;

const coresPorSituacao = {
  AIA: '#5DADE2', // Aguardando Inicio do Atendimento {COR AZUL CLARO}
  AIC: '#F39C12', // Aguardando Interação do Cliente {COR AMARELO CLARO}
  ARS: '#D4AC0D', // Aguardando Resposta do Suporte
  EAT: '#8E44AD', // Em Análise Técnica
  RCS: '#27AE60', // Resolvido com Sucesso
  FSR: '#474747', // Fechado sem Resolução
};

const obterCorPorSituacao = (situacao) => coresPorSituacao[situacao] || 'gray';

export const Etiqueta = styled.div`
  background-color: ${(props) => obterCorPorSituacao(props.situacao)};
  border-radius: 5px;
  padding: 5px; /* Espaçamento interno para o texto */
  margin-top: 2px;
  margin-bottom: 2px;
  color: white; /* Cor do texto */
  display: flex; /* Use flexbox para centralizar o conteúdo */
  justify-content: center; /* Centralize horizontalmente */
  align-items: center; /* Centralize verticalmente */
  &:hover {
    background-color: ${(props) =>
      darken(0.05, obterCorPorSituacao(props.situacao))};
  }
`;
