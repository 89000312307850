import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import RouteWrapper from './routeWrapper';

import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';

import CreateCity from '../pages/City/Create';
import ListCity from '../pages/City/List';
import EditCity from '../pages/City/Update';

import CreateCompany from '../pages/Company/Create';
import ListCompany from '../pages/Company/List';
import UpdateCompany from '../pages/Company/Update';

import CreateComputer from '../pages/Computer/Create';
import ListComputer from '../pages/Computer/List';
import UpdateComputer from '../pages/Computer/Update';

import CreateGroup from '../pages/Group/Create';
import ListGroup from '../pages/Group/List';
import UpdateGroup from '../pages/Group/Update';

import CreateTicket from '../pages/Ticket/Create';
import ListTicket from '../pages/Ticket/List';
import UpdateTicket from '../pages/Ticket/Update';
import DetailsTicket from '../pages/Ticket/Details';
import DetailsResponseTicket from '../pages/Ticket/DetailsResponse';
import ManagerTicket from '../pages/Ticket/Manager';
import MonitoringTicket from '../pages/Ticket/Monitoring';

import CreateTicketCategory from '../pages/TicketCategory/Create';
import ListTicketCategory from '../pages/TicketCategory/List';
import UpdateTicketCategory from '../pages/TicketCategory/Update';

import CreateLicense from '../pages/License/Create';
import ListLicense from '../pages/License/List';

import SoftwareModule from '../pages/SoftwareModule/Create';

import CreateSoftware from '../pages/Software/Create';
import ListSoftware from '../pages/Software/List';
import UpdateSoftware from '../pages/Software/Update';

import CreateMarkDown from '../pages/MarkDown/Create';

import CreteUpdate from '../pages/Update/Create';
import ListUpdate from '../pages/Update/List';
import CreateReleasedUpdate from '../pages/UpdateReleased/Create';
import ListReleasedUpdate from '../pages/UpdateReleased/List';
import UpdateReleasedUpdate from '../pages/UpdateReleased/Update';

import CreateServer from '../pages/Server/Create';
import ListServer from '../pages/Server/List';
import UpdateServer from '../pages/Server/Update';

// import CreateChartAccount from '../pages/ChartAccount/Create';
// import ListChartAccount from '../pages/ChartAccount/List';
// import UpdateChartAccount from '../pages/ChartAccount/Update';

import CreateIssuer from '../pages/Issuer/Create';
import ListIssuer from '../pages/Issuer/List';
import UpdateIssuer from '../pages/Issuer/Update';

import CreateRecurrent from '../pages/RecurrentExport/Create';
import ListRecurrent from '../pages/RecurrentExport/List';
import UpdateRecurrent from '../pages/RecurrentExport/Update';
import ListHistoricExport from '../pages/RecurrentExport/Historic/ListHistoricExport';
import SearchDfe from '../pages/RecurrentExport/Search/SearchDfe';
import OnDemand from '../pages/RecurrentExport/OnDemand';

import ListBackup from '../pages/Backups/List';

import CreateDocument from '../pages/Document/Create';

import ListUser from '../pages/User/List';
import UpdateUser from '../pages/User/Update';
import AccessUser from '../pages/User/Access';

export default function Routes() {
  return (
    <Switch>
      <Route element={<RouteWrapper />}>
        <Route path="/" exact element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Route>
      <Route
        element={
          <RouteWrapper isPrivate rolesPermitted={['Administrator', 'Basic']} />
        }
      >
        <Route path="/dashboard" element={<Dashboard />} isPrivate />
        <Route path="/ticket-create" element={<CreateTicket />} isPrivate />
        <Route path="/ticket-list" element={<ListTicket />} isPrivate />

        <Route
          path="/ticket-details/:id"
          element={<DetailsTicket />}
          isPrivate
        />
        <Route path="/ticket-edit/:id" element={<UpdateTicket />} isPrivate />
        <Route
          path="/ticketcategory-create"
          element={<CreateTicketCategory />}
          isPrivate
        />
        <Route
          path="/ticketcategory-list"
          element={<ListTicketCategory />}
          isPrivate
        />
        <Route
          path="/ticketcategory-edit/:id"
          element={<UpdateTicketCategory />}
          isPrivate
        />
      </Route>

      <Route
        element={<RouteWrapper isPrivate rolesPermitted={['Administrator']} />}
      >
        <Route path="/ticket-manager" element={<ManagerTicket />} isPrivate />
        <Route
          path="/ticket-monitoring"
          element={<MonitoringTicket />}
          isPrivate
        />
        <Route
          path="/ticket-detresponse/:id"
          element={<DetailsResponseTicket />}
          isPrivate
        />
        <Route path="/city-create" element={<CreateCity />} isPrivate />
        <Route path="/company-create" element={<CreateCompany />} isPrivate />
        <Route path="/computer-create" element={<CreateComputer />} isPrivate />
        <Route path="/group-create" element={<CreateGroup />} isPrivate />
        <Route path="/license-create" element={<CreateLicense />} isPrivate />
        <Route path="/license-list" element={<ListLicense />} isPrivate />
        <Route path="/update-create" element={<CreteUpdate />} isPrivate />
        <Route path="/update-list" element={<ListUpdate />} isPrivate />
        <Route path="/document-create" element={<CreateDocument />} isPrivate />
        <Route path="/software-create" element={<CreateSoftware />} isPrivate />
        <Route path="/software-list" element={<ListSoftware />} isPrivate />
        <Route path="/markdown-create" element={<CreateMarkDown />} isPrivate />
        <Route
          path="/software-edit/:id"
          element={<UpdateSoftware />}
          isPrivate
        />
        <Route path="/server-create" element={<CreateServer />} isPrivate />
        <Route path="/server-list" element={<ListServer />} isPrivate />
        <Route path="/server-edit/:id" element={<UpdateServer />} isPrivate />
        <Route path="/issuer-create" element={<CreateIssuer />} isPrivate />
        <Route path="/issuer-list" element={<ListIssuer />} isPrivate />
        <Route path="/issuer-edit/:id" element={<UpdateIssuer />} isPrivate />
        <Route path="/city-list" element={<ListCity />} isPrivate />
        <Route path="/city-edit/:id" element={<EditCity />} isPrivate />

        <Route path="/company-list" element={<ListCompany />} isPrivate />
        <Route path="/company-edit/:id" element={<UpdateCompany />} isPrivate />

        <Route path="/computer-list" element={<ListComputer />} isPrivate />
        <Route
          path="/computer-edit/:id"
          element={<UpdateComputer />}
          isPrivate
        />

        <Route path="/group-list" element={<ListGroup />} isPrivate />
        <Route path="/group-edit/:id" element={<UpdateGroup />} isPrivate />

        <Route path="/user-list" element={<ListUser />} isPrivate />
        <Route path="/user-edit/:id" element={<UpdateUser />} isPrivate />
        <Route path="/user-access/:id" element={<AccessUser />} isPrivate />

        <Route
          path="/recurrent-export-create"
          element={<CreateRecurrent />}
          isPrivate
        />

        <Route
          path="/recurrent-export-list"
          element={<ListRecurrent />}
          isPrivate
        />

        <Route
          path="/export-historic-list"
          element={<ListHistoricExport />}
          isPrivate
        />
        <Route
          path="/export-historic-list/:id"
          element={<ListHistoricExport />}
          isPrivate
        />

        <Route path="/search-dfe" element={<SearchDfe />} isPrivate />
        <Route path="/export-ondemand" element={<OnDemand />} isPrivate />

        <Route
          path="/recurrent-export-edit/:id"
          element={<UpdateRecurrent />}
          isPrivate
        />

        <Route
          path="/update-released-create"
          element={<CreateReleasedUpdate />}
          isPrivate
        />

        <Route
          path="/update-released-list"
          element={<ListReleasedUpdate />}
          isPrivate
        />

        <Route
          path="/update-released-edit/:id"
          element={<UpdateReleasedUpdate />}
          isPrivate
        />
        <Route
          path="/softwaremodule-create"
          element={<SoftwareModule />}
          isPrivate
        />

        <Route path="/backup-list" element={<ListBackup />} isPrivate />
      </Route>

      {/* eslint-disable-next-line react/no-unstable-nested-components */}
      <Route path="/notFound" element={() => <h1>Página não encontrada</h1>} />
    </Switch>
  );
}
