import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { cityRequestCreate } from '../../../store/modules/city/actions';

import { UFS } from '../../../utils/constants';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StyledForm from '../../../components/Form';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import ContainerCard from '../../../components/ContainerCard';

import { ContainerButtons } from './styles';

const CitySchema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório')
    .min(4, 'Nome muito curto'),

  uf: Yup.string().required('A Uf é obrigatória'),
});

function Create() {
  const dispath = useDispatch();
  const loading = useSelector((state) => state.city.loading);

  async function handleSubmit({ name, uf }) {
    dispath(cityRequestCreate(name, uf));
  }
  function handleReset(values, { setStatus }) {
    setStatus(true);
  }

  return (
    <ContainerCard title="Cadastro de Cidade">
      <Formik
        onSubmit={handleSubmit}
        onReset={handleReset}
        initialValues={{
          name: '',
          uf: '',
        }}
        validationSchema={CitySchema}
      >
        <StyledForm>
          <Label text="Nome da Cidade" />
          <Field placeholder="" type="text" name="name" component={Input} />
          <Label text="Selecione a Uf" />
          <Field name="uf" component={Select} type="text">
            <option value="" disabled>
              Selecione um estado
            </option>
            {UFS.map((uf) => (
              <option key={uf.value} value={uf.value}>
                {uf.label}
              </option>
            ))}
          </Field>
          <ContainerButtons>
            <Button
              size="contained"
              variant="outline"
              text="Cancelar"
              type="button"
              onClick={() => history.push('/city-list')}
            />
            <Button
              size="contained"
              type="submit"
              text={loading ? 'Aguarde...' : 'Salvar'}
            />
          </ContainerButtons>
        </StyledForm>
      </Formik>
    </ContainerCard>
  );
}

export default Create;
